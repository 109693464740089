import React, {Component} from 'react';
import {Message, Button, Icon, Header, Segment, Popup, Radio} from 'semantic-ui-react';
import {Link, Redirect} from 'react-router-dom';
import ErrorBoundary from '../../common/ErrorBoundary';
import Confirm from '../../common/Confirm';
import Spinner from '../../common/Spinner';
import {DefaultApi} from "../../api";
import Error from '../../common/Error';

class TableRow extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onDomyslnyChange(this.props.data.id);
    }

    render() {
        return <tr>
            <td width="500px">{this.props.data.nazwaWaluty}</td>
            <td>{this.props.data.symbolWaluty}</td>
            <td><Radio onChange={this.handleChange} checked={this.props.radiovalue === this.props.data.id} value={this.props.data.id} name='radioGroup'/>
            </td>
            <td width="120px">
                <Popup
                    trigger={<Icon link name='edit' onClick={() => this.props.edit(this.props.data.id)}/>}
                    content='Edycja wybranego rodzaju waluty'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                    content='Usunięcie wybranego rodzaju waluty'
                    size='tiny'
                    inverted
                />
            </td>
        </tr>
    }
}


export default class RodzajWalutyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idRodzajuWaluty: '',
            isLoading: true,
            rodzajeWalut: [],
            domyslny: false,
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            radiovalue: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getRodzajeWalut();
    }

    getRodzajeWalut = async () => {
        const api = new DefaultApi({});
        api.listOfWaluta().then((response) => {
            this.setState({rodzajeWalut: response.data, isLoading: false});
            const foundAktPrawny = this.state.rodzajeWalut.find(rodzajaktu => rodzajaktu.domyslny === true);
            if (typeof foundAktPrawny !== 'undefined')
                this.setState({radiovalue: foundAktPrawny.id});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    listRodzajeWalut = () => {
        return (
            <Segment raised className={`panel`}>

                <Header as='h3'>
                    <Icon name='configure'/>
                    <Header.Content>
                        Lista rodzajów walut
                        <Header.Subheader>Zarządzanie rodzajami walut</Header.Subheader>
                    </Header.Content>
                </Header>

                <Link to={'/umowy/waluta/dodaj'}><Button primary>Dodaj rodzaj waluty</Button></Link>
                <table className="ui table">
                    <thead>
                    <tr>
                        <th>Nazwa rodzaju waluty</th>
                        <th>Symbol</th>
                        <th>Domyślny</th>
                        <th>Opcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.rodzajeWalut.sort((a, b) => {
                        if (a.nazwaWaluty != null)
                            return a.nazwaWaluty.localeCompare(b.nazwaWaluty)
                        else if (b.nazwaWaluty != null)
                            return b.nazwaWaluty.localeCompare(a.nazwaWaluty)
                        return null
                    }).map((aktyprawne, i) => <TableRow key={i} data={aktyprawne} radiovalue={this.state.radiovalue}
                                                        onDomyslnyChange={this.onDomyslnyChange}
                                                        remove={this.deleteRodzajWaluty}
                                                        edit={this.editRodzajWaluty}
                        />
                    )}
                    </tbody>
                </table>
                <Segment>
                    <Header as='h4' color='grey'>Legenda</Header>
                    <Icon link name='edit'/> - edycja wybranego rodzaju waluty<br/>
                    <Icon link name='trash'/> - usunięcie wybranego rodzaju waluty
                </Segment>
            </Segment>
        )
    };

    onDomyslnyChange = async (id) => {

        this.setState({radiovalue: id});

        const api = new DefaultApi({});
        await api.listOfWaluta().then((response) => this.setState({rodzajeWalut: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        /* ustawiamy flagę FALSE dla dokumentu(ów), który dotychczas miał ustawioną TRUE */
        const foundRodzajeAktow = this.state.rodzajeWalut.filter(rodzajaktu => rodzajaktu.domyslny === true);

        for (const rodzajaktu of foundRodzajeAktow) {
            if (rodzajaktu.id !== id) {
                await api.editWaluta(rodzajaktu.id, {
                    nazwaWaluty: rodzajaktu.nazwaWaluty,
                    symbolWaluty: rodzajaktu.symbolWaluty,
                    domyslny: false,
                }).catch((error) => {
                    Promise.resolve(error.response.data.msg).then((response) => {
                        this.setState({errorAPI: response, confirmError: true})
                    });
                });
            }
        }

        /* ustawiamy flagę TRUE dla dokumentu zaznaczonego */
        const foundAktPrawny = this.state.rodzajeWalut.find(rodzajaktu => rodzajaktu.id === id);

        await api.editWaluta(id, {
            nazwaWaluty: foundAktPrawny.nazwaWaluty,
            symbolWaluty: foundAktPrawny.symbolWaluty,
            domyslny: true
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    showRodzajWaluty = () => {
        if (this.state.rodzajeWalut.length > 0) {
            return this.listRodzajeWalut()
        } else {
            if (this.state.isLoading) {
                return <Spinner/>
            } else {
                return <div>
                    <Message
                        color='red'
                        header='Nie ma zdefiniowanych rodzajów walut'
                        list={[
                            'Musisz zdefiniować rodzaj waluty. W tym celu kliknij przycisk "Dodaj rodzaj waluty"',
                        ]}
                    />
                    <Link to={'/umowy/waluta/dodaj'}><Button primary>Dodaj rodzaj waluty</Button></Link>
                </div>
            }
        }
    };


    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteWaluta(this.state.idRodzajuWaluty).then(() => this.getRodzajeWalut()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handleCancel = () => this.setState({confirmDelete: false});

    deleteRodzajWaluty = (id) => {
        this.setState({confirmDelete: true, idRodzajuWaluty: id});
    };

    editRodzajWaluty = (id) => {
        this.setState({redirectEdit: true, idRodzajuWaluty: id})
    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectEdit} = this.state;
        const {errorAPI} = this.state;

        return <ErrorBoundary>
            {this.showRodzajWaluty()}

            <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                     title={'Usunięcie rodzaju waluty'}
                     question={'Czy na pewno chcesz usunąć ten rodzaj waluty?'}/>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectEdit && (
                <Redirect push to={'/umowy/waluta/edytuj/' + this.state.idRodzajuWaluty}/>
            )}
        </ErrorBoundary>
    }
}

import React, {Component} from 'react';
import StatusAktuPrawnegoForm from "./StatusAktuPrawnegoForm";


export default class StatusAktuPrawnegoEdit extends Component {

    render() {
        return <div>
            <StatusAktuPrawnegoForm mode={'edit'} id={this.props.match.params.id}/>
        </div>
    }
}

import React, {Component} from 'react';
import UmowyForm from "./UmowyForm";


export default class UmowyAdd extends Component {

    render() {
        return <div>
            <UmowyForm mode={'add'}/>
        </div>
    }
}

import React from 'react';
import {Dimmer, Header, Icon} from 'semantic-ui-react'

const Loading = () => {
    return (
        <Dimmer active page>
            <Header as='h2' icon inverted>
                <Icon name='clock outline' color='green' />
                <br />Trwa weryfikowanie uprawnień użytkownika!<br /><br />
                <Header.Subheader>Poczekaj, aż system przyzna Tobie prawa dostępu...</Header.Subheader>
            </Header>
        </Dimmer>
    )
};

export default Loading;


import React from 'react';
import {Button, Grid, GridRow} from "semantic-ui-react";
import {Link} from "react-router-dom";

const NoMatch = () => {
    return <Grid
        centered
        columns={3}
        padded
        stackable
        style={{margin: '-1.5em', width: 400}}
        textAlign='center'
    >
        <Grid.Row color='red' style={{margin: '0.5em', height: '100%', width: '100%'}}>
            <h3>Błąd 404: brak strony o podanym adresie!</h3>
        </Grid.Row>
        <GridRow>
            <Link to={'/aktyprawne'}><Button primary>Przejdź do strony startowej</Button></Link>
        </GridRow>
    </Grid>
}

export default NoMatch;
import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import 'semantic-ui-css/semantic.min.css';


export default function Menu() {
    const {isLoading, isAuthenticated, loginWithRedirect, logout} = useAuth0();

    return <div className="wrapper_header">
        <div>
            <header>
                <nav className="navbar navbar-default navbar-left">
                    <div className="container-fluid">
                        <ul className="horizontal_menu">
                            <li><a href="/">Strona główna</a></li>
                            {!isLoading && (
                                isAuthenticated ? (
                                    <span>
                                    <li><a href="/aktyprawne">Akty prawne</a></li>
                                    <li><a href="/umowy">Umowy</a></li>
                                    <li><a href="/ustawienia">Ustawienia</a></li>
                                        <li><a title="Kliknij, aby wylogować" href="#/" onClick={() => logout({
                                            returnTo: window.location.origin,
                                        })}>Wyloguj</a></li>
                                </span>
                                ) : (
                                    <span>
                                    <li><a href="/kontakt">Kontakt</a></li>
                                    <li><a title="Kliknij, aby zalogować" href="#/" onClick={() => loginWithRedirect()}>Zaloguj</a></li>

                                </span>
                                ))}
                        </ul>
                    </div>
                </nav>
                <div className="baner"></div>
            </header>
        </div>
    </div>

}

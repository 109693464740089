import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Form, Grid, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class RodzajAktuForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RodzajAktu: [],
            nazwaRodzajuAktu: '',
            nazwaRodzajuAktuError: '',
            symbolAktu: '',
            symbolAktuError: '',
            domyslny: false,
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        if (this.props.mode === "add")
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie rodzaju aktu prawnego"});
        else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja rodzaju aktu prawnego"});
            this.getItem(this.props.id);
        }
    }

    onNazwaRodzajuAktuChange = (event) => {
        this.setState({nazwaRodzajuAktu: event.target.value});
    };

    onSymbolAktuChange = (event) => {
        this.setState({symbolAktu: event.target.value});
    };

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfRodzajAktuPrawnego(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const rodzajAktu = response.data.find((a) => a.id === id);

            this.setState({
                IdRodzajAktu: rodzajAktu.valueOf().id,
                nazwaRodzajuAktu: rodzajAktu.valueOf().nazwaRodzajuAktu,
                symbolAktu: rodzajAktu.valueOf().symbolAktu,
                domyslny: rodzajAktu.valueOf().domyslny
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let nazwaRodzajuAktuError, symbolAktuError = "";
        let isEmptyText = "Nie wypełniono tego pola";

        if (!this.state.nazwaRodzajuAktu) {
            nazwaRodzajuAktuError = isEmptyText;
        }

        if (!this.state.symbolAktu) {
            symbolAktuError = isEmptyText;
        }

        if (nazwaRodzajuAktuError || symbolAktuError) {
            this.setState({nazwaRodzajuAktuError, symbolAktuError});
            return false;
        }
        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {
            await api.addRodzajAktuPrawnego({
                id: '',
                nazwaRodzajuAktu: this.state.nazwaRodzajuAktu,
                symbolAktu: this.state.symbolAktu,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {
            await api.editRodzajAktuPrawnego(this.props.id, {
                id: '',
                nazwaRodzajuAktu: this.state.nazwaRodzajuAktu,
                symbolAktu: this.state.symbolAktu,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if(this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }
            this.setState({nazwaRodzajuAktuError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal}=this.props;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić nazwę rodzaju aktu prawnego</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={9}>
                                    <label>Nazwa rodzaju aktu prawnego:*</label>
                                    <input placeholder='Podaj nazwę rodzaju aktu prawnego' autoComplete="off" type="text" id="rodzaj"
                                           value={this.state.nazwaRodzajuAktu}
                                           onChange={this.onNazwaRodzajuAktuChange}/>
                                    {this.state.nazwaRodzajuAktuError ? (
                                        <Label color='red' pointing>
                                            {this.state.nazwaRodzajuAktuError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <label>Symbol rodzaju aktu prawnego:*</label>
                                    <input placeholder='Podaj symbol rodzaju aktu prawnego' autoComplete="off" type="text" id="symbol"
                                           value={this.state.symbolAktu}
                                           onChange={this.onSymbolAktuChange}/>
                                    {this.state.symbolAktuError ? (
                                        <Label color='red' pointing>
                                            {this.state.symbolAktuError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>

                    <Button primary>{this.state.modeName}</Button>

                    {modal ?
                         <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/aktyprawne/rodzajaktu'}><Button basic>Anuluj</Button></Link>
                    }
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/aktyprawne/rodzajaktu'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
import React, {Component} from 'react';
import StatusUmowyForm from "./StatusUmowyForm";


export default class StatusUmowyAdd extends Component {

    render() {
        return <div>
            <StatusUmowyForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

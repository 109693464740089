import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Form, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class PodmiotForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nazwaRodzajuPodmiotu: '',
            nazwaRodzajuPodmiotuError: '',
            domyslny: '',
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        if (this.props.mode === "add")
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie rodzaju podmiotu"});
        else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja rodzaju podmiotu"});
            this.getItem(this.props.id);
        }
    }

    onNazwaRodzajuPodmiotuChange = (event) => {
        this.setState({nazwaRodzajuPodmiotu: event.target.value});
    };

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfRodzajPodmiotu(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const rodzajpodmiotu = response.data.find((a) => a.id === id);

            this.setState({
                IdRodzajuPodmiotu: rodzajpodmiotu.valueOf().id,
                nazwaRodzajuPodmiotu: rodzajpodmiotu.valueOf().nazwaRodzajuPodmiotu,
                domyslny: rodzajpodmiotu.valueOf().domyslny
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let nazwaRodzajuPodmiotuError = "";
        let isEmptyText = "Nie wypełniono tego pola";

        if (!this.state.nazwaRodzajuPodmiotu) {
            nazwaRodzajuPodmiotuError = isEmptyText;
        }

        if (nazwaRodzajuPodmiotuError) {
            this.setState({nazwaRodzajuPodmiotuError});
            return false;
        }
        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {

            await api.addRodzajPomiotu({
                id: '',
                nazwaRodzajuPodmiotu: this.state.nazwaRodzajuPodmiotu,
                domyslny: false,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {

            await api.editRodzajPodmiotu(this.props.id, {
                id: '',
                nazwaRodzajuPodmiotu: this.state.nazwaRodzajuPodmiotu,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if(this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }
            this.setState({nazwaRodzajuPodmiotuError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal}=this.props;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić dane rodzaju podmiotu</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <label>Nazwa rodzaju podmiotu:*</label>
                        <input placeholder='Podaj nazwę rodzaju podmiotu' autoComplete="off" type="text" id="address"
                               value={this.state.nazwaRodzajuPodmiotu}
                               onChange={this.onNazwaRodzajuPodmiotuChange}/>
                        {this.state.nazwaRodzajuPodmiotuError ? (
                            <Label color='red' pointing>
                                {this.state.nazwaRodzajuPodmiotuError}
                            </Label>
                        ) : null}
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    {modal ?
                        <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/umowy/podmiot'}><Button basic>Anuluj</Button></Link>
                    }
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/umowy/podmiot'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
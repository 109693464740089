import React, {Component} from 'react';
import RodzajAktuForm from "./RodzajAktuForm";


export default class RodzajAktuAdd extends Component {

    render() {
        return <div>
            <RodzajAktuForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

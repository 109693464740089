import React, {Component} from 'react';
import JednostkaOdpowiedzialnaForm from "./JednostkaOdpowiedzialnaForm";


export default class JednostkaOdpowiedzialnaAdd extends Component {

    render() {
        return <div>
            <JednostkaOdpowiedzialnaForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

import React from 'react';
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

const Error = ({title, question, mode, confirm}) => {
    return (
        <Modal open={mode} basic size='small'>
            <Header icon='exclamation triangle' color='red' content={title}/>
            <Modal.Content>
                <p>
                    {question}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => confirm()} color='grey' inverted>
                    <Icon name='checkmark'/> Rozumiem
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default Error;


import React, {Component} from 'react';
import RodzajWalutyForm from "./RodzajWalutyForm";


export default class RodzajWalutyEdit extends Component {

    render() {
        return <div>
            <RodzajWalutyForm mode={'edit'} id={this.props.match.params.id}/>
        </div>

    }
}

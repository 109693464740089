import React, {Component} from 'react';
import {Message, Button, Icon, Header, Segment, Popup, Radio} from 'semantic-ui-react';
import {Link, Redirect} from 'react-router-dom';
import ErrorBoundary from '../../common/ErrorBoundary';
import Confirm from '../../common/Confirm';
import Spinner from '../../common/Spinner';
import {DefaultApi} from "../../api";
import Error from '../../common/Error';

class TableRow extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            value: ''
        }
    }

    handleChange() {
        this.props.onDomyslnyChange(this.props.data.id);
    }

    render() {
        return <tr>
            <td width="500px">{this.props.data.nazwaJednostkiInicjujacej}</td>
            <td><Radio onChange={this.handleChange} checked={this.props.radiovalue === this.props.data.id} value={this.props.data.id} name='radioGroup'/>
            </td>
            <td width="120px">
                <Popup
                    trigger={<Icon link name='edit' onClick={() => this.props.edit(this.props.data.id)}/>}
                    content='Edycja wybranej jednostki inicjującej'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                    content='Usunięcie wybranej jednostki inicjującej'
                    size='tiny'
                    inverted
                />
            </td>
        </tr>
    }
}


export default class JednostkaInicjujacaList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idJednostkiInicjujacej: '',
            isLoading: true,
            jednostkiInicjujace: [],
            domyslny: false,
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            radiovalue: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getJednostkiInicjujace();
    }

    getJednostkiInicjujace = async () => {
        const api = new DefaultApi({});
        api.listOfJednostkaInicjujaca().then((response) => {
                this.setState({jednostkiInicjujace: response.data, isLoading: false});
                const foundJednostkaInicjujaca = this.state.jednostkiInicjujace.find(jednostka => jednostka.domyslny === true);
                if (typeof foundJednostkaInicjujaca !== 'undefined')
                    this.setState({radiovalue: foundJednostkaInicjujaca.id});
            }
        ).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    listJednostkiInicjujace = () => {
        return (
            <Segment raised className={`panel`}>

                <Header as='h3'>
                    <Icon name='configure'/>
                    <Header.Content>
                        Lista jednostek inicjujących
                        <Header.Subheader>Zarządzanie jednostkami inicjującymi</Header.Subheader>
                    </Header.Content>
                </Header>

                <Link to={'/aktyprawne/jednostkainicjujaca/dodaj'}><Button primary>Dodaj jednostkę inicjującą</Button></Link>
                <table className="ui table">
                    <thead>
                    <tr>
                        <th>Nazwa jednostki inicjującej</th>
                        <th>Domyślna</th>
                        <th>Opcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.jednostkiInicjujace
                        .sort((a, b) => {
                            if (a.nazwaJednostkiInicjujacej != null)
                                return a.nazwaJednostkiInicjujacej.localeCompare(b.nazwaJednostkiInicjujacej)
                            else if (b.nazwaJednostkiInicjujacej != null)
                                return b.nazwaJednostkiInicjujacej.localeCompare(a.nazwaJednostkiInicjujacej)
                            return null
                        })
                        .map((jednostka, i) => <TableRow key={i} data={jednostka} radiovalue={this.state.radiovalue}
                                                         onDomyslnyChange={this.onDomyslnyChange}
                                                         remove={this.deleteJednostkaInicjujaca}
                                                         edit={this.editJednostkaInicjujaca}
                            />
                        )}
                    </tbody>
                </table>
                <Segment>
                    <Header as='h4' color='grey'>Legenda</Header>
                    <Icon link name='edit'/> - edycja wybranej jednostki inicjującej<br/>
                    <Icon link name='trash'/> - usunięcie wybranej jednostki inicjującej
                </Segment>
            </Segment>
        )
    };

    onDomyslnyChange = async (id) => {

        this.setState({radiovalue: id});

        const api = new DefaultApi({});
        await api.listOfJednostkaInicjujaca().then((response) => this.setState({jednostkaInicjujaca: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        /* ustawiamy flagę FALSE dla dokumentu(ów), który dotychczas miał ustawioną TRUE */
        const foundJednostkaInicjujacaTrue = this.state.jednostkaInicjujaca.filter(jednostkainicjujaca => jednostkainicjujaca.domyslny === true);

        for (const jednostkainicjujaca of foundJednostkaInicjujacaTrue) {
            if (jednostkainicjujaca.id !== id) {
                await api.editJednostkaInicjujaca(jednostkainicjujaca.id, {
                    id: '',
                    nazwaJednostkiInicjujacej: jednostkainicjujaca.nazwaJednostkiInicjujacej,
                    domyslny: false,
                }).catch((error) => {
                    Promise.resolve(error.response.data.msg).then((response) => {
                        this.setState({errorAPI: response, confirmError: true})
                    });
                });
            }
        }

        /* ustawiamy flagę TRUE dla dokumentu zaznaczonego */
        const foundJednostkaInicjujacaId = this.state.jednostkaInicjujaca.filter(jednostkainicjujaca => jednostkainicjujaca.id === id);
        await api.editJednostkaInicjujaca(id, {
            id: '',
            nazwaJednostkiInicjujacej: foundJednostkaInicjujacaId[0].nazwaJednostkiInicjujacej,
            domyslny: true
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    showJednostkaInicjujaca = () => {
        if (this.state.jednostkiInicjujace.length > 0) {
            return this.listJednostkiInicjujace()
        } else {
            if (this.state.isLoading) {
                return <Spinner/>
            } else {
                return <div>
                    <Message
                        color='red'
                        header='Nie ma zdefiniowanych jednostek inicjujących'
                        list={[
                            'Musisz zdefiniować jednostkę inicjującą. W tym celu kliknij przycisk "Dodaj jednostkę inicjującą"',
                        ]}
                    />
                    <Link to={'/aktyprawne/jednostkainicjujaca/dodaj'}><Button primary>Dodaj jednostkę inicjującą</Button></Link>
                </div>
            }
        }
    };


    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteJednostkaInicjujaca(this.state.idJednostkiInicjujacej).then(() => this.getJednostkiInicjujace()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handleCancel = () => this.setState({confirmDelete: false});

    handleError = () => this.setState({confirmError: false});

    deleteJednostkaInicjujaca = (id) => {
        this.setState({confirmDelete: true, idJednostkiInicjujacej: id});
    };

    editJednostkaInicjujaca = (id) => {
        this.setState({redirectEdit: true, idJednostkiInicjujacej: id})
    };


    render() {
        const {redirectEdit} = this.state;
        const {errorAPI} = this.state;

        return <ErrorBoundary>
            {this.showJednostkaInicjujaca()}

            <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                     title={'Usunięcie jednostki inicjującej'}
                     question={'Czy na pewno chcesz usunąć tą jednostkę inicjującą?'}/>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectEdit && (
                <Redirect push to={'/aktyprawne/jednostkainicjujaca/edytuj/' + this.state.idJednostkiInicjujacej}/>
            )}
        </ErrorBoundary>
    }
}

import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Form, Grid, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class OsobaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            osoby: [],
            idOsoby: '',
            imie: '',
            imieError: '',
            nazwisko: '',
            nazwiskoError: '',
            email: '',
            emailError: '',
            telefon: '',
            telefonError: '',
            telefon2: '',
            telefon2Error: '',
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        if (this.props.mode === "add")
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie osoby odpowiedzialnej za realizację umowy"});
        else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja osoby odpowiedzialnej za realizację umowy"});
            this.getItem(this.props.id);
        }
    }

    onImieChange = (event) => {
        this.setState({imie: event.target.value});
    };

    onNazwiskoChange = (event) => {
        this.setState({nazwisko: event.target.value});
    };

    onEmailChange = (event) => {
        this.setState({email: event.target.value});
    };

    onTelefonChange = (event) => {
        this.setState({telefon: event.target.value});
    };

    onTelefon2Change = (event) => {
        this.setState({telefon2: event.target.value});
    };

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfOsoba(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const osoba = response.data.find((a) => a.id === id);
            this.setState({
                imie: osoba.imie,
                nazwisko: osoba.nazwisko,
                email: osoba.email,
                telefon: osoba.telefon,
                telefon2: osoba.telefon2,
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let imieError, nazwiskoError, emailError = "";
        let isEmptyText = "Nie wypełniono tego pola";
        let isWrongEmail = "Podany zapis adresu e-mail nie jest poprawny";
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!this.state.imie) {
            imieError = isEmptyText;
        }

        if (!this.state.nazwisko) {
            nazwiskoError = isEmptyText;
        }

        if (!this.state.email) {
            emailError = isEmptyText;
        } else {
            if (!emailPattern.test(this.state.email))
                emailError = isWrongEmail;
        }

        if (imieError || nazwiskoError || emailError) {
            this.setState({imieError, nazwiskoError, emailError});
            return false;
        }
        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {

            await api.addOsoba({
                id: '',
                imie: this.state.imie,
                nazwisko: this.state.nazwisko,
                email: this.state.email,
                telefon: this.state.telefon,
                telefon2: this.state.telefon2,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {

            await api.editOsoba(this.props.id, {
                id: '',
                imie: this.state.imie,
                nazwisko: this.state.nazwisko,
                email: this.state.email,
                telefon: this.state.telefon,
                telefon2: this.state.telefon2,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if(this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }
            this.setState({nazwaRodzajuAktuError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal}=this.props;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić dane osoby</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={7}>
                                    <label>Imię osoby:*</label>
                                    <input placeholder='Podaj imię osoby' autoComplete="off" type="text" id="rodzaj"
                                           value={this.state.imie}
                                           onChange={this.onImieChange}/>
                                    {this.state.imieError ? (
                                        <Label color='red' pointing>
                                            {this.state.imieError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={7}>
                                    <label>Nazwisko osoby:*</label>
                                    <input placeholder='Podaj nazwisko osoby' autoComplete="off" type="text" id="symbol"
                                           value={this.state.nazwisko}
                                           onChange={this.onNazwiskoChange}/>
                                    {this.state.nazwiskoError ? (
                                        <Label color='red' pointing>
                                            {this.state.nazwiskoError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <label>Adres e-mail:*</label>
                                    <input placeholder='Podaj adres e-mail' autoComplete="off" type="text" id="rodzaj"
                                           value={this.state.email}
                                           onChange={this.onEmailChange}/>
                                    {this.state.emailError ? (
                                        <Label color='red' pointing>
                                            {this.state.emailError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <label>Numer telefonu:</label>
                                    <input placeholder='Podaj numer telefonu' autoComplete="off" type="text" id="symbol"
                                           value={this.state.telefon}
                                           onChange={this.onTelefonChange}/>
                                    {this.state.telefonError ? (
                                        <Label color='red' pointing>
                                            {this.state.telefonError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <label>Numer telefonu komórkowego:</label>
                                    <input placeholder='Podaj numer telefonu komórkowego' autoComplete="off" type="text" id="symbol"
                                           value={this.state.telefon2}
                                           onChange={this.onTelefon2Change}/>
                                    {this.state.telefon2Error ? (
                                        <Label color='red' pointing>
                                            {this.state.telefon2Error}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    {modal ?
                        <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/umowy/osoba'}><Button basic>Anuluj</Button></Link>
                    }
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/umowy/osoba'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import 'semantic-ui-css/semantic.min.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from "./Routes";
import Menu from "./Menu";
import Loading from "./Loading";

export default function App() {

    const {isLoading} = useAuth0();

    return (
        <div>
            {isLoading && <Loading/>}
            <Menu/>
            <div className="margin_application">
                <Router>
                    <Routes/>
                </Router>
            </div>
        </div>
    );
}
import React, {Component} from "react";
import {Icon, List, Popup} from "semantic-ui-react";
import {Link} from 'react-router-dom';

export default class FileList extends Component {

    render() {
        return <List horizontal>
            <List.Item>
                <List.Content>
                    <Icon name='file alternate outline'/>
                    <Link className="link-button" to='' onClick={e => this.props.load(e, this.props.data.id, this.props.data.nazwaPliku)}>{this.props.data.nazwaPliku}</Link>
                    {this.props.remove && <Popup
                        trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                        content='Usunięcie wybranego pliku'
                        size='tiny'
                        inverted
                    />
                    }
                </List.Content>
            </List.Item>
        </List>
    }
}

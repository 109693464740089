import React, {Component} from 'react';
import {Button, Icon, Header, Segment, Popup, Grid, Form, Input, GridRow} from 'semantic-ui-react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom'
import ErrorBoundary from '../common/ErrorBoundary';
import Confirm from '../common/Confirm';
import {DefaultApi} from "../api/index";
import AktyPrawneSearch from "./AktyPrawneSearch";
import Pagination from "react-js-pagination";
import Error from '../common/Error';

class TableRow extends Component {

    render() {
        return <tr>
            <td width="20%"><Link to="#" onClick={(e) => this.props.details(this.props.data.id, e)}>{this.props.data.numerRocznikAktu}</Link></td>
            <td width="40%">{this.props.data.przedmiotAktu}</td>
            <td width="20%">{this.props.data.dataWydania}</td>
            <td width="10%">{this.props.data.nazwaStatusuAktu}</td>
            <td width="10%">
                <Popup
                    trigger={<Icon link name='file alternate outline'
                                   onClick={(e) => this.props.details(this.props.data.id, e)}/>}
                    content='Informacje szczegółowe wybranego aktu prawnego'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='edit' onClick={() => this.props.edit(this.props.data.id)}/>}
                    content='Edycja wybranego aktu prawnego'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                    content='Usunięcie wybranego aktu prawnego (UWAGA: usuwane są również
                    wszystkie pliki zdefiniowane dla tego aktu prawnego)'
                    size='tiny'
                    inverted
                />
            </td>
        </tr>
    }
}


class AktyPrawneTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            aktyPrawne: [],
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            activePage: 1,
            liczbaAktow: 0,
            sortBy: this.props.sortBy,
            direction: this.props.direction,
            page: this.props.page,
            errorAPI: '',
            confirmError: false
        }
    }

    componentDidMount() {
        this.getAktyPrawne(this.props.sortBy, this.props.direction);
    }

    componentDidUpdate(prevProps) {

        if (this.props.sortBy !== prevProps.sortBy || this.props.direction !== prevProps.direction || this.props.page !== prevProps.page
            || this.props.searchWord !== prevProps.searchWord || this.props.searchAdvanced !== prevProps.searchAdvanced) {
            if (this.props.searchWord !== '') {
                this.wyszukajAktyPrawne();
            } else if (typeof this.props.searchAdvanced !== 'undefined') {
                this.wyszukajAktyPrawneZaawansowane();
            } else {
                this.getAktyPrawne();
            }
        }
    }

    deleteAktPrawny = (id) => {
        this.setState({confirmDelete: true, idAktuPrawnego: id});
    };

    editAktPrawny = (id) => {
        this.setState({redirectEdit: true, idAktuPrawnego: id})
    };

    detailsAktPrawny = (id, e) => {
        e.preventDefault();
        this.setState({showDetailsPanel: true, redirectDetail: true, idAktuPrawnego: id});
    };

    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteAktPrawny(this.state.idAktuPrawnego).then(() => this.getAktyPrawne()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handlePageChange = async (pageNumber) => {
        await this.setState({activePage: pageNumber});
        if (this.props.searchWord !== '') {
            this.wyszukajAktyPrawne();
        } else if (typeof this.props.searchAdvanced !== 'undefined') {
            this.wyszukajAktyPrawneZaawansowane();
        } else {
            this.getAktyPrawne();
        }
    }

    handleCancel = () => this.setState({confirmDelete: false});

    handleError = () => this.setState({confirmError: false});

    wyszukajAktyPrawneZaawansowane = async () => {
        const api = new DefaultApi({});
        const query = this.props.searchAdvanced;

        await api.zaawansowaneWyszukiwanieAktowPrawnych({
            dopasujWszystkie: query.matchAllAttributes,
            przedmiotAktu: query.przedmiotAktu,
            numerRocznikAktu: query.numerRocznikAktu,
            dataWydaniaOd: query.dataWydaniaOd,
            dataWydaniaDo: query.dataWydaniaDo,
            dataObowiazywaniaOd: null,
            dataObowiazywaniaDo: null,
            tagi: null,
            statusAktu: query.nazwaStatusu,
            rodzajAktu: query.nazwaRodzaju,
            jednostkaInicjujaca: query.nazwaJednostki,
            trescWord: null,
            czyPlikWordPubliczny: null,
            czyMaPlikWord: null,
            czyMaZalaczniki: null,
            zmieniaAktyPrawne: null,
            uchylaAktyPrawne: null,
            page: this.state.activePage,
            limit: process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE,
            sort: this.props.sortBy,
            asc: this.props.direction
        }).then((response) => {
            this.setState({aktyPrawne: response.data.list, liczbaAktow: response.data.count, isLoading: false});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    wyszukajAktyPrawne = async () => {
        const api = new DefaultApi({});
        await api.wyszukajAktPrawny(
            this.props.searchWord,
            this.state.activePage,
            process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE,
            this.props.sortBy,
            this.props.direction
        ).then((response) => {
            this.setState({aktyPrawne: response.data.list, liczbaAktow: response.data.count, isLoading: false});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

    };

    getAktyPrawne() {
        const api = new DefaultApi({});
        api.listOfAktPrawnyEL(
            this.state.activePage,
            process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE,
            this.props.sortBy,
            this.props.direction
        ).then((response) => {
            this.setState({aktyPrawne: response.data.list, liczbaAktow: response.data.count, isLoading: false})
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    }

    render() {
        const {redirectEdit} = this.state;
        const {redirectDetail} = this.state;
        const maxNumber = process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE;
        const pageRange = process.env.REACT_APP_PAGE_RANGE;
        const {errorAPI} = this.state;

        return (
            <tbody>
            {this.state.aktyPrawne.length > 0 ? (
                this.state.aktyPrawne.map((aktyprawne, i) => <TableRow key={i} data={aktyprawne}
                                                                       remove={this.deleteAktPrawny}
                                                                       edit={this.editAktPrawny}
                                                                       details={this.detailsAktPrawny}
                />)) : <tr>
                <td colSpan="5"><strong>Nie znaleziono aktów prawnych</strong></td>
            </tr>
            }

            {(this.state.liczbaAktow / maxNumber) > 1 &&
            <tr>
                <td colSpan="5">
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={Number(maxNumber)}
                        totalItemsCount={this.state.liczbaAktow}
                        pageRangeDisplayed={Number(pageRange)}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </td>
            </tr>
            }

            <tr>
                <td colSpan="5">
                    <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                             title={'Usunięcie aktu prawnego'}
                             question={'Czy na pewno chcesz usunąć ten akt prawny?'}/>

                    {redirectDetail && (
                        <Redirect push to={'/aktyprawne/details/' + this.state.idAktuPrawnego}/>
                    )}

                    {redirectEdit && (
                        <Redirect push to={'/aktyprawne/edytuj/' + this.state.idAktuPrawnego}/>
                    )}


                    {errorAPI && <Error mode={this.state.confirmError}
                                        confirm={this.handleError}
                                        title={'Wystąpił błąd!'}
                                        question={errorAPI}/>}
                </td>
            </tr>
            </tbody>
        )
    }
}


export default class AktyPrawneList extends Component {

    constructor(props) {
        super(props);
        this.handleChangeValue = this.handleChangeValue.bind(this);

        this.state = {
            isLoading: true,
            aktyPrawne: [],
            hasError: false,
            searchWord: '',
            showSearchPanel: false,
            showDetailsPanel: false,
            idAktuPrawnego: '',
            sortBy: '',
            direction: false,
            sortDir_numerRocznikAktu: false,
            sortDir_przedmiotAktu: false,
            sortDir_dataWydania: false,
            sortDir_fkIdStatusAktu: false
        };
    }

    handleChangeSortBy = (sortValue) => {
        this.setState({sortBy: sortValue});

        if (sortValue === "numerRocznikAktu") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_numerRocznikAktu: !this.state.sortDir_numerRocznikAktu})
            } else {
                this.setState({sortDir_numerRocznikAktu: true})
            }

            this.setState({direction: this.state.sortDir_numerRocznikAktu});
        }

        if (sortValue === "przedmiotAktu") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_przedmiotAktu: !this.state.sortDir_przedmiotAktu})
            } else {
                this.setState({sortDir_przedmiotAktu: true})
            }

            this.setState({direction: this.state.sortDir_przedmiotAktu});
        }

        if (sortValue === "dataWydania") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_dataWydania: !this.state.sortDir_dataWydania})
            } else {
                this.setState({sortDir_dataWydania: true})
            }

            this.setState({direction: this.state.sortDir_dataWydania});
        }

        if (sortValue === "fkIdStatusAktu") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_fkIdStatusAktu: !this.state.sortDir_fkIdStatusAktu})
            } else {
                this.setState({sortDir_fkIdStatusAktu: true})
            }

            this.setState({direction: this.state.sortDir_fkIdStatusAktu});
        }

    }

    handleChangeValue(searchKeyWords) {
        this.setState({searchAdvanced: searchKeyWords, searchWord: ''});
    }

    handleSearchPanel = () => {
        this.setState((prevState) => ({showSearchPanel: !prevState.showSearchPanel}));
    }

    onWyszukajChange = async (e, data) => {
        await this.setState({searchWord: data.value, showSearchPanel: false});
    }


    render() {
        const {showSearchPanel} = this.state;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>

                <Header as='h3'>
                    <Icon name='clipboard outline'/>
                    <Header.Content>
                        Lista aktów prawnych
                        <Header.Subheader>Zarządzanie aktami prawnymi</Header.Subheader>
                    </Header.Content>
                </Header>

                <Grid divided='vertically'>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Link to={'/aktyprawne/dodaj'}><Button primary>Dodaj akt prawny</Button></Link>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Form.Field>
                                <Input placeholder='Wprowadź słowo kluczowe' autoComplete="off" type="text"
                                       id="searchUmowa" className="wyszukaj"
                                       value={this.state.searchWord}
                                       onChange={this.onWyszukajChange}/>
                                <Button color='blue' as="label"
                                        content={showSearchPanel ? 'Ukryj panel' : 'Wyszukiwanie zaawansowane'}
                                        onClick={this.handleSearchPanel}/>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    {showSearchPanel && <GridRow>
                        <Grid.Column>
                            <AktyPrawneSearch value={this.props.value} onAktyPrawneChange={this.handleChangeValue}/>
                        </Grid.Column>
                    </GridRow>
                    }
                </Grid>


                <table className="ui table">
                    <thead>
                    <tr>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("numerRocznikAktu")}>Numer i rocznik</button>
                            {this.state.sortBy === "numerRocznikAktu" &&
                            (this.state.sortDir_numerRocznikAktu ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("przedmiotAktu")}>Przedmiot aktu prawnego</button>
                            {this.state.sortBy === "przedmiotAktu" &&
                            (this.state.sortDir_przedmiotAktu ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("dataWydania")}>Data wydania</button>
                            {this.state.sortBy === "dataWydania" &&
                            (this.state.sortDir_dataWydania ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("fkIdStatusAktu")}>Status</button>
                            {this.state.sortBy === "fkIdStatusAktu" &&
                            (this.state.sortDir_fkIdStatusAktu ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>Opcje</th>
                    </tr>
                    </thead>
                    <AktyPrawneTable sortBy={this.state.sortBy} direction={this.state.direction} page={this.state.activePage}
                                     searchWord={this.state.searchWord} searchAdvanced={this.state.searchAdvanced}/>

                </table>

                <Segment>
                    <Header as='h4' color='grey'>Legenda</Header>
                    <Icon link name='edit'/> - edycja wybranego aktu prawnego<br/>
                    <Icon link name='trash'/> - usunięcie wybranego aktu prawnego
                </Segment>
            </Segment>


        </ErrorBoundary>
    }
}

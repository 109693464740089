import React, {Component} from "react";
import {Button, Grid, Form, Segment, Label, Checkbox, Radio, Input} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

export default class UmowySearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            matchAllAttributes: false,
            nettoBrutto: 'brutto',
            numerUmowy: '',
            dataZawarciaOd: '',
            dataZawarciaDo: '',
            rodzajUmowy: '',
            nazwaWykonawcy: '',
            przedmiotUmowy: '',
            wartoscOd: '',
            wartoscDo: '',
            terminRealizacjiOd: '',
            terminRealizacjiDo: '',
            nazwaJednostki: '',
            nazwaOsoby: '',
            nazwaStatusu: '',
            umowyZaawansowane: [],
            dataZawarciaError: '',
            terminRealizacjiError: '',
            wartoscError: '',
            showAneksy: false,
        };
    }

    onDataZawarciaOdChange = (e, data) => {
        this.setState({dataZawarciaOd: data.value});
    };

    onDataZawarciaDoChange = (e, data) => {
        this.setState({dataZawarciaDo: data.value});
    };

    onNumerUmowyChange = (e) => {
        e.preventDefault();
        this.setState({numerUmowy: e.target.value});
    };

    onRodzajUmowyChange = (e) => {
        e.preventDefault();
        this.setState({rodzajUmowy: e.target.value});
    };

    onNazwaWykonawcyChange = (e) => {
        e.preventDefault();
        this.setState({nazwaWykonawcy: e.target.value});
    };

    onPrzedmiotUmowyChange = (e) => {
        e.preventDefault();
        this.setState({przedmiotUmowy: e.target.value});
    };

    onWartoscOdChange = (e) => {
        this.setState({wartoscOd: e.target.value});
    };

    onWartoscDoChange = (e) => {
        this.setState({wartoscDo: e.target.value});
    };

    onTerminRealizacjiOdChange = (e, data) => {
        this.setState({terminRealizacjiOd: data.value});
    };

    onTerminRealizacjiDoChange = (e, data) => {
        this.setState({terminRealizacjiDo: data.value});
    };

    onNazwaJednostkiChange = (e) => {
        e.preventDefault();
        this.setState({nazwaJednostki: e.target.value});
    };

    onNazwaOsobyChange = (e) => {
        e.preventDefault();
        this.setState({nazwaOsoby: e.target.value});
    };

    onNazwaStatusuChange = (e) => {
        e.preventDefault();
        this.setState({nazwaStatusu: e.target.value});
    };

    onMatchAllAttribitesChange = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({matchAllAttributes: !prevState.matchAllAttributes}));
    };

    onShowAneksyChange = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({showAneksy: !prevState.showAneksy}));
    };

    onNettoBruttoChange = (e, {value}) => {
        e.preventDefault();
        this.setState({nettoBrutto: value});
    };

    validateForm = () => {

        let dataZawarciaError, terminRealizacjiError, wartoscError = "";
        let isWrongDate = "Data końcowa nie może być wcześniejsza od daty początkowej";
        let isWrongNumber = "Wartość minimalna nie może być większa lub równa wartości maksymalnej";
        let isWrongValue = "Wartość nie może być mniejsza od 0";

        if (this.state.dataZawarciaOd && this.state.dataZawarciaDo) {
            if (this.state.dataZawarciaOd >= this.state.dataZawarciaDo) {
                dataZawarciaError = isWrongDate;
            }
        }

        if (this.state.terminRealizacjiOd && this.state.terminRealizacjiDo) {
            if (this.state.terminRealizacjiOd >= this.state.terminRealizacjiDo) {
                terminRealizacjiError = isWrongDate;
            }
        }

        if (this.state.wartoscOd && this.state.wartoscDo) {
            if (this.state.wartoscOd >= this.state.wartoscDo) {
                wartoscError = isWrongNumber;
            }
        }

        if (this.state.wartoscOd < 0 || this.state.wartoscDo < 0) {
            wartoscError = isWrongValue;
        }

        this.setState({dataZawarciaError});
        this.setState({terminRealizacjiError});
        this.setState({wartoscError});

        return !(dataZawarciaError || terminRealizacjiError || wartoscError);
    };

    onFormSubmit = async (event) => {
        event.preventDefault();
        const isValid = this.validateForm();

        if (isValid) {
            this.props.onUmowyChange(this.state);
        } else {
            console.log("Błędnie wypełniony formularz");
        }
    };

    render() {

        return <Segment raised>
            <Label as='a' color='blue' ribbon>
                Wyszukiwanie zaawansowane
            </Label>
            <br/><br/>
            <Form onSubmit={this.onFormSubmit}>
                <Form.Field>
                    <label>Numer umowy:</label>
                    <input placeholder='Wprowadź numer umowy' autoComplete="off" type="text"
                           value={this.state.numerUmowy}
                           onChange={this.onNumerUmowyChange}/>
                </Form.Field>

                <Form.Field>
                    <label>Data zawarcia umowy:</label>
                    <div>
                        od:&nbsp;&nbsp;<SemanticDatepicker onChange={this.onDataZawarciaOdChange} format='DD-MM-YYYY'
                                                           locale="en-US"
                                                           value={this.state.dataZawarciaOd}/>&nbsp;&nbsp;
                        do:&nbsp;&nbsp; <SemanticDatepicker onChange={this.onDataZawarciaDoChange} format='DD-MM-YYYY'
                                                            locale="en-US"
                                                            value={this.state.dataZawarciaDo}/>
                    </div>
                    {this.state.dataZawarciaError ? (
                        <Label color='red' pointing>
                            {this.state.dataZawarciaError}
                        </Label>
                    ) : null}
                </Form.Field>

                <Form.Field>
                    <label>Rodzaj umowy:</label>
                    <input placeholder='Wprowadź nazwę rodzaju umowy' autoComplete="off" type="text"
                           value={this.state.rodzajUmowy}
                           onChange={this.onRodzajUmowyChange}/>
                </Form.Field>

                <Form.Field>
                    <label>Nazwa wykonawcy:</label>
                    <input placeholder='Wprowadź nazwę wykonawcy umowy' autoComplete="off" type="text"
                           value={this.state.nazwaWykonawcy}
                           onChange={this.onNazwaWykonawcyChange}/>
                </Form.Field>

                <Form.TextArea onChange={this.onPrzedmiotUmowyChange} label='Przedmiot umowy:'
                               placeholder='Wprowadź przedmiot umowy' value={this.state.przedmiotUmowy}/>

                <Form.Field>
                    <label>Wartość umowy:</label>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Input autoComplete="off" type="number"
                                       value={this.state.wartoscOd}
                                       onChange={this.onWartoscOdChange}
                                       label="od"/>
                            </Grid.Column>
                            <Grid.Column width={1}/>
                            <Grid.Column width={3}>
                                <Input autoComplete="off" type="number"
                                       value={this.state.wartoscDo}
                                       onChange={this.onWartoscDoChange}
                                       label="do"/>
                            </Grid.Column>
                        </Grid.Row>
                        {this.state.wartoscError ? (
                            <Grid.Row>
                                <Label color='red' pointing>
                                    {this.state.wartoscError}
                                </Label>
                            </Grid.Row>
                        ) : null}
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Radio
                                    label='Wartość netto'
                                    name='radioGroup'
                                    value='netto'
                                    checked={this.state.nettoBrutto === 'netto'}
                                    onChange={this.onNettoBruttoChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Radio
                                    label='Wartość brutto'
                                    name='radioGroup'
                                    value='brutto'
                                    checked={this.state.nettoBrutto === 'brutto'}
                                    onChange={this.onNettoBruttoChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form.Field>

                <Form.Field>
                    <label>Termin realizacji umowy:</label>
                    <div>
                        od:&nbsp;&nbsp;<SemanticDatepicker onChange={this.onTerminRealizacjiOdChange}
                                                           format='DD-MM-YYYY'
                                                           locale="en-US"
                                                           value={this.state.terminRealizacjiOd}/>&nbsp;&nbsp;
                        do:&nbsp;&nbsp; <SemanticDatepicker onChange={this.onTerminRealizacjiDoChange}
                                                            format='DD-MM-YYYY'
                                                            locale="en-US"
                                                            value={this.state.terminRealizacjiDo}/>
                    </div>
                    {this.state.terminRealizacjiError ? (
                        <Label color='red' pointing>
                            {this.state.terminRealizacjiError}
                        </Label>
                    ) : null}
                </Form.Field>

                <Form.Field>
                    <label>Jednostka odpowiedzialna za realizację umowy:</label>
                    <input placeholder='Wprowadź nazwę jednostki odpowiedzialnej' autoComplete="off" type="text"
                           value={this.state.nazwaJednostki}
                           onChange={this.onNazwaJednostkiChange}/>
                </Form.Field>

                <Form.Field>
                    <label>Osoba odpowiedzialna za realizację umowy:</label>
                    <input placeholder='Wprowadź nazwę osoby odpowiedzialnej' autoComplete="off" type="text"
                           value={this.state.nazwaOsoby}
                           onChange={this.onNazwaOsobyChange}/>
                </Form.Field>

                <Form.Field>
                    <label>Status:</label>
                    <input placeholder='Wprowadź nazwę statusu' autoComplete="off" type="text"
                           value={this.state.nazwaStatusu}
                           onChange={this.onNazwaStatusuChange}/>
                </Form.Field>

                <Form.Field>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Checkbox label='Dopasuj wszystkie pola...'
                                          onChange={this.onMatchAllAttribitesChange}
                                          checked={this.state.matchAllAttributes}
                                /></Grid.Column>
                            <Grid.Column width={5}>
                                <Checkbox label='Przeszukaj również aneksy...'
                                          onChange={this.onShowAneksyChange}
                                          checked={this.state.showAneksy}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form.Field>

                <Button primary>Wyszukaj umowy</Button>
            </Form>
        </Segment>
    }
}

import React, {Component} from 'react';
import {Button, Checkbox, Form, Grid, GridRow, Header, Icon, Input, Popup, Segment} from 'semantic-ui-react';
import {Link, Redirect} from 'react-router-dom';
import ErrorBoundary from '../common/ErrorBoundary';
import Confirm from '../common/Confirm';
import {DefaultApi} from "../api/index";
import UmowySearch from "./UmowySearch";
import Pagination from "react-js-pagination";
import Error from '../common/Error';

class TableRow extends Component {

    render() {
        const czyMaAneksy = this.props.data.czyMaAneksy;
        const numberPattern = /^[0-9]+(.0)?$/;
        let iconAneks, wartoscBrutto;

        if (czyMaAneksy) {
            iconAneks = <Icon link name='inbox'/>;
        }

        if (numberPattern.test(this.props.data.wartoscBrutto)) {
            wartoscBrutto = this.props.data.wartoscBrutto + "0";
        } else {
            wartoscBrutto = this.props.data.wartoscBrutto;
        }

        return (<tr>
            <td width="12%">{iconAneks}&nbsp;<Link to="#" onClick={(e) => this.props.details(this.props.data.id, e)}>{this.props.data.numerUmowy}</Link></td>
            <td width="10%">{this.props.data.nazwaWykonawcy}</td>
            <td width="28%">{this.props.data.przedmiotUmowy}</td>
            <td width="10%">{wartoscBrutto}</td>
            <td width="10%">{this.props.data.dataZawarcia}</td>
            <td width="10%">{this.props.data.nazwaJednostki}</td>
            <td width="10%">{this.props.data.nazwaStatusu}</td>
            <td width="10%">
                <Popup
                    trigger={<Icon link name='file alternate outline' onClick={(e) => this.props.details(this.props.data.id, e)}/>}
                    content='Informacje szczegółowe wybranej umowy'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='edit' onClick={() => this.props.edit(this.props.data.id)}/>}
                    content='Edycja wybranej umowy'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                    content='Usunięcie wybranej umowy (UWAGA: usuwane są również
                    wszystkie pliki zdefiniowane dla tej umowy)'
                    size='tiny'
                    inverted
                />
            </td>
        </tr>)
    }
}

class UmowyTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            umowy: [],
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            activePage: 1,
            liczbaUmow: 0,
            sortBy: this.props.sortBy,
            direction: this.props.direction,
            errorAPI: '',
            confirmError: false
        }

    }

    componentDidMount() {
        this.getUmowy();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sortBy !== prevProps.sortBy
            || this.props.direction !== prevProps.direction || this.props.page !== prevProps.page
            || this.props.searchWord !== prevProps.searchWord || this.props.showAneksy !== prevProps.showAneksy
            || this.props.searchAdvanced !== prevProps.searchAdvanced) {
            if (this.props.searchWord !== '') {
                this.wyszukajUmowy();
            } else if (typeof this.props.searchAdvanced !== 'undefined') {
                this.wyszukajZaawansowaneUmowy();
            } else {
                this.getUmowy();
            }
        }
    }

    deleteUmowa = (id) => {
        this.setState({confirmDelete: true, idUmowy: id});
    };

    editUmowa = (id) => {
        this.setState({redirectEdit: true, idUmowy: id})
    };

    detailsUmowa = (id, e) => {
        e.preventDefault();
        this.setState({showDetailsPanel: true, redirectDetail: true, idUmowy: id});
    };

    closeDetails = () => {
        this.setState({showDetailsPanel: false})
    }

    handleError = () => this.setState({confirmError: false});

    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteUmowa(this.state.idUmowy).then(() => this.getUmowy()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handlePageChange = async (pageNumber) => {
        await this.setState({activePage: pageNumber});
        if (this.props.searchWord !== '') {
            this.wyszukajUmowy();
        } else if (typeof this.props.searchAdvanced !== 'undefined') {
            this.wyszukajZaawansowaneUmowy();
        } else {
            this.getUmowy();
        }
    }

    handleCancel = () => this.setState({confirmDelete: false});


    wyszukajZaawansowaneUmowy = () => {
        const query = this.props.searchAdvanced;
        let bruttoOd, bruttoDo, nettoOd, nettoDo;

        if (query.nettoBrutto === "netto") {
            nettoOd = query.wartoscOd;
            nettoDo = query.wartoscDo;
        } else if (query.nettoBrutto === "brutto") {
            bruttoOd = query.wartoscOd;
            bruttoDo = query.wartoscDo;
        }

        const api = new DefaultApi({});
        api.zaawansowaneWyszukiwanieUmow({
            dopasujWszystkie: query.matchAllAttributes,
            dataZawarciaOd: query.dataZawarciaOd,
            dataZawarciaDo: query.dataZawarciaDo,
            numerUmowy: query.numerUmowy,
            przedmiotUmowy: query.przedmiotUmowy,
            terminRealizacjiOd: query.terminRealizacjiOd,
            terminRealizacjiDo: query.terminRealizacjiDo,
            bruttoOd: bruttoOd,
            bruttoDo: bruttoDo,
            nettoOd: nettoOd,
            nettoDo: nettoDo,
            czyAneks: query.showAneksy,
            czyMaAneksy: null,
            trescWord: null,
            osoba: query.nazwaOsoby,
            jednostka: query.nazwaJednostki,
            wykonawca: query.nazwaWykonawcy,
            statusUmowy: query.nazwaStatusu,
            rodzajUmowy: query.rodzajUmowy,
            waluta: null,
            page: this.state.activePage,
            limit: process.env.REACT_APP_MAX_NUMBER_UMOWY,
            sort: this.props.sortBy,
            asc: this.props.direction
        }).then((response) => this.setState({umowy: response.data.list, liczbaUmow: response.data.count, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };


    wyszukajUmowy = () => {
        const api = new DefaultApi({});
        if (this.props.searchWord.length > 0)
            api.wyszukajUmowe(
                this.props.searchWord,
                this.props.showAneksy,
                this.state.activePage,
                process.env.REACT_APP_MAX_NUMBER_UMOWY,
                this.props.sortBy,
                this.props.direction
            ).then((response) => this.setState({umowy: response.data.list, liczbaUmow: response.data.count, isLoading: false})).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
    };

    getUmowy() {
        const api = new DefaultApi({});
        api.listOfUmowa(
            this.state.activePage,
            process.env.REACT_APP_MAX_NUMBER_UMOWY,
            this.props.sortBy,
            this.props.direction
        ).then((response) => this.setState({umowy: response.data.list, liczbaUmow: response.data.count, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        if (this.state.umowy.length > 0) {
            this.setState({umowy: this.state.umowy.filter(umowa => umowa.czyAneks === false)});
        }
    }

    render() {
        const {redirectEdit} = this.state;
        const {redirectDetail} = this.state;
        const maxNumber = process.env.REACT_APP_MAX_NUMBER_UMOWY;
        const pageRange = process.env.REACT_APP_PAGE_RANGE;
        const {errorAPI} = this.state;

        return (
            <tbody>
            {this.state.umowy.length > 0 ? (
                this.state.umowy.map((umowy, i) => <TableRow key={i} data={umowy}
                                                             remove={this.deleteUmowa}
                                                             edit={this.editUmowa}
                                                             details={this.detailsUmowa}
                />)) : <tr>
                <td colSpan="8"><strong>Nie znaleziono umów</strong></td>
            </tr>}

            {(this.state.liczbaUmow / maxNumber) > 1 &&
            <tr>
                <td colSpan="8">
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={Number(maxNumber)}
                        totalItemsCount={this.state.liczbaUmow}
                        pageRangeDisplayed={Number(pageRange)}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </td>
            </tr>
            }

            <tr>
                <td colSpan="8">
                    <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                             title={'Usunięcie umowy'}
                             question={'Czy na pewno chcesz usunąć tą umowę?'}/>

                    {errorAPI && <Error mode={this.state.confirmError}
                                        confirm={this.handleError}
                                        title={'Wystąpił błąd!'}
                                        question={errorAPI}/>}

                    {redirectEdit && (
                        <Redirect push to={'/umowy/edytuj/' + this.state.idUmowy}/>
                    )}
                    {redirectDetail && (
                        <Redirect push to={'/umowy/details/' + this.state.idUmowy}/>
                    )}

                </td>
            </tr>
            </tbody>)
    }
}

export default class UmowyList extends Component {

    constructor(props) {
        super(props);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.state = {
            isLoading: true,
            idUmowy: '',
            umowy: [],
            aneksy: [],
            wykonawcy: [],
            jednostki: [],
            statusy: [],
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            slowaKluczowe: '',
            showAneksy: false,
            showSearchPanel: false,
            showDetailsPanel: false,
            activePage: 1,
            liczbaUmow: 0,
            sortBy: '',
            direction: false,
            sortDir_numerUmowy: false,
            sortDir_fkIdWykonawcy: false,
            sortDir_przedmiotUmowy: false,
            sortDir_brutto: false,
            sortDir_dataZawarcia: false,
            sortDir_fkIdJednostki: false,
            sortDir_fkIdStatusUmowy: false,
            searchWord: '',
            searchAdvanced: '',
        };
    }


    handleChangeSortBy = (sortValue) => {
        this.setState({sortBy: sortValue});

        if (sortValue === "numerUmowy") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_numerUmowy: !this.state.sortDir_numerUmowy})
            } else {
                this.setState({sortDir_numerUmowy: true})
            }

            this.setState({direction: this.state.sortDir_numerUmowy});
        }

        if (sortValue === "fkIdWykonawcy") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_fkIdWykonawcy: !this.state.sortDir_fkIdWykonawcy})
            } else {
                this.setState({sortDir_fkIdWykonawcy: true})
            }

            this.setState({direction: this.state.sortDir_fkIdWykonawcy});
        }

        if (sortValue === "przedmiotUmowy") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_przedmiotUmowy: !this.state.sortDir_przedmiotUmowy})
            } else {
                this.setState({sortDir_przedmiotUmowy: true})
            }

            this.setState({direction: this.state.sortDir_przedmiotUmowy});
        }

        if (sortValue === "brutto") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_brutto: !this.state.sortDir_brutto})
            } else {
                this.setState({sortDir_brutto: true})
            }

            this.setState({direction: this.state.sortDir_brutto});
        }

        if (sortValue === "dataZawarcia") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_dataZawarcia: !this.state.sortDir_dataZawarcia})
            } else {
                this.setState({sortDir_dataZawarcia: true})
            }

            this.setState({direction: this.state.sortDir_dataZawarcia});
        }

        if (sortValue === "fkIdJednostki") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_fkIdJednostki: !this.state.sortDir_fkIdJednostki})
            } else {
                this.setState({sortDir_fkIdJednostki: true})
            }

            this.setState({direction: this.state.sortDir_fkIdJednostki});
        }

        if (sortValue === "fkIdStatusUmowy") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_fkIdStatusUmowy: !this.state.sortDir_fkIdStatusUmowy})
            } else {
                this.setState({sortDir_fkIdStatusUmowy: true})
            }

            this.setState({direction: this.state.sortDir_fkIdStatusUmowy});
        }
    }

    handleChangeValue(searchKeyWords) {
        this.setState({searchAdvanced: searchKeyWords, searchWord: ''});
    }

    showSearchPanel = () => {
        this.setState((prevState) => ({showSearchPanel: !prevState.showSearchPanel}));
    }

    onWyszukajChange = (e, data) => {
        this.setState({searchWord: data.value, showSearchPanel: false});
    }

    onShowAneksyClick = () => {
        this.setState((prevState) => ({showAneksy: !prevState.showAneksy}));
    };

    render() {
        const {showSearchPanel} = this.state;
        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Header as='h3'>
                    <Icon name='clipboard outline'/>
                    <Header.Content>
                        Lista umów
                        <Header.Subheader>Zarządzanie umowami</Header.Subheader>
                    </Header.Content>
                </Header>
                <Grid divided='vertically'>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Link to={'/umowy/dodaj'}><Button primary>Dodaj umowę</Button></Link>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Form.Field>
                                <Input placeholder='Wprowadź słowo kluczowe' autoComplete="off" type="text" id="searchUmowa" className="wyszukaj"
                                       value={this.state.searchWord}
                                       onChange={this.onWyszukajChange}/>
                                <Button color='blue' as="label"
                                        content={showSearchPanel ? 'Ukryj panel' : 'Wyszukiwanie zaawansowane'}
                                        onClick={this.showSearchPanel}/>
                                <br/><br/>
                                <Checkbox label='Przeszukaj również aneksy...'
                                          onChange={this.onShowAneksyClick}
                                          checked={this.state.showAneksy}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    {showSearchPanel && <GridRow>
                        <Grid.Column>
                            <UmowySearch value={this.props.value} onUmowyChange={this.handleChangeValue}/>
                        </Grid.Column>
                    </GridRow>
                    }
                </Grid>
                <table className="ui table">
                    <thead>
                    <tr>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("numerUmowy")}>Numer i rocznik</button>
                            {this.state.sortBy === "numerUmowy" &&
                            (this.state.sortDir_numerUmowy ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("fkIdWykonawcy")}>Wykonawca</button>
                            {this.state.sortBy === "fkIdWykonawcy" &&
                            (this.state.sortDir_fkIdWykonawcy ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("przedmiotUmowy")}>Przedmiot umowy</button>
                            {this.state.sortBy === "przedmiotUmowy" &&
                            (this.state.sortDir_przedmiotUmowy ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("brutto")}>Kwota brutto</button>
                            {this.state.sortBy === "brutto" &&
                            (this.state.sortDir_brutto ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("dataZawarcia")}>Data zawarcia</button>
                            {this.state.sortBy === "dataZawarcia" &&
                            (this.state.sortDir_dataZawarcia ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("fkIdJednostki")}>Jednostka</button>
                            {this.state.sortBy === "fkIdJednostki" &&
                            (this.state.sortDir_fkIdJednostki ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>
                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("fkIdStatusUmowy")}>Status</button>
                            {this.state.sortBy === "fkIdStatusUmowy" &&
                            (this.state.sortDir_fkIdStatusUmowy ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                        </th>
                        <th>Opcje</th>
                    </tr>
                    </thead>
                    <UmowyTable sortBy={this.state.sortBy} direction={this.state.direction} page={this.state.activePage} searchWord={this.state.searchWord}
                                showAneksy={this.state.showAneksy} searchAdvanced={this.state.searchAdvanced}/>
                </table>
                <Segment>
                    <Header as='h4' color='grey'>Legenda</Header>
                    <Icon link name='inbox'/> - umowa posiada aneksy<br/>
                    <Icon link name='edit'/> - edycja wybranej umowy<br/>
                    <Icon link name='trash'/> - usunięcie wybranej umowy
                </Segment>
            </Segment>
        </ErrorBoundary>
    }
}

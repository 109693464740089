import React, {Component} from 'react';
import {Button, Checkbox, Dropdown, Form, Grid, Header, Icon, Label, Segment} from 'semantic-ui-react';
import ErrorBoundary from '../common/ErrorBoundary';
import {Redirect} from 'react-router-dom';
import {DefaultApi} from "../api/index";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import FileList from "../common/FileList";
import {ZalacznikAktuPrawnegoRodzajPlikuEnum} from "../api/ts/model";
import Axios from "../common/Axios";
import Confirm from "../common/Confirm";
import AktyPrawneSearchModal from "./AktyPrawneSearchModal";
import download from 'downloadjs';
import ItemList from "../common/ItemList";
import AktyPrawneDetail from "./AktyPrawneDetail";
import Error from '../common/Error';
import RodzajAktuModal from "./ustawienia/RodzajAktuModal";
import StatusAktuPrawnegoModal from "./ustawienia/StatusAktuPrawnegoModal";
import JednostkaInicjujacaModal from "./ustawienia/JednostkaInicjujacaModal";

export default class AktyPrawneForm extends Component {

    constructor(props) {
        super(props);
        this.onZmienianyAktPrawnyChange = this.onZmienianyAktPrawnyChange.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.state = {
            idAktuPrawnego: this.props.id,
            IdRodzajAktu: '',
            numerRocznikAktu: '',
            dataWydania: new Date(),
            przedmiotAktu: '',
            zmieniaAktyPrawne: [],
            uchylaAktyPrawne: [],
            IdStatusAktu: '',
            IdJednostkaInicjujaca: '',
            description: '',

            IdRodzajAktuError: '',
            numerRocznikAktuError: '',
            dataWydaniaError: '',
            przedmiotAktuError: '',
            IdStatusAktuError: '',
            IdJednostkaInicjujacaError: '',

            redirectSwitch: false,
            modeName: '',
            modeTitle: '',
            aktyPrawne: [],
            rodzajPliku: '',
            jednostkiInicjujace: [],
            rodzajeAktow: [],
            statusy: [],
            zalaczniki: [],
            zalacznikiPDF: [],
            zalacznikiWord: [],
            aktPrawny: '',
            showWord: false,
            showModalPanel: false,
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getJednostkiInicjujace();
        this.getRodzajeAktow();
        this.getStatusy();
        this.getAktyPrawne();

        if (this.props.mode === "add") {
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie aktu prawnego"});
            this.getIdAktuPrawnego();
            this.getDefaultValues();
        } else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja aktu prawnego"});
            this.getItem(this.props.id).then(() => this.getZalaczniki());
        }
    }

    getIdAktuPrawnego = async () => {
        const api = new DefaultApi({});
        await api.addEmptyAktPrawny().then(response => {
            this.setState({idAktuPrawnego: response.data.id})
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    }

    getAktyPrawne = async () => {
        const api = new DefaultApi({});
        await api.listOfAktPrawnyEL().then((response) => this.setState({aktyPrawne: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    }

    onIdRodzajAktuChange = (e, data) => {
        this.setState({IdRodzajAktu: data.value});
    };

    onNumerRocznikAktuChange = (e) => {
        this.setState({numerRocznikAktu: e.target.value});
    };

    onDataWydaniaChange = (e, data) => {
        this.setState({dataWydania: data.value});
    };

    onPrzedmiotAktuChange = (e) => {
        this.setState({przedmiotAktu: e.target.value});
    };

    onIdStatusAktuChange = (e, data) => {
        this.setState({IdStatusAktu: data.value});
    };

    onIdJednostkaInicjujacaChange = (e, data) => {
        this.setState({IdJednostkaInicjujaca: data.value});
    };

    onShowWordClick = () => {
        this.setState((prevState) => ({showWord: !prevState.showWord}))
    };

    getJednostkiInicjujace = async () => {
        const api = new DefaultApi({});
        await api.listOfJednostkaInicjujaca().then((response) => this.setState({
            jednostkiInicjujace: response.data,
            isLoading: false
        })).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getRodzajeAktow = async () => {
        const api = new DefaultApi({});
        api.listOfRodzajAktuPrawnego().then((response) => this.setState({
            rodzajeAktow: response.data,
            isLoading: false
        })).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getStatusy = async () => {
        const api = new DefaultApi({});
        api.listOfStatusAktuPrawnego().then((response) => this.setState({statusy: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    detailsAktPrawny = (id) => {
        this.setState({redirectDetail: true, idAktuPrawnegoLista: id});
        this.setState({showDetailsPanel: true})
    };

    closeDetails = () => {
        this.setState({showDetailsPanel: false})
    }

    convertDate(date) {
        let dataAktPrawny = new Date(date);
        let miesiacAktPrawny = ((dataAktPrawny.getMonth() + 1).toString().length === 1) ? ("0" + (dataAktPrawny.getMonth() + 1).toString()) : dataAktPrawny.getMonth() + 1;
        let dzienAktPrawny = ((dataAktPrawny.getDate()).toString().length === 1) ? ("0" + (dataAktPrawny.getDate()).toString()) : dataAktPrawny.getDate();
        return dataAktPrawny.getFullYear() + "-" + miesiacAktPrawny + "-" + dzienAktPrawny;
    }

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.loadAktPrawny(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({aktPrawny: response.data, idAktuPrawnego: id});

            this.setState({
                IdRodzajAktu: this.state.aktPrawny.fkIdRodzajAktu,
                numerRocznikAktu: this.state.aktPrawny.numerRocznikAktu,
                dataWydania: new Date(this.state.aktPrawny.dataWydania),
                przedmiotAktu: this.state.aktPrawny.przedmiotAktu,
                IdStatusAktu: this.state.aktPrawny.fkIdStatusAktu,
                IdJednostkaInicjujaca: this.state.aktPrawny.fkIdJednostkaInicjujaca,
                showWord: this.state.aktPrawny.czyPlikWordPubliczny,
                zmieniaAktyPrawne: this.state.aktPrawny.zmieniaAktyPrawne,
                uchylaAktyPrawne: this.state.aktPrawny.uchylaAktyPrawne,
            });
        } catch (error) {
            console.error(error);
        }
    };

    getDefaultValues = async () => {
        try {
            const api = new DefaultApi({});

            let response = await api.listOfRodzajAktuPrawnego().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({rodzajeAktow: response.data});
            const idDomyslnyRodzajAktu = this.state.rodzajeAktow.find((item) => item.domyslny === true).id;

            response = await api.listOfStatusAktuPrawnego().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({statusy: response.data});
            const idDomyslnyStatus = this.state.statusy.find((item) => item.domyslny === true).id;

            response = await api.listOfJednostkaInicjujaca().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({jednostkiInicjujace: response.data});
            const idDomyslnaJednostkaInicjujaca = this.state.jednostkiInicjujace.find((item) => item.domyslny === true).id;

            this.setState({
                IdRodzajAktu: idDomyslnyRodzajAktu,
                IdStatusAktu: idDomyslnyStatus,
                IdJednostkaInicjujaca: idDomyslnaJednostkaInicjujaca,
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let IdRodzajAktuError = "",
            numerRocznikAktuError = "",
            dataWydaniaError = "",
            przedmiotAktuError = "",
            IdStatusAktuError = "",
            IdJednostkaInicjujacaError = "";

        let isEmptyText = "Nie wypełniono tego pola";

        if (!this.state.IdRodzajAktu) {
            IdRodzajAktuError = isEmptyText;
        }

        if (!this.state.numerRocznikAktu) {
            numerRocznikAktuError = isEmptyText;
        }

        if (!this.state.dataWydania) {
            dataWydaniaError = isEmptyText;
        }

        if (!this.state.przedmiotAktu) {
            przedmiotAktuError = isEmptyText;
        }

        if (!this.state.IdStatusAktu) {
            IdStatusAktuError = isEmptyText;
        }

        if (!this.state.IdJednostkaInicjujaca) {
            IdJednostkaInicjujacaError = isEmptyText;
        }

        if (IdRodzajAktuError ||
            numerRocznikAktuError ||
            dataWydaniaError ||
            przedmiotAktuError ||
            IdStatusAktuError ||
            IdJednostkaInicjujacaError) {
            this.setState({
                IdRodzajAktuError,
                numerRocznikAktuError,
                dataWydaniaError,
                przedmiotAktuError,
                IdStatusAktuError,
                IdJednostkaInicjujacaError
            });
            return false;
        }

        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});

        await api.editAktPrawny(this.state.idAktuPrawnego, {
            id: '',
            fkIdRodzajAktu: this.state.IdRodzajAktu,
            numerRocznikAktu: this.state.numerRocznikAktu,
            dataWydania: this.convertDate(this.state.dataWydania),
            dataObowiazywania: this.convertDate(this.state.dataWydania),
            przedmiotAktu: this.state.przedmiotAktu,
            fkIdStatusAktu: this.state.IdStatusAktu,
            fkIdJednostkaInicjujaca: this.state.IdJednostkaInicjujaca,
            tagi: [],
            /*usunąć*/
            trescWord: '',
            czyPlikWordPubliczny: this.state.showWord,
            zalaczniki: [],
            fkIdAlertu: '',
            zmieniaAktyPrawne: this.state.zmieniaAktyPrawne,
            uchylaAktyPrawne: this.state.uchylaAktyPrawne,
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

    };

    onFormSubmit = (event) => {
        event.preventDefault();

        const isValid = this.validateForm();

        if (isValid) {
            this.saveForm().then(() => this.setState({redirectSwitch: true}));
            this.setState({nazwaAktuError: '', numerAktuError: '', dataWydaniaError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    onCancel = () => {
        if (this.state.modeName === 'Dodaj') {
            const api = new DefaultApi({});
            api.deleteAktPrawny(this.state.idAktuPrawnego).then(() => this.setState({redirectSwitch: true})).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        }
        this.setState({redirectSwitch: true});
    }

    onPDFFileChange = async (e) => {
        this.setState({rodzajPliku: ZalacznikAktuPrawnegoRodzajPlikuEnum.PDF});
        await this.onFileChange(e);
    }

    onWordFileChange = async (e) => {
        this.setState({rodzajPliku: ZalacznikAktuPrawnegoRodzajPlikuEnum.WORD});
        await this.onFileChange(e);
    }

    onZalacznikFileChange = async (e) => {
        this.setState({rodzajPliku: ZalacznikAktuPrawnegoRodzajPlikuEnum.ZALACZNIK});
        await this.onFileChange(e);
    }

    onZmienianyAktPrawnyChange = (zmieniaAktyPrawne) => {
        this.setState({zmieniaAktyPrawne});
    }

    onUchylanyAktPrawnyChange = (uchylaAktyPrawne) => {
        this.setState({uchylaAktyPrawne});
    }

    onFileChange = async (e) => {
        const api = new DefaultApi({});

        let formData = new FormData();
        let headers = new Headers();

        await this.setState({
                file: e.target.files[0],
                fileName: e.target.files[0].name,
                size: e.target.files[0].size,
                date: e.target.files[0].lastModifiedDate,
                type: e.target.files[0].type
            }
        );

        await api.addZalacznikAktuPrawnego({
            id: '',
            sciezka: '',
            nrZalacznika: '',
            dataDodania: this.state.date,
            fkIdAktyPrawne: this.state.idAktuPrawnego,
            nazwaPliku: this.state.fileName,
            uwagi: '',
            rodzajPliku: this.state.rodzajPliku,
            mime: this.state.type
        }).then((response) => {
            this.setState({idZalacznika: response.data.id});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        headers.append('Content-Type', 'multipart/form-data');
        formData.append('file', this.state.file);
        formData.append('idZalacznika', this.state.idZalacznika);

        await Axios.post('/aktyprawne/pliki/', formData, {headers: headers}).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        await this.getZalaczniki();
    };

    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteZalacznikAktuPrawnego(this.state.idZalacznika).then(() => this.getZalaczniki()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handleError = () => this.setState({confirmError: false});

    handleCancel = () => this.setState({confirmDelete: false});

    deleteZalacznik = (id) => {
        this.setState({confirmDelete: true, idZalacznika: id});
    };

    deleteZmieniaAktyPrawne = (id) => {
        this.setState(prevState => ({zmieniaAktyPrawne: prevState.zmieniaAktyPrawne.filter(aktprawny => aktprawny !== id)}));
    };

    deleteUchylaAktyPrawne = (id) => {
        this.setState(prevState => ({uchylaAktyPrawne: prevState.uchylaAktyPrawne.filter(aktprawny => aktprawny !== id)}));
    };

    downloadFile = async (e, id, filename) => {
        e.preventDefault();
        await Axios({
            method: 'get',
            url: '/aktyprawne/pliki/' + id,
            responseType: 'blob'
        }).then((response) =>
            download(response.data, filename)
        );
    };

    getZalaczniki = async () => {
        const api = new DefaultApi({});
        await api.listOfZalacznikAktuPrawnego(this.state.idAktuPrawnego).then((response) => this.setState({
            zalaczniki: response.data,
            isLoading: false
        })).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        this.setState({zalacznikiPDF: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.PDF)});
        this.setState({zalacznikiWord: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.WORD)});
        this.setState({zalaczniki: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.ZALACZNIK)});
    };

    render() {
        const {redirectSwitch} = this.state;
        const {redirectDetail} = this.state;
        const {errorAPI} = this.state;

        const jednostkiInicjujace = this.state.jednostkiInicjujace.map((jednostka) => {
            return {
                key: jednostka.id,
                text: jednostka.nazwaJednostkiInicjujacej,
                value: jednostka.id
            }
        });

        const rodzajeAktowPrawnych = this.state.rodzajeAktow.map((rodzaj) => {
            return {
                key: rodzaj.id,
                text: rodzaj.nazwaRodzajuAktu,
                value: rodzaj.id
            }
        });
        const statusy = this.state.statusy.map((status) => {
            return {
                key: status.id,
                text: status.nazwaStatusu,
                value: status.id
            }
        });

        return <ErrorBoundary>

            {redirectDetail && (
                <AktyPrawneDetail id={this.state.idAktuPrawnegoLista} show={this.state.showDetailsPanel} close={this.closeDetails}/>
            )}

            <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                     title={'Usunięcie załącznika'}
                     question={'Czy na pewno chcesz usunąć ten załącznik?'}/>

            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>
                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić dane aktu prawnego</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <label>Rodzaj aktu prawnego:</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder={'Wybierz rodzaj aktu prawnego'}
                                    fluid
                                    noResultsMessage={"Brak danych"}
                                    selection
                                    onChange={this.onIdRodzajAktuChange.bind(this)}
                                    options={rodzajeAktowPrawnych}
                                    value={this.state.IdRodzajAktu}
                                />
                                    {this.state.IdRodzajAktuError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdRodzajAktuError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <RodzajAktuModal change={this.getRodzajeAktow}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <label>Numer i rocznik:*</label>
                        <input placeholder='Wprowadź numer i rocznik aktu prawnego' autoComplete="off" type="text"
                               id="address"
                               value={this.state.numerRocznikAktu}
                               onChange={this.onNumerRocznikAktuChange}/>
                        {this.state.numerRocznikAktuError ? (
                            <Label color='red' pointing>
                                {this.state.numerRocznikAktuError}
                            </Label>
                        ) : null}
                    </Form.Field>

                    <Form.Field>
                        <label>Data wydania aktu prawnego:*</label>
                        <SemanticDatepicker onChange={this.onDataWydaniaChange} format='DD-MM-YYYY' locale="en-US"
                                            value={this.state.dataWydania}/>
                        {this.state.dataWydaniaError ? (
                            <Label color='red' pointing>
                                {this.state.dataWydaniaError}
                            </Label>
                        ) : null}
                    </Form.Field>

                    <Form.TextArea onChange={this.onPrzedmiotAktuChange} label='Przedmiot aktu prawnego:'
                                   placeholder='Wprowadź przedmiot aktu prawnego' value={this.state.przedmiotAktu}/>
                    {this.state.przedmiotAktuError ? (
                        <Label color='red' pointing>
                            {this.state.przedmiotAktuError}
                        </Label>
                    ) : null}

                    <Form.Field>

                        <Grid divided='vertically'>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Segment raised>
                                        <Label as='a' color='blue' ribbon>
                                            Plik aktu prawnego w formacie PDF:
                                        </Label>
                                        {this.state.zalacznikiPDF.length === 0 ? (
                                            <div>
                                                <br/>
                                                <Button color='blue' as="label" htmlFor="filepdf"><Icon
                                                    name='file pdf outline'/>Wczytaj plik aktu prawnego (PDF)</Button>
                                                <input type="file" accept=".pdf, application/pdf" id="filepdf" style={{display: 'none'}} onChange={this.onPDFFileChange}/></div>
                                        ) : (
                                            this.state.zalacznikiPDF.map((zalaczniki, i) => <FileList key={i}
                                                                                                      data={zalaczniki}
                                                                                                      remove={this.deleteZalacznik}
                                                                                                      load={this.downloadFile}
                                            />))}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Segment raised>
                                        <Label as='a' color='blue' ribbon>
                                            Plik aktu prawnego w formacie Word:
                                        </Label>
                                        {this.state.zalacznikiWord.length === 0 ? (
                                            <div>
                                                <br/>
                                                <Button color='blue' as="label" htmlFor="fileword"><Icon
                                                    name='file word outline'/>Wczytaj plik aktu prawnego (Word)</Button>
                                                <input type="file" accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                       id="fileword" style={{display: 'none'}} onChange={this.onWordFileChange}/></div>

                                        ) : (
                                            this.state.zalacznikiWord.map((zalaczniki, i) => <FileList key={i}
                                                                                                       data={zalaczniki}
                                                                                                       remove={this.deleteZalacznik}
                                                                                                       load={this.downloadFile}
                                            />))}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Checkbox label='Czy udostępnić plik WORD?'
                                              onChange={this.onShowWordClick}
                                              checked={this.state.showWord}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Segment placeholder>
                        <Label as='a' color='blue' ribbon>
                            Załączniki do aktu prawnego:
                        </Label>
                        {this.state.zalaczniki.length === 0 ? (
                            <Header icon>
                                <Icon name='folder open outline'/>
                                Ten akt prawny nie posiada załączników.
                            </Header>
                        ) : (
                            this.state.zalaczniki.map((zalaczniki, i) => <FileList key={i} data={zalaczniki}
                                                                                   remove={this.deleteZalacznik}
                                                                                   load={this.downloadFile}
                            />))}
                        <br/>
                        <Button color='blue' as="label" htmlFor="file" type="button"><Icon name='attach'/>Dodaj
                            załącznik</Button>
                        <input type="file" accept=".pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                               id="file" style={{display: 'none'}} onChange={this.onZalacznikFileChange}/>
                    </Segment>


                    <Form.Field>
                        <label>Zmienia akty prawne:</label>
                        {this.state.zmieniaAktyPrawne && this.state.zmieniaAktyPrawne.length > 0 &&
                        this.state.zmieniaAktyPrawne.map((zmienia, i) =>
                            <ItemList key={i}
                                      aktyPrawneTab={zmienia}
                                      id={this.state.idAktuPrawnego}
                                      remove={this.deleteZmieniaAktyPrawne}
                                      details={this.detailsAktPrawny}
                            />
                        )}
                        <AktyPrawneSearchModal idParentAktPrawny={this.state.idAktuPrawnego} aktyPrawneTab={this.state.zmieniaAktyPrawne}
                                               onAktPrawnyChange={this.onZmienianyAktPrawnyChange}/>
                    </Form.Field>

                    <Form.Field>
                        <label>Uchyla akty prawne:</label>
                        {this.state.uchylaAktyPrawne && this.state.uchylaAktyPrawne.length > 0 &&
                        this.state.uchylaAktyPrawne.map((uchyla, i) =>
                            <ItemList key={i}
                                      aktyPrawneTab={uchyla}
                                      id={this.state.idAktuPrawnego}
                                      remove={this.deleteUchylaAktyPrawne}
                                      details={this.detailsAktPrawny}
                            />
                        )}
                        <AktyPrawneSearchModal idParentAktPrawny={this.state.idAktuPrawnego} aktyPrawneTab={this.state.uchylaAktyPrawne}
                                               onAktPrawnyChange={this.onUchylanyAktPrawnyChange}/>
                    </Form.Field>


                    <Form.Field>
                        <label>Status:*</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder='Wybierz status'
                                    fluid
                                    selection
                                    onChange={this.onIdStatusAktuChange}
                                    options={statusy}
                                    value={this.state.IdStatusAktu}
                                />
                                    {this.state.IdStatusAktuError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdStatusAktuError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <StatusAktuPrawnegoModal change={this.getStatusy}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <label>Jednostka inicjująca:*</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder='Wybierz jednostkę inicjującą'
                                    fluid
                                    selection
                                    onChange={this.onIdJednostkaInicjujacaChange}
                                    options={jednostkiInicjujace}
                                    value={this.state.IdJednostkaInicjujaca}
                                />
                                    {this.state.IdJednostkaInicjujacaError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdJednostkaInicjujacaError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <JednostkaInicjujacaModal change={this.getJednostkiInicjujace}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <label>Dodaj tag:*</label>
                        <Icon name='add square'/>
                    </Form.Field>

                    <Form.Field>
                        <label>Utwórz alert:*</label>
                        <Icon name='add square'/>
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    <Button basic onClick={this.onCancel}>Anuluj</Button>
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/aktyprawne'}/>
            )}
        </ErrorBoundary>
    }
}

import React, {Component} from "react";
import {Button, Header, Modal, Input} from "semantic-ui-react";
import {DefaultApi} from "../api";

class TableRow extends Component {

    render() {
        return <tr>
            <td>{this.props.data.numerRocznikAktu}</td>
            <td>{this.props.data.przedmiotAktu}</td>
            <td>{this.props.data.dataWydania}</td>
            <td>{this.props.data.nazwaStatusuAktu}</td>
            <td><Button size='mini' color='blue' onClick={() => this.props.select(this.props.data.id)}>Wybierz</Button>
            </td>
        </tr>
    }
}

export default class AktyPrawneSearchModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            aktyPrawne: [],
            slowaKluczowe: ''
        };
    }

    openModal = () => {
        this.setState({open: true});
    };

    closeModal = () => {
        this.setState({open: false});
    };

    onWyszukajChange = async (e, data) => {
        await this.setState({slowaKluczowe: data.value});
        const api = new DefaultApi({});
        await api.wyszukajAktPrawny(this.state.slowaKluczowe).then((response) => this.setState({
            aktyPrawne: response.data.list,
            isLoading: false
        }));

        /* Remove AktPrawny with parent id */
        if(this.state.aktyPrawne.length > 0) {
            this.setState({aktyPrawne: this.state.aktyPrawne.filter((aktprawny) => aktprawny.id !== this.props.idParentAktPrawny)});
        }
    }

    onSelectAktPrawny = async (idAktPrawny) => {
        var zmieniaAktyPrawne = [];

        if (this.props.aktyPrawneTab && this.props.aktyPrawneTab.length > 0) {
            zmieniaAktyPrawne = this.props.aktyPrawneTab;

            /* Check idAktu exists in aktyPrawneTab */
            if (!this.props.aktyPrawneTab.find((id) => id === idAktPrawny)) {
                zmieniaAktyPrawne.push(idAktPrawny);
            }
        } else {
            zmieniaAktyPrawne.push(idAktPrawny);
        }

        this.props.onAktPrawnyChange(zmieniaAktyPrawne);
        this.closeModal();
    }

    render() {
        return <Modal
            onClose={this.closeModal}
            onOpen={this.openModal}
            open={this.state.open}
            trigger={<Button size='mini' color='blue' type='button'>Wyszukaj akt prawny...</Button>}
        >
            <Modal.Header>Wyszukaj akt prawny</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header>Wskaż zmieniający akt prawny ...</Header>
                    <Input placeholder='Wprowadź słowo kluczowe' autoComplete="off" type="text"
                           id="searchUmowa" className="wyszukaj"
                           value={this.state.slowaKluczowe}
                           onChange={this.onWyszukajChange}/>
                    <table className="ui table">
                        <thead>
                        <tr>
                            <th>Numer i rocznik</th>
                            <th>Nazwa aktu prawnego</th>
                            <th>Data wydania</th>
                            <th>Status</th>
                            <th>Opcje</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.aktyPrawne.length > 0 ? (
                        this.state.aktyPrawne.map((aktyprawne, i) => <TableRow key={i} data={aktyprawne}
                                                                                select={this.onSelectAktPrawny}
                        />)): <tr><td><strong>Nie znaleziono dopasowania</strong></td></tr>}
                        </tbody>
                    </table>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='blue' onClick={this.closeModal}>
                    Zamknij
                </Button>
            </Modal.Actions>
        </Modal>
    }
}

import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Form, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class StatusAktuPrawnegoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nazwaStatusu: '',
            nazwaStatusuError: '',
            domyslny: '',
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        if (this.props.mode === "add")
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie statusu aktu prawnego"});
        else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja statusu aktu prawnego"});
            this.getItem(this.props.id);
        }
    }

    onNazwaStatusuChange = (event) => {
        this.setState({nazwaStatusu: event.target.value});
    };

    handleError = () => this.setState({confirmError: false});

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfStatusAktuPrawnego().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const status = response.data.find((a) => a.id === id);
            this.setState({
                id: status.valueOf().id,
                nazwaStatusu: status.valueOf().nazwaStatusu,
                domyslny: status.valueOf().domyslny
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let nazwaStatusuError = "";
        let isEmptyText = "Nie wypełniono tego pola";

        if (!this.state.nazwaStatusu) {
            nazwaStatusuError = isEmptyText;
        }

        if (nazwaStatusuError) {
            this.setState({nazwaStatusuError});
            return false;
        }
        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {
            await api.addStatusAktuPrawnego({
                id: '',
                nazwaStatusu: this.state.nazwaStatusu,
                domyslny: false,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {
            await api.editStatusAktuPrawnego(this.props.id, {
                id: '',
                nazwaStatusu: this.state.nazwaStatusu,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if(this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }

            this.setState({nazwaStatusuError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal}=this.props;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić dane statusu aktu prawnego</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <label>Nazwa statusu:*</label>
                        <input placeholder='Podaj nazwę statusu' autoComplete="off" type="text" id="address"
                               value={this.state.nazwaStatusu}
                               onChange={this.onNazwaStatusuChange}/>
                        {this.state.nazwaStatusuError ? (
                            <Label color='red' pointing>
                                {this.state.nazwaStatusuError}
                            </Label>
                        ) : null}
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    {modal ?
                        <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/aktyprawne/status'}><Button basic>Anuluj</Button></Link>
                    }
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/aktyprawne/status'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
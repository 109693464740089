import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Dropdown, Form, Grid, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class WykonawcaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wykonawcy: [],
            rodzajePodmiotow: [],
            idWykonawcy: '',
            idRodzajuPodmiotu: '',
            idRodzajuPodmiotuError: '',
            nazwaWykonawcy: '',
            nazwaWykonawcyError: '',
            ulica: '',
            ulicaError: '',
            miasto: '',
            miastoError: '',
            kod: '',
            kodError: '',
            kraj: '',
            krajError: '',
            email: '',
            emailError: '',
            telefon: '',
            telefonError: '',
            peselNipRegon: '',
            peselNipRegonError: '',
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getRodzajePodmiotow();

        if (this.props.mode === "add") {
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie wykonawcy"});
            this.getDefaultValues();
        } else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja wykonawcy"});
            this.getItem(this.props.id);
        }
    }

    onNazwaChange = (event) => {
        this.setState({nazwaWykonawcy: event.target.value});
    };

    onIdRodzajPodmiotuChange = (event, data) => {
        event.preventDefault();
        this.setState({idRodzajuPodmiotu: data.value});
    };

    onUlicaChange = (event) => {
        this.setState({ulica: event.target.value});
    };

    onMiastoChange = (event) => {
        this.setState({miasto: event.target.value});
    };

    onKodChange = (event) => {
        this.setState({kod: event.target.value});
    };

    onKrajChange = (event) => {
        this.setState({kraj: event.target.value});
    };

    onEmailChange = (event) => {
        this.setState({email: event.target.value});
    };

    onTelefonChange = (event) => {
        this.setState({telefon: event.target.value});
    };

    onPeselNipRegonChange = (event) => {
        this.setState({peselNipRegon: event.target.value});
    };

    getRodzajePodmiotow = async () => {
        const api = new DefaultApi({});
        api.listOfRodzajPodmiotu().then((response) => this.setState({rodzajePodmiotow: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfWykonawca(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const wykonawca = response.data.find((a) => a.id === id);
            this.setState({
                nazwaWykonawcy: wykonawca.nazwaWykonawcy,
                idRodzajuPodmiotu: wykonawca.fkIdRodzajuPodmiotu,
                ulica: wykonawca.ulica,
                miasto: wykonawca.miasto,
                kod: wykonawca.kod,
                kraj: wykonawca.kraj,
                email: wykonawca.email,
                telefon: wykonawca.telefon,
                peselNipRegon: wykonawca.peselNipRegon,
            });
        } catch (error) {
            console.error(error);
        }
    };

    getDefaultValues = async () => {
        try {
            const api = new DefaultApi({});

            let response = await api.listOfRodzajPodmiotu().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({rodzajePodmiotow: response.data});
            const idDomyslnyRodzajPodmiotu = this.state.rodzajePodmiotow.find((item) => item.domyslny === true).id;

            this.setState({
                idRodzajuPodmiotu: idDomyslnyRodzajPodmiotu,
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let nazwaError, emailError, kodError, idRodzajuPodmiotuError = "";
        let isEmptyText = "Nie wypełniono tego pola";
        let isWrongEmail = "Podany zapis adresu e-mail nie jest poprawny";
        let isWrongPostcode = "Podany zapis kodu pocztowego nie jest poprawny";
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const postcodePattern = /^[0-9]{2}(-[0-9]{3})?$/;

        if (!this.state.nazwaWykonawcy) {
            nazwaError = isEmptyText;
        }

        if (!this.state.idRodzajuPodmiotu) {
            idRodzajuPodmiotuError = isEmptyText;
        }

        if (this.state.email && !emailPattern.test(this.state.email)) {
            emailError = isWrongEmail;
        }

        if (this.state.kod && !postcodePattern.test(this.state.kod)) {
            kodError = isWrongPostcode;
        }

        if (nazwaError || emailError || kodError || idRodzajuPodmiotuError) {
            this.setState({nazwaError, emailError, kodError, idRodzajuPodmiotuError});
            return false;
        }
        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {

            await api.addWykonawca({
                id: '',
                nazwaWykonawcy: this.state.nazwaWykonawcy,
                fkIdRodzajuPodmiotu: this.state.idRodzajuPodmiotu,
                ulica: this.state.ulica,
                miasto: this.state.miasto,
                kod: this.state.kod,
                kraj: this.state.kraj,
                email: this.state.email,
                telefon: this.state.telefon,
                peselNipRegon: this.state.peselNipRegon,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {

            await api.editWykonawca(this.props.id, {
                id: '',
                nazwaWykonawcy: this.state.nazwaWykonawcy,
                fkIdRodzajuPodmiotu: this.state.idRodzajuPodmiotu,
                ulica: this.state.ulica,
                miasto: this.state.miasto,
                kod: this.state.kod,
                kraj: this.state.kraj,
                email: this.state.email,
                telefon: this.state.telefon,
                peselNipRegon: this.state.peselNipRegon,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if(this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }
            this.setState({nazwaRodzajuAktuError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal}=this.props;

        const rodzajePodmiotow = this.state.rodzajePodmiotow.map((status) => {
            return {
                key: status.id,
                text: status.nazwaRodzajuPodmiotu,
                value: status.id
            }
        });
        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić dane wykonawcy</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={7}>
                                    <label>Nazwa wykonawcy:*</label>
                                    <input placeholder='Podaj nazwę wykonawcy' autoComplete="off" type="text" id="rodzaj"
                                           value={this.state.nazwaWykonawcy}
                                           onChange={this.onNazwaChange}/>
                                    {this.state.nazwaError ? (
                                        <Label color='red' pointing>
                                            {this.state.nazwaError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={7}>
                                    <label>Rodzaj podmiotu:*</label>
                                    <Dropdown
                                        placeholder={'Wybierz rodzaj podmiotu'}
                                        fluid
                                        noResultsMessage={"Brak danych"}
                                        selection
                                        onChange={this.onIdRodzajPodmiotuChange}
                                        options={rodzajePodmiotow}
                                        value={this.state.idRodzajuPodmiotu}
                                    />
                                    {this.state.idRodzajuPodmiotuError ? (
                                        <Label color='red' pointing>
                                            {this.state.idRodzajuPodmiotuError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <label>Ulica:</label>
                                    <input placeholder='Podaj nazwę ulicy' autoComplete="off" type="text" id="rodzaj"
                                           value={this.state.ulica}
                                           onChange={this.onUlicaChange}/>
                                    {this.state.ulicaError ? (
                                        <Label color='red' pointing>
                                            {this.state.ulicaError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <label>Kod pocztowy:</label>
                                    <input placeholder='Podaj kod pocztowy' autoComplete="off" type="text" id="symbol"
                                           value={this.state.kod}
                                           onChange={this.onKodChange}/>
                                    {this.state.kodError ? (
                                        <Label color='red' pointing>
                                            {this.state.kodError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <label>Miasto:</label>
                                    <input placeholder='Podaj nazwę miasta' autoComplete="off" type="text" id="symbol"
                                           value={this.state.miasto}
                                           onChange={this.onMiastoChange}/>
                                    {this.state.miastoError ? (
                                        <Label color='red' pointing>
                                            {this.state.miastoError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <label>Kraj:</label>
                                    <input placeholder='Podaj nazwę kraju' autoComplete="off" type="text" id="symbol"
                                           value={this.state.kraj}
                                           onChange={this.onKrajChange}/>
                                    {this.state.krajError ? (
                                        <Label color='red' pointing>
                                            {this.state.krajError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <label>Adres e-mail:</label>
                                    <input placeholder='Podaj adres e-mail' autoComplete="off" type="text" id="rodzaj"
                                           value={this.state.email}
                                           onChange={this.onEmailChange}/>
                                    {this.state.emailError ? (
                                        <Label color='red' pointing>
                                            {this.state.emailError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <label>Numer telefonu:</label>
                                    <input placeholder='Podaj numer telefonu' autoComplete="off" type="text" id="symbol"
                                           value={this.state.telefon}
                                           onChange={this.onTelefonChange}/>
                                    {this.state.telefonError ? (
                                        <Label color='red' pointing>
                                            {this.state.telefonError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <label>PESEL/NIP/REGON:</label>
                                    <input placeholder='Podaj numer PESEL/NIP/REGON' autoComplete="off" type="text" id="symbol"
                                           value={this.state.peselNipRegon}
                                           onChange={this.onPeselNipRegonChange}/>
                                    {this.state.peselNipRegonError ? (
                                        <Label color='red' pointing>
                                            {this.state.peselNipRegonError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    {modal ?
                        <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/umowy/wykonawca'}><Button basic>Anuluj</Button></Link>
                    }
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/umowy/wykonawca'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
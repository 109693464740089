import React, {Component} from 'react';
import OsobaForm from "./OsobaForm";


export default class OsobaEdit extends Component {

    render() {
        return <div>
            <OsobaForm mode={'edit'} id={this.props.match.params.id}/>
        </div>

    }
}

import React, {Component} from 'react';
import {Message, Button, Icon, Header, Segment, Popup, Radio} from 'semantic-ui-react';
import {Link, Redirect} from 'react-router-dom';
import ErrorBoundary from '../../common/ErrorBoundary';
import Error from '../../common/Error';
import Confirm from '../../common/Confirm';
import Spinner from '../../common/Spinner';
import {DefaultApi} from "../../api";

class TableRow extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onDomyslnyChange(this.props.data.id);
    }

    render() {
        return <tr>
            <td width="500px">{this.props.data.nazwaStatusu}</td>
            <td><Radio onChange={this.handleChange} checked={this.props.radiovalue === this.props.data.id} value={this.props.data.id} name='radioGroup'/>
            </td>
            <td width="120px">
                <Popup
                    trigger={<Icon link name='edit' onClick={() => this.props.edit(this.props.data.id)}/>}
                    content='Edycja wybranego statusu aktu prawnego'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                    content='Usunięcie wybranego statusu aktu prawnego'
                    size='tiny'
                    inverted
                />
            </td>
        </tr>
    }
}


export default class StatusAktuPrawnegoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idStatusu: '',
            isLoading: true,
            status: '',
            statusy: [],
            domyslny: false,
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            radiovalue: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getStatusy();
    }

    getStatusy = async () => {
        const api = new DefaultApi({});
        api.listOfStatusAktuPrawnego().then((response) => {
                this.setState({statusy: response.data, isLoading: false});
                const foundStatus = this.state.statusy.find(status => status.domyslny === true);
                if (typeof foundStatus !== 'undefined')
                    this.setState({radiovalue: foundStatus.id});
            }
        ).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

    };

    listStatusy = () => {
        return (
            <Segment raised className={`panel`}>

                <Header as='h3'>
                    <Icon name='configure'/>
                    <Header.Content>
                        Lista statusów aktu prawnego
                        <Header.Subheader>Zarządzanie statusami aktu prawnego</Header.Subheader>
                    </Header.Content>
                </Header>

                <Link to={'/aktyprawne/status/dodaj'}><Button primary>Dodaj status aktu prawnego</Button></Link>
                <table className="ui table">
                    <thead>
                    <tr>
                        <th>Nazwa statusu aktu prawnego</th>
                        <th>Domyślny</th>
                        <th>Opcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.statusy
                        .sort((a, b) => {
                            if (a.nazwaStatusu != null)
                                return a.nazwaStatusu.localeCompare(b.nazwaStatusu)
                            else if (b.nazwaStatusu != null)
                                return b.nazwaStatusu.localeCompare(a.nazwaStatusu)
                            return null
                        })
                        .map((status, i) => <TableRow key={i} data={status} radiovalue={this.state.radiovalue}
                                                      onDomyslnyChange={this.onDomyslnyChange}
                                                      remove={this.deleteStatus}
                                                      edit={this.editStatus}
                            />
                        )}
                    </tbody>
                </table>
                <Segment>
                    <Header as='h4' color='grey'>Legenda</Header>
                    <Icon link name='edit'/> - edycja wybranego statusu aktu prawnego<br/>
                    <Icon link name='trash'/> - usunięcie wybranego statusu aktu prawnego
                </Segment>
            </Segment>
        )
    };

    onDomyslnyChange = async (id) => {

        this.setState({radiovalue: id});

        const api = new DefaultApi({});
        await api.listOfStatusAktuPrawnego().then((response) => this.setState({status: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        /* ustawiamy flagę FALSE dla dokumentu(ów), który dotychczas miał ustawioną TRUE */
        const foundStatusTrue = this.state.status.filter(status => status.domyslny === true);

        for (const status of foundStatusTrue) {
            if (status.id !== id) {
                await api.editStatusAktuPrawnego(status.id, {
                    id: '',
                    nazwaStatusu: status.nazwaStatusu,
                    domyslny: false,
                }).catch((error) => {
                    Promise.resolve(error.response.data.msg).then((response) => {
                        this.setState({errorAPI: response, confirmError: true})
                    });
                });
            }
        }

        /* ustawiamy flagę TRUE dla dokumentu zaznaczonego */
        const foundStatusId = this.state.status.filter(status => status.id === id);
        await api.editStatusAktuPrawnego(id, {
            id: '',
            nazwaStatusu: foundStatusId[0].nazwaStatusu,
            domyslny: true
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    showStatus = () => {
        if (this.state.statusy.length > 0) {
            return this.listStatusy()
        } else {
            if (this.state.isLoading) {
                return <Spinner/>
            } else {
                return <div>
                    <Message
                        color='red'
                        header='Nie ma zdefiniowanych statusów aktu prawnego'
                        list={[
                            'Musisz zdefiniować status. W tym celu kliknij przycisk "Dodaj status aktu prawnego"',
                        ]}
                    />
                    <Link to={'/aktyprawne/status/dodaj'}><Button primary>Dodaj status aktu prawnego</Button></Link>
                </div>
            }
        }
    };


    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteStatusAktuPrawnego(this.state.idStatusu)
            .then((response) => {
                if (response.ok)
                    this.getStatusy().error(console.error);
            })
            .catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });

        this.setState({confirmDelete: false});
    };

    handleCancel = () => this.setState({confirmDelete: false});

    handleError = () => this.setState({confirmError: false});

    deleteStatus = (id) => {
        this.setState({confirmDelete: true, idStatusu: id});
    };

    editStatus = (id) => {
        this.setState({redirectEdit: true, idStatusu: id})
    };


    render() {
        const {redirectEdit} = this.state;
        const {errorAPI} = this.state;


        return <ErrorBoundary>
            {this.showStatus()}

            <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                     title={'Usunięcie statusu aktu prawnego'}
                     question={'Czy na pewno chcesz usunąć ten status aktu prawnego?'}/>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectEdit && (
                <Redirect push to={'/aktyprawne/status/edytuj/' + this.state.idStatusu}/>
            )}
        </ErrorBoundary>
    }
}

import React, {Component} from "react";
import {Icon, List, Popup} from "semantic-ui-react";
import {Link} from 'react-router-dom';

export default class AneksList extends Component {

    render() {
        const address = '/umowy/edytuj/'+this.props.data.id;
        return <List>
            <List.Item>
                <List.Content>
                    <Icon name='file alternate outline'/>
                    <Link className="link-button" to={address}>{this.props.data.numerUmowy}</Link>
                    {this.props.remove && <Popup
                        trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                        content='Usunięcie wybranego aneksu'
                        size='tiny'
                        inverted
                    />}
                </List.Content>
            </List.Item>
        </List>
    }
}

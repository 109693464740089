import React, {Component} from 'react';
import {Button, Header, Icon, Segment} from 'semantic-ui-react';
import {Link} from "react-router-dom";

export default class UstawieniaMain extends Component {

    render() {
        return <Segment raised className={`panel`}>

            <Header as='h3'>
                <Icon name='configure'/>
                <Header.Content>
                    Ustawienia aplikacji
                    <Header.Subheader>Zarządzanie ustawieniami</Header.Subheader>
                </Header.Content>
            </Header>
            <Header as='h4'>Zarządzanie ustawieniami aktów prawnych</Header>
            <Link to={'/aktyprawne/rodzajaktu'}><Button primary>Rodzaje aktów prawnych</Button></Link>
            <Link to={'/aktyprawne/jednostkainicjujaca'}><Button primary>Jednostki inicjujące</Button></Link>
            <Link to={'/aktyprawne/status'}><Button primary>Statusy</Button></Link>

            <Header as='h4'>Zarządzanie ustawieniami umów</Header>
            <Link to={'/umowy/osoba'}><Button primary>Osoby</Button></Link>
            <Link to={'/umowy/podmiot'}><Button primary>Podmioty</Button></Link>
            <Link to={'/umowy/wykonawca'}><Button primary>Wykonawcy</Button></Link>
            <Link to={'/umowy/rodzajumowy'}><Button primary>Rodzaje umów</Button></Link>
            <Link to={'/umowy/jednostkaodpowiedzialna'}><Button primary>Jednostki odpowiedzialne</Button></Link>
            <Link to={'/umowy/status'}><Button primary>Statusy</Button></Link>
            <Link to={'/umowy/waluta'}><Button primary>Waluty</Button></Link>
        </Segment>
    }
}
import React, {Component} from 'react';
import {Button, Grid, Form, Header, Icon, Segment, Label, Dropdown} from 'semantic-ui-react';
import ErrorBoundary from '../common/ErrorBoundary';
import {Redirect} from 'react-router-dom';
import {DefaultApi} from "../api/index";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {ZalacznikUmowyRodzajPlikuEnum} from "../api/ts/model";
import Confirm from "../common/Confirm";
import Axios from '../common/Axios';
import FileList from "../common/FileList";
import AneksList from "../common/AneksList";
import download from "downloadjs";
import Error from '../common/Error';
import RodzajUmowyModal from "./ustawienia/RodzajUmowyModal";
import WykonawcaModal from "./ustawienia/WykonawcaModal";
import RodzajWalutyModal from "./ustawienia/RodzajWalutyModal";
import JednostkaOdpowiedzialnaModal from "./ustawienia/JednostkaOdpowiedzialnaModal";
import OsobaModal from "./ustawienia/OsobaModal";
import StatusUmowyModal from "./ustawienia/StatusUmowyModal";

export default class UmowyForm extends Component {

    constructor(props) {
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
        this.state = {
            idUmowy: '',
            dataZawarcia: '',
            numerUmowy: '',
            przedmiotUmowy: '',
            dataPoczatkowa: '',
            dataKoncowa: '',
            brutto: '',
            netto: '',
            aneksIdUmowy: '',
            aneksyTab: '',
            trescWord: '',
            IdOsoby: '',
            IdJednostki: '',
            IdWykonawcy: '',
            IdAlertu: '',
            IdStatusUmowy: '',
            IdRodzajuUmowy: '',
            IdWaluty: '',
            idZalacznika: '',
            czyAneks: false,

            dataZawarciaError: '',
            numerUmowyError: '',
            przedmiotUmowyError: '',
            dataPoczatkowaError: '',
            dataKoncowaError: '',
            bruttoError: '',
            nettoError: '',
            aneksIdUmowyError: '',
            aneksyTabError: '',
            trescWordError: '',
            IdOsobyError: '',
            IdJednostkiError: '',
            IdWykonawcyError: '',
            IdAlertuError: '',
            IdStatusUmowyError: '',
            IdRodzajuUmowyError: '',
            IdWalutyError: '',

            redirectSwitch: false,
            fileName: '',
            modeName: '',
            modeTitle: '',
            disabledAneks: false,
            idAneksu: '',
            IdJednostkiCzasu: '1',
            wartosc: '',
            aneksy: [],
            osoby: [],
            jednostki: [],
            wykonawcy: [],
            statusy: [],
            waluty: [],
            rodzajeumowy: [],
            zalacznikiPDF: [],
            zalacznikiWord: [],
            zalaczniki: [],
            errorAPI: '',
            confirmError: false
        };

    }

    componentDidMount() {

        this.getOsoby();
        this.getJednostki();
        this.getStatusy();
        this.getRodzajeUmowy();
        this.getWaluty();
        this.getWykonawcy();


        if (this.props.mode === "add") {
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie umowy", disabledAneks: true});
            this.getIdUmowy();
            this.getDefaultValues();
        } else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja umowy"});
            this.getItem(this.props.id).then(() => {
                    this.getZalaczniki();
                    this.getAneksy();
                }
            );
        }
    }

    getIdUmowy = async () => {
        const api = new DefaultApi({});
        await api.addEmptyUmowa().then(response => {
            this.setState({idUmowy: response.data.id});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    }


    onNumerUmowyChange = (e) => {
        e.preventDefault();
        this.setState({numerUmowy: e.target.value});
    };

    onPrzedmiotUmowyChange = (e) => {
        e.preventDefault();
        this.setState({przedmiotUmowy: e.target.value});
    };

    onDataZawarciaChange = (e, data) => {
        this.setState({dataZawarcia: data.value});
    };

    onNettoChange = (e) => {
        e.preventDefault();
        this.setState({netto: e.target.value});
    };

    onBruttoChange = (e) => {
        e.preventDefault();
        this.setState({brutto: e.target.value});
    };

    onDataPoczatkowaChange = (e, data) => {
        this.setState({dataPoczatkowa: data.value});
    };

    onDataKoncowaChange = (e, data) => {
        this.setState({dataKoncowa: data.value});

    };

    onIdJednostkiChange = (e, data) => {
        e.preventDefault();
        this.setState({IdJednostki: data.value});
    };

    onIdOsobyChange = (e, data) => {
        e.preventDefault();
        this.setState({IdOsoby: data.value});
    };

    onIdStatusUmowyChange = (e, data) => {
        e.preventDefault();
        this.setState({IdStatusUmowy: data.value});
    };

    onIdRodzajuUmowyChange = (e, data) => {
        e.preventDefault();
        this.setState({IdRodzajuUmowy: data.value});
    };

    onIdWykonawcyChange = (e, data) => {
        e.preventDefault();
        this.setState({IdWykonawcy: data.value});
    };

    onIdWalutyChange = (e, data) => {
        e.preventDefault();
        this.setState({IdWaluty: data.value});
    };

    onWartoscChange = async (e) => {
        e.preventDefault();
        await this.setState({wartosc: e.target.value});
        this.calculateDate();
    };

    onJednostkaCzasuChange = async (e, data) => {
        e.preventDefault();
        await this.setState({IdJednostkiCzasu: data.value});
        this.calculateDate();
    };

    calculateDate = () => {
        var mnoznik;

        switch (this.state.IdJednostkiCzasu) {
            case 2:
                mnoznik = 7;
                break;
            case 3:
                mnoznik = 31;
                break;
            default:
                mnoznik = 1;
                break;
        }
        if (this.state.wartosc && this.state.IdJednostkiCzasu) {
            this.setState({dataKoncowa: new Date(this.state.dataPoczatkowa.valueOf() + (this.state.wartosc * mnoznik * 24 * 3600 * 1000))});
        }
    }

    getZalaczniki = async () => {
        const api = new DefaultApi({});
        await api.listOfZalacznikUmowy(this.state.idUmowy).then((response) => this.setState({zalaczniki: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        this.setState({zalacznikiPDF: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikUmowyRodzajPlikuEnum.PDF)});
        this.setState({zalacznikiWord: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikUmowyRodzajPlikuEnum.WORD)});
        this.setState({zalaczniki: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikUmowyRodzajPlikuEnum.ZALACZNIK)});
    };

    getOsoby = async () => {
        const api = new DefaultApi({});
        api.listOfOsoba().then((response) => this.setState({osoby: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getJednostki = async () => {
        const api = new DefaultApi({});
        api.listOfJednostki().then((response) => this.setState({jednostki: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getWykonawcy = async () => {
        const api = new DefaultApi({});
        api.listOfWykonawca().then((response) => this.setState({wykonawcy: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getStatusy = async () => {
        const api = new DefaultApi({});
        api.listOfStatusUmowy().then((response) => this.setState({statusy: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getRodzajeUmowy = async () => {
        const api = new DefaultApi({});
        api.listOfRodzajUmowy().then((response) => this.setState({rodzajeumowy: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getWaluty = async () => {
        const api = new DefaultApi({});
        api.listOfWaluta().then((response) => this.setState({waluty: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getAneksy = async () => {
        const api = new DefaultApi({});
        await api.listOfAneksUmowaEL(this.state.idUmowy).then((response) => this.setState({aneksy: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    }


    convertDate(date) {
        var dataUmowa = new Date(date);
        var miesiacUmowa = ((dataUmowa.getMonth() + 1).toString().length === 1) ? ("0" + (dataUmowa.getMonth() + 1).toString()) : dataUmowa.getMonth() + 1;
        var dzienUmowa = ((dataUmowa.getDate()).toString().length === 1) ? ("0" + (dataUmowa.getDate()).toString()) : dataUmowa.getDate();
        return dataUmowa.getFullYear() + "-" + miesiacUmowa + "-" + dzienUmowa;
    }

    convertWartosc(wartosc) {
        const numberPattern = /^[0-9]+(.0)?$/;
        let wartoscOut;

        if (numberPattern.test(wartosc)) {
            wartoscOut = wartosc + ",00";
        } else {
            wartoscOut = wartosc;
        }

        wartoscOut = wartoscOut.toString().replace(".", ",")
        return wartoscOut;
    }

    getItem = async (id) => {
        const api = new DefaultApi({});


        await api.loadUmowa(id).then((response) => {
            this.setState({umowa: response.data, idUmowy: id});

            if (this.state.umowa.czyAneks) {
                this.setState({modeTitle: "Edycja aneksu"});
            }

            this.setState({
                dataZawarcia: new Date(this.state.umowa.dataZawarcia),
                numerUmowy: this.state.umowa.numerUmowy,
                przedmiotUmowy: this.state.umowa.przedmiotUmowy,
                dataPoczatkowa: new Date(this.state.umowa.dataPoczatkowa),
                dataKoncowa: new Date(this.state.umowa.dataKoncowa),
                czyAneks: this.state.umowa.czyAneks,
                brutto: this.convertWartosc(this.state.umowa.brutto),
                netto: this.convertWartosc(this.state.umowa.netto),
                IdOsoby: this.state.umowa.fkIdOsoby,
                IdJednostki: this.state.umowa.fkIdJednostki,
                IdWykonawcy: this.state.umowa.fkIdWykonawcy,
                IdAlertu: this.state.umowa.fkIdAlertu,
                IdStatusUmowy: this.state.umowa.fkIdStatusUmowy,
                IdRodzajuUmowy: this.state.umowa.fkIdRodzajuUmowy,
                IdWaluty: this.state.umowa.fkIdWaluty,
                IdJednostkiCzasu: 1
            });
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getDefaultValues = async () => {
        try {
            const api = new DefaultApi({});

            let response = await api.listOfRodzajUmowy().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({rodzajeumowy: response.data});
            const idDomyslnyRodzajUmowy = this.state.rodzajeumowy.find((item) => item.domyslny === true).id;

            response = await api.listOfStatusUmowy().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({statusy: response.data});
            const idDomyslnyStatus = this.state.statusy.find((item) => item.domyslny === true).id;

            response = await api.listOfJednostki().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({jednostki: response.data});
            const idDomyslnaJednostka = this.state.jednostki.find((item) => item.domyslny === true).id;

            response = await api.listOfWaluta().catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            this.setState({waluty: response.data});
            const idDomyslnaWaluta = this.state.waluty.find((item) => item.domyslny === true).id;

            this.setState({
                IdRodzajuUmowy: idDomyslnyRodzajUmowy,
                IdStatusUmowy: idDomyslnyStatus,
                IdJednostki: idDomyslnaJednostka,
                IdWaluty: idDomyslnaWaluta
            });

        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let dataZawarciaError = "",
            numerUmowyError = "",
            przedmiotUmowyError = "",
            dataPoczatkowaError = "",
            dataKoncowaError = "",
            nettoError = "",
            bruttoError = "",
            IdOsobyError = "",
            IdJednostkiError = "",
            IdWykonawcyError = "",
            IdStatusUmowyError = "",
            IdRodzajuUmowyError = "",
            IdWalutyError = "";

        let isEmptyText = "Nie wypełniono tego pola";
        let isWrongDate = "Data końcowa nie może być wcześniejsza od daty początkowej";
        let isWrongNumber = "Podana wartość umowy nie jest poprawna. Poprawnymi wartościami są przykładowo: 10,99 lub 1450";
        const numberPattern = /^[0-9]{1,}(,[0-9]{2})?$/;


        if (!this.state.dataZawarcia) {
            dataZawarciaError = isEmptyText;
        }

        if (!this.state.numerUmowy) {
            numerUmowyError = isEmptyText;
        }

        if (!this.state.przedmiotUmowy) {
            przedmiotUmowyError = isEmptyText;
        }

        if (!this.state.dataPoczatkowa) {
            dataPoczatkowaError = isEmptyText;
        }

        if (!this.state.dataKoncowa) {
            dataKoncowaError = isEmptyText;
        }

        if (this.state.dataPoczatkowa > this.state.dataKoncowa) {
            dataKoncowaError = isWrongDate;
        }

        if (!this.state.brutto) {
            bruttoError = isEmptyText;
        } else if (!numberPattern.test(this.state.brutto)) {
            bruttoError = isWrongNumber;
        }

        if (this.state.netto && !numberPattern.test(this.state.netto)) {
            nettoError = isWrongNumber;
        }

        if (!this.state.IdOsoby) {
            IdOsobyError = isEmptyText;
        }
        if (!this.state.IdJednostki) {
            IdJednostkiError = isEmptyText;
        }
        if (!this.state.IdWykonawcy) {
            IdWykonawcyError = isEmptyText;
        }
        if (!this.state.IdStatusUmowy) {
            IdStatusUmowyError = isEmptyText;
        }
        if (!this.state.IdRodzajuUmowy) {
            IdRodzajuUmowyError = isEmptyText;
        }
        if (!this.state.IdWaluty) {
            IdWalutyError = isEmptyText;
        }

        if (dataZawarciaError ||
            numerUmowyError ||
            przedmiotUmowyError ||
            dataPoczatkowaError ||
            dataKoncowaError ||
            nettoError ||
            bruttoError ||
            IdOsobyError ||
            IdJednostkiError ||
            IdWykonawcyError ||
            IdStatusUmowyError ||
            IdRodzajuUmowyError ||
            IdWalutyError) {
            this.setState({
                dataZawarciaError,
                numerUmowyError,
                przedmiotUmowyError,
                dataPoczatkowaError,
                dataKoncowaError,
                nettoError,
                bruttoError,
                IdOsobyError,
                IdJednostkiError,
                IdWykonawcyError,
                IdStatusUmowyError,
                IdRodzajuUmowyError,
                IdWalutyError
            });
            return false;
        }

        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        let aneksyId = []

        if (this.state.umowa && !this.state.umowa.czyAneks) {
            aneksyId = this.state.aneksy.map((aneks) => {
                return aneks.id
            });
        }

        if (!this.state.netto.includes(",")) {
            this.setState({netto: this.state.netto += ",00"})
        }

        if (!this.state.brutto.includes(",")) {
            this.setState({brutto: this.state.brutto += ",00"})
        }

        await api.editUmowa(this.state.idUmowy, {
            id: '',
            dataZawarcia: this.convertDate(this.state.dataZawarcia),
            numerUmowy: this.state.numerUmowy,
            przedmiotUmowy: this.state.przedmiotUmowy,
            dataPoczatkowa: this.convertDate(this.state.dataPoczatkowa),
            dataKoncowa: this.convertDate(this.state.dataKoncowa),
            brutto: this.state.brutto,
            netto: this.state.netto,
            czyAneks: this.state.czyAneks,
            aneksyTab: aneksyId,
            trescWord: '',
            fkIdOsoby: this.state.IdOsoby,
            fkIdJednostki: this.state.IdJednostki,
            fkIdWykonawcy: this.state.IdWykonawcy,
            fkIdStatusUmowy: this.state.IdStatusUmowy,
            fkIdRodzajuUmowy: this.state.IdRodzajuUmowy,
            fkIdWaluty: this.state.IdWaluty,
            fkIdAlertu: '',
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    onFormSubmit = (event) => {
        event.preventDefault();

        const isValid = this.validateForm();

        if (isValid) {
            this.saveForm().then(() => this.setState({redirectSwitch: true}));
            this.setState({nazwaAktuError: '', numerAktuError: '', dataWydaniaError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    onCancel = () => {
        if (this.state.modeName === 'Dodaj') {
            const api = new DefaultApi({});
            api.deleteUmowa(this.state.idUmowy).then(() => this.setState({redirectSwitch: true})).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        }
        this.setState({redirectSwitch: true});
    }

    onDodajAneks = async () => {
        const api = new DefaultApi({});
        const liczbaAneksow = this.state.aneksy.length + 1;
        await api.addAneksUmowa(this.state.idUmowy).then((response) => {
            this.setState({idAneksu: response.data.id});
            this.setState({idUmowy: this.state.idAneksu});
            this.setState({czyAneks: true});
            this.getItem(this.state.idAneksu).then(() => {
                this.setState({numerUmowy: "Aneks nr " + liczbaAneksow + " do umowy nr " + this.state.numerUmowy, modeTitle: "Edycja aneksu"});
            });
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

    }

    onPDFFileChange = async (e) => {
        this.setState({rodzajPliku: ZalacznikUmowyRodzajPlikuEnum.PDF});
        await this.onFileChange(e);
    }

    onWordFileChange = async (e) => {
        this.setState({rodzajPliku: ZalacznikUmowyRodzajPlikuEnum.WORD});
        await this.onFileChange(e);
    }

    onZalacznikFileChange = async (e) => {
        this.setState({rodzajPliku: ZalacznikUmowyRodzajPlikuEnum.ZALACZNIK});
        await this.onFileChange(e);
    }

    onFileChange = async (e) => {
        const api = new DefaultApi({});

        let formData = new FormData();
        let headers = new Headers();

        await this.setState({
                file: e.target.files[0],
                fileName: e.target.files[0].name,
                size: e.target.files[0].size,
                date: e.target.files[0].lastModifiedDate,
                type: e.target.files[0].type
            }
        );


        await api.addZalacznikUmowy({
            id: '',
            sciezka: '',
            nrZalacznika: '',
            dataDodania: this.state.date,
            fkIdUmowy: this.state.idUmowy,
            nazwaPliku: this.state.fileName,
            uwagi: '',
            rodzajPliku: this.state.rodzajPliku,
            mime: this.state.type
        }).then((response) => {
            this.setState({idZalacznika: response.data.id});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        headers.append('Content-Type', 'multipart/form-data');
        formData.append('file', this.state.file);
        formData.append('idZalacznika', this.state.idZalacznika);

        await Axios.post('/umowy/pliki/', formData, {headers: headers});
        await this.getZalaczniki();
    };

    handleConfirmZalacznik = () => {
        const api = new DefaultApi({});
        api.deleteZalacznikUmowy(this.state.idZalacznika).then(() => this.getZalaczniki()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handleError = () => this.setState({confirmError: false});

    handleCancel = () => this.setState({confirmDelete: false});

    deleteZalacznik = (id) => {
        this.setState({confirmDelete: true, idZalacznika: id});
    };

    downloadFile = async (e, id, filename) => {
        e.preventDefault();
        await Axios({
            method: 'get',
            url: '/umowy/pliki/' + id,
            responseType: 'blob'
        }).then((response) =>
            download(response.data, filename)
        );
    };

    handleConfirmAneks = () => {
        const api = new DefaultApi({});
        api.deleteUmowa(this.state.idAneksu).then(() => this.getAneksy()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDeleteAneks: false});
    };

    deleteAneks = (id) => {
        this.setState({confirmDeleteAneks: true, idAneksu: id});
    };

    render() {
        const {redirectSwitch} = this.state;
        const {errorAPI} = this.state;

        const osoby = this.state.osoby.map((osoba) => {
            return {
                key: osoba.id,
                text: osoba.imie + " " + osoba.nazwisko,
                value: osoba.id
            }
        });

        const jednostki = this.state.jednostki.map((jednostka) => {
            return {
                key: jednostka.id,
                text: jednostka.nazwaJednostki,
                value: jednostka.id
            }
        });

        const wykonawcy = this.state.wykonawcy.map((wykonawca) => {
            return {
                key: wykonawca.id,
                text: wykonawca.nazwaWykonawcy,
                value: wykonawca.id
            }

        });

        const statusy = this.state.statusy.map((status) => {
            return {
                key: status.id,
                text: status.nazwa,
                value: status.id
            }
        });

        const rodzajeumowy = this.state.rodzajeumowy.map((rodzajumowy) => {
            return {
                key: rodzajumowy.id,
                text: rodzajumowy.nazwaRodzajuUmowy,
                value: rodzajumowy.id
            }
        });

        const waluty = this.state.waluty.map((waluta) => {
            return {
                key: waluta.id,
                text: waluta.nazwaWaluty,
                value: waluta.id
            }
        });

        const jednostkiczasu = [
            {key: 1, text: 'dni', value: 1},
            {key: 2, text: 'tygodni', value: 2},
            {key: 3, text: 'miesięcy', value: 3},
        ]

        return <ErrorBoundary>
            <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirmZalacznik} cancel={this.handleCancel}
                     title={'Usunięcie załącznika'}
                     question={'Czy na pewno chcesz usunąć ten załącznik?'}/>

            <Confirm mode={this.state.confirmDeleteAneks} confirm={this.handleConfirmAneks} cancel={this.handleCancel}
                     title={'Usunięcie aneksu'}
                     question={'Czy na pewno chcesz usunąć ten aneks?'}/>

            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                <Header as='h3'>
                                    <Icon name='clipboard outline'/>
                                    <Header.Content>
                                        {this.state.modeTitle}
                                        <Header.Subheader>Proszę wprowadzić dane umowy / aneksu</Header.Subheader>
                                    </Header.Content>
                                </Header></Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Form.Field>
                        <label>Numer umowy:*</label>
                        <input placeholder='Wprowadź numer umowy' autoComplete="off" type="text" id="address"
                               value={this.state.numerUmowy}
                               onChange={this.onNumerUmowyChange}/>
                        {this.state.numerUmowyError ? (
                            <Label color='red' pointing>
                                {this.state.numerUmowyError}
                            </Label>
                        ) : null}
                    </Form.Field>

                    <Form.Field>
                        <label>Data zawarcia umowy:*</label>
                        <SemanticDatepicker onChange={this.onDataZawarciaChange} format='DD-MM-YYYY' locale="en-US" datePickerOnly={true} value={this.state.dataZawarcia}/>
                        {this.state.dataZawarciaError ? (
                            <Label color='red' pointing>
                                {this.state.dataZawarciaError}
                            </Label>
                        ) : null}
                    </Form.Field>

                    <Form.Field>
                        <Grid divided='vertically'>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <Segment raised>
                                        <Label as='a' color='blue' ribbon>
                                            Plik umowy w formacie PDF:
                                        </Label>
                                        {this.state.zalacznikiPDF.length === 0 ? (
                                            <div>
                                                <br/>
                                                <Button color='blue' as="label" htmlFor="filepdf"><Icon name='file pdf outline'/>Wczytaj plik umowy (PDF)</Button>
                                                <input type="file" accept=".pdf, application/pdf" id="filepdf" style={{display: 'none'}} onChange={this.onPDFFileChange}/></div>
                                        ) : (
                                            this.state.zalacznikiPDF.map((zalaczniki, i) => <FileList key={i} data={zalaczniki}
                                                                                                      remove={this.deleteZalacznik}
                                                                                                      load={this.downloadFile}
                                            />))}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Segment raised>
                                        <Label as='a' color='blue' ribbon>
                                            Plik umowy w formacie Word:
                                        </Label>
                                        {this.state.zalacznikiWord.length === 0 ? (
                                            <div>
                                                <br/>
                                                <Button color='blue' as="label" htmlFor="fileword"><Icon name='file word outline'/>Wczytaj plik umowy (Word)</Button>
                                                <input type="file" accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                       id="fileword" style={{display: 'none'}} onChange={this.onWordFileChange}/></div>
                                        ) : (
                                            this.state.zalacznikiWord.map((zalaczniki, i) => <FileList key={i} data={zalaczniki}
                                                                                                       remove={this.deleteZalacznik}
                                                                                                       load={this.downloadFile}
                                            />))}
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Segment placeholder>
                        <Label as='a' color='blue' ribbon>
                            Załączniki do umowy:
                        </Label>
                        {this.state.zalaczniki.length === 0 ? (
                            <Header icon>
                                <Icon name='folder open outline'/>
                                Ta umowa nie posiada załączników.
                            </Header>
                        ) : (
                            this.state.zalaczniki.map((zalaczniki, i) => <FileList key={i} data={zalaczniki}
                                                                                   remove={this.deleteZalacznik}
                                                                                   load={this.downloadFile}
                            />))}
                        <br/>
                        <Button color='blue' as="label" htmlFor="file" type="button"><Icon name='attach'/>Dodaj załącznik</Button>
                        <input type="file" accept=".pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                               id="file" style={{display: 'none'}} onChange={this.onZalacznikFileChange}/>
                    </Segment>

                    <Form.Field>
                        <label>Rodzaj umowy:</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder={'Wybierz rodzaj umowy'}
                                    fluid
                                    noResultsMessage={"Brak danych"}
                                    selection
                                    onChange={this.onIdRodzajuUmowyChange}
                                    options={rodzajeumowy}
                                    value={this.state.IdRodzajuUmowy}
                                />
                                    {this.state.IdRodzajuUmowyError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdRodzajuUmowyError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <RodzajUmowyModal change={this.getRodzajeUmowy}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <label>Dane wykonawcy:</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder={'Wybierz wykonawcę'}
                                    fluid
                                    noResultsMessage={"Brak danych"}
                                    selection
                                    onChange={this.onIdWykonawcyChange}
                                    options={wykonawcy}
                                    value={this.state.IdWykonawcy}
                                />
                                    {this.state.IdWykonawcyError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdWykonawcyError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <WykonawcaModal change={this.getWykonawcy}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <Grid divided='vertically'>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <label>Wartość umowy netto:</label>
                                    <input placeholder='Wprowadź wartość umowy netto' autoComplete="off" type="text" id="address"
                                           value={this.state.netto}
                                           onChange={this.onNettoChange}/>
                                    {this.state.nettoError ? (
                                        <Label color='red' pointing>
                                            {this.state.nettoError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <label>Wartość umowy brutto:*</label>
                                    <input placeholder='Wprowadź wartość umowy brutto' autoComplete="off" type="text" id="address"
                                           value={this.state.brutto}
                                           onChange={this.onBruttoChange}/>
                                    {this.state.bruttoError ? (
                                        <Label color='red' pointing>
                                            {this.state.bruttoError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <label>Waluta:*</label>
                                    <Dropdown
                                        placeholder={'Wybierz walutę'}
                                        fluid
                                        noResultsMessage={"Brak danych"}
                                        selection
                                        onChange={this.onIdWalutyChange}
                                        options={waluty}
                                        value={this.state.IdWaluty}
                                    />
                                    {this.state.IdWalutyError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdWalutyError}
                                        </Label>
                                    ) : null}
                                    <br/>
                                    <RodzajWalutyModal change={this.getWaluty}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.TextArea onChange={this.onPrzedmiotUmowyChange} label='Przedmiot umowy:'
                                   placeholder='Wprowadź przedmiot umowy' value={this.state.przedmiotUmowy}/>
                    {this.state.przedmiotUmowyError ? (
                        <Label color='red' pointing>
                            {this.state.przedmiotUmowyError}
                        </Label>
                    ) : null}

                    <Form.Field>
                        <Grid divided='vertically'>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <label>Termin realizacji umowy od:</label>
                                    <SemanticDatepicker onChange={this.onDataPoczatkowaChange} format='DD-MM-YYYY' locale="en-US" datePickerOnly={true}
                                                        value={this.state.dataPoczatkowa}/>
                                    {this.state.dataPoczatkowaError ? (
                                        <Label color='red' pointing>
                                            {this.state.dataPoczatkowaError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <label>Termin realizacji umowy do:</label>
                                    <SemanticDatepicker onChange={this.onDataKoncowaChange} format='DD-MM-YYYY' locale="en-US" datePickerOnly={true}
                                                        value={this.state.dataKoncowa}/>
                                    {this.state.dataKoncowaError ? (
                                        <Label color='red' pointing>
                                            {this.state.dataKoncowaError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <label>lub:*</label>
                                    <input placeholder='Wartość' autoComplete="off" type="text" id="address"
                                           value={this.state.wartosc}
                                           onChange={this.onWartoscChange}/>
                                    {this.state.wartoscError ? (
                                        <Label color='red' pointing>
                                            {this.state.wartoscError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <label>Określ jednostkę czasu:</label>
                                    <Dropdown
                                        placeholder={'Wybierz jednostkę czasu'}
                                        fluid
                                        noResultsMessage={"Brak danych"}
                                        selection
                                        onChange={this.onJednostkaCzasuChange}
                                        options={jednostkiczasu}
                                        value={this.state.IdJednostkiCzasu}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <label>Jednostka odpowiedzialna za realizację umowy:</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder='Wybierz jednostkę odpowiedzialną za realizację umowy'
                                    fluid
                                    selection
                                    onChange={this.onIdJednostkiChange}
                                    options={jednostki}
                                    value={this.state.IdJednostki}
                                />
                                    {this.state.IdJednostkiError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdJednostkiError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <JednostkaOdpowiedzialnaModal change={this.getJednostki}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <label>Osoba odpowiedzialna za realizację umowy:</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder='Wybierz osobę odpowiedzialną za realizację umowy'
                                    fluid
                                    selection
                                    onChange={this.onIdOsobyChange}
                                    options={osoby}
                                    value={this.state.IdOsoby}
                                />
                                    {this.state.IdOsobyError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdOsobyError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <OsobaModal change={this.getOsoby}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Form.Field>
                        <label>Status:*</label>
                        <Grid divided='vertically'>
                            <Grid.Row columns={2}>
                                <Grid.Column><Dropdown
                                    placeholder='Wybierz status'
                                    fluid
                                    selection
                                    onChange={this.onIdStatusUmowyChange}
                                    options={statusy}
                                    value={this.state.IdStatusUmowy}
                                />
                                    {this.state.IdStatusUmowyError ? (
                                        <Label color='red' pointing>
                                            {this.state.IdStatusUmowyError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <StatusUmowyModal change={this.getStatusy}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>
                    {this.state.czyAneks === null ? (
                        <Segment placeholder>
                            <Label as='a' color='blue' ribbon>
                                Aneksy do umowy:
                            </Label>
                            {this.state.aneksy.length === 0 ? (
                                <Header as='h3' icon>
                                    <Icon name='share square'/>
                                    Ta umowa nie posiada aneksów
                                </Header>
                            ) : (
                                this.state.aneksy.map((aneksy, i) => <AneksList key={i} data={aneksy}
                                                                                remove={this.deleteAneks}
                                                                                load={this.getItem}
                                />))}
                            <br/>
                            <Button primary disabled={this.state.disabledAneks} onClick={this.onDodajAneks} as="label">Dodaj aneks do umowy</Button>
                        </Segment>
                    ) : null}

                    <Form.Field>
                        <label>Dodaj tag:*</label>
                        <Icon name='add square'/>
                    </Form.Field>

                    <Form.Field>
                        <label>Utwórz alert:*</label>
                        <Icon name='add square'/>
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    <Button basic onClick={this.onCancel}>Anuluj</Button>
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/umowy'}/>
            )}
        </ErrorBoundary>
    }
}

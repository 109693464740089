import React, {Component} from 'react';
import PodmiotForm from "./PodmiotForm";


export default class PodmiotAdd extends Component {

    render() {
        return <div>
            <PodmiotForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

import React from 'react';
import {Button, Header, Icon, Modal} from 'semantic-ui-react'

const Confirm = ({title, question, mode, confirm, cancel}) => {
    return (
        <Modal open={mode} basic size='small'>
            <Header icon='question circle outline' content={title}/>
            <Modal.Content>
                <p>
                    {question}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => cancel()} basic color='red' inverted>
                    <Icon name='remove'/> Nie
                </Button>
                <Button onClick={() => confirm()} color='green' inverted>
                    <Icon name='checkmark'/> Tak
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default Confirm;
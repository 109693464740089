import React, {Component} from 'react';
import JednostkaInicjujacaForm from "./JednostkaInicjujacaForm";


export default class JednostkaInicjujacaEdit extends Component {

    render() {
        return <div>
            <JednostkaInicjujacaForm mode={'edit'} id={this.props.match.params.id}/>
        </div>

    }
}

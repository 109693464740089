import React, {Component} from 'react';
import StatusUmowyForm from "./StatusUmowyForm";


export default class StatusUmowyEdit extends Component {

    render() {
        return <div>
            <StatusUmowyForm mode={'edit'} id={this.props.match.params.id}/>
        </div>
    }
}

import React, {Component} from 'react';
import RodzajWalutyForm from "./RodzajWalutyForm";


export default class RodzajWalutyAdd extends Component {

    render() {
        return <div>
            <RodzajWalutyForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

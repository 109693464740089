import React, {Component} from 'react';
import {Message} from 'semantic-ui-react';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: '', errorInfo: ''};
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: info
        });
    }

    render() {
        if (this.state.hasError) {
            return <div>
                <Message color='orange'>
                    <Message.Header>{this.state.error.toString()}</Message.Header>
                    <p>{this.state.errorInfo.componentStack}</p>
                </Message>
            </div>
        }
        return this.props.children;
    }
}
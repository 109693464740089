import React, {Component} from 'react';
import {Message, Button, Icon, Header, Segment, Popup} from 'semantic-ui-react';
import {Link, Redirect} from 'react-router-dom';
import ErrorBoundary from '../../common/ErrorBoundary';
import Confirm from '../../common/Confirm';
import Spinner from '../../common/Spinner';
import {DefaultApi} from "../../api";
import Error from '../../common/Error';

class TableRow extends Component {

    render() {
        return <tr>
            <td width="300px">{this.props.data.nazwaWykonawcy}</td>
            <td>{this.props.data.ulica + ", " + this.props.data.miasto}</td>
            <td>{this.props.data.email + " / tel. " + this.props.data.telefon}</td>
            <td width="120px">
                <Popup
                    trigger={<Icon link name='edit' onClick={() => this.props.edit(this.props.data.id)}/>}
                    content='Edycja wykonawcy'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                    content='Usunięcie wybranego wykonawcy'
                    size='tiny'
                    inverted
                />
            </td>
        </tr>
    }
}


export default class WykonawcaList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idWykonawcy: '',
            isLoading: true,
            wykonawcy: [],
            domyslny: false,
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getWykonawcy();
    }

    getWykonawcy = async () => {
        const api = new DefaultApi({});
        api.listOfWykonawca().then((response) => {
            this.setState({wykonawcy: response.data, isLoading: false});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    listWykonawcy = () => {
        return (
            <Segment raised className={`panel`}>

                <Header as='h3'>
                    <Icon name='configure'/>
                    <Header.Content>
                        Lista wykonawców
                        <Header.Subheader>Zarządzanie wykonawcami</Header.Subheader>
                    </Header.Content>
                </Header>

                <Link to={'/umowy/wykonawca/dodaj'}><Button primary>Dodaj wykonawcę</Button></Link>
                <table className="ui table">
                    <thead>
                    <tr>
                        <th>Nazwa wykonawcy</th>
                        <th>Adres wykonawcy</th>
                        <th>Kontakt</th>
                        <th>Opcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.wykonawcy.sort((a, b) => {
                        if (a.nazwa != null)
                            return a.nazwa.localeCompare(b.nazwa)
                        else if (b.nazwa != null)
                            return b.nazwa.localeCompare(a.nazwa)
                        return null
                    }).map((wykonawcy, i) => <TableRow key={i} data={wykonawcy}
                                                       onDomyslnyChange={this.onDomyslnyChange}
                                                       remove={this.deleteWykonawca}
                                                       edit={this.editWykonawca}
                        />
                    )}
                    </tbody>
                </table>
                <Segment>
                    <Header as='h4' color='grey'>Legenda</Header>
                    <Icon link name='edit'/> - edycja wybranego wykonawcy<br/>
                    <Icon link name='trash'/> - usunięcie wybranego wykonawcy
                </Segment>
            </Segment>
        )
    };

    showWykonawca = () => {
        if (this.state.wykonawcy.length > 0) {
            return this.listWykonawcy()
        } else {
            if (this.state.isLoading) {
                return <Spinner/>
            } else {
                return <div>
                    <Message
                        color='red'
                        header='Nie ma zdefiniowanych wykonawców'
                        list={[
                            'Musisz zdefiniować wykonawcę. W tym celu kliknij przycisk "Dodaj wykonawcę"',
                        ]}
                    />
                    <Link to={'/umowy/wykonawca/dodaj'}><Button primary>Dodaj wykonawcę</Button></Link>
                </div>
            }
        }
    };


    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteWykonawca(this.state.idWykonawcy).then(() => this.getWykonawcy()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handleCancel = () => this.setState({confirmDelete: false});

    deleteWykonawca = (id) => {
        this.setState({confirmDelete: true, idWykonawcy: id});
    };

    editWykonawca = (id) => {
        this.setState({redirectEdit: true, idWykonawcy: id})
    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectEdit} = this.state;
        const {errorAPI} = this.state;

        return <ErrorBoundary>
            {this.showWykonawca()}

            <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                     title={'Usunięcie wykonawcy'}
                     question={'Czy na pewno chcesz usunąć tego wykonawcę?'}/>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectEdit && (
                <Redirect push to={'/umowy/wykonawca/edytuj/' + this.state.idWykonawcy}/>
            )}
        </ErrorBoundary>
    }
}
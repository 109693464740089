import React, {Component} from "react";
import {Button, Grid, Form, Segment, Label, Checkbox} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

export default class AktyPrawneSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            matchAllAttributes: false,
            przedmiotAktu: '',
            nazwaRodzaju: '',
            numerRocznikAktu: '',
            dataWydaniaOd: '',
            dataWydaniaDo: '',
            nazwaStatusu: '',
            nazwaJednostki: '',
            aktyPrawneZaawansowane: [],
            dataError: ''
        };
    }

    onNazwaRodzajuChange = (e) => {
        this.setState({nazwaRodzaju: e.target.value});
    };

    onNumerRocznikAktuChange = (e) => {
        this.setState({numerRocznikAktu: e.target.value});
    };

    onDataWydaniaOdChange = (e, data) => {
        this.setState({dataWydaniaOd: data.value});
    };

    onDataWydaniaDoChange = (e, data) => {
        this.setState({dataWydaniaDo: data.value});
    };

    onPrzedmiotAktuChange = (e) => {
        this.setState({przedmiotAktu: e.target.value});
    };

    onNazwaJednostkiChange = (e) => {
        e.preventDefault();
        this.setState({nazwaJednostki: e.target.value});
    };

    onNazwaStatusuChange = (e) => {
        e.preventDefault();
        this.setState({nazwaStatusu: e.target.value});
    };

    onMatchAllAttribitesChange = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({matchAllAttributes: !prevState.matchAllAttributes}));
    };

    validateForm = () => {

        let dataError = "";
        let isWrongDate = "Data końcowa nie może być wcześniejsza od daty początkowej";

        if (this.state.dataWydaniaOd && this.state.dataWydaniaDo) {
            if (this.state.dataWydaniaOd >= this.state.dataWydaniaDo) {
                dataError = isWrongDate;
            }
        }

        this.setState({dataError});

        return !dataError;
    };

    onFormSubmit = async (event) => {
        event.preventDefault();
        const isValid = this.validateForm();

        if (isValid) {
            this.props.onAktyPrawneChange(this.state);
        } else {
            console.log("Błędnie wypełniony formularz");
        }
    };

    render() {

        return <Segment raised>
            <Label as='a' color='blue' ribbon>
                Wyszukiwanie zaawansowane
            </Label>
            <br/><br/>
            <Form onSubmit={this.onFormSubmit}>
                <Form.TextArea onChange={this.onPrzedmiotAktuChange} label='Przedmiot aktu prawnego:'
                               placeholder='Wprowadź przedmiot aktu prawnego' value={this.state.przedmiotAktu}/>


                <Form.Field>
                    <label>Rodzaj aktu prawnego:</label>
                    <Grid divided='vertically'>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <input placeholder='Wprowadź nazwę rodzaju aktu prawnego' autoComplete="off" type="text"
                                       value={this.state.nazwaRodzaju}
                                       onChange={this.onNazwaRodzajuChange}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form.Field>

                <Form.Field>
                    <label>Numer i rocznik:</label> <input placeholder='Wprowadź numer i rocznik aktu prawnego'
                                                           autoComplete="off" type="text"
                                                           id="address"
                                                           value={this.state.numerRocznikAktu}
                                                           onChange={this.onNumerRocznikAktuChange}/>
                </Form.Field>

                <Form.Field>
                    <label>Data wydania aktu prawnego:</label>
                    <div>
                        od:&nbsp;&nbsp;<SemanticDatepicker onChange={this.onDataWydaniaOdChange} format='DD-MM-YYYY'
                                                           locale="en-US"
                                                           value={this.state.dataWydaniaOd}/>&nbsp;&nbsp;
                        do:&nbsp;&nbsp; <SemanticDatepicker onChange={this.onDataWydaniaDoChange} format='DD-MM-YYYY'
                                                            locale="en-US"
                                                            value={this.state.dataWydaniaDo}/>
                    </div>
                    {this.state.dataError ? (
                        <Label color='red' pointing>
                            {this.state.dataError}
                        </Label>
                    ) : null}
                </Form.Field>

                <Form.Field>
                    <label>Status:</label>
                    <Grid divided='vertically'>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <input placeholder='Wprowadź nazwę statusu' autoComplete="off" type="text"
                                       value={this.state.nazwaStatusu}
                                       onChange={this.onNazwaStatusuChange}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form.Field>

                <Form.Field>
                    <label>Jednostka inicjująca:</label>
                    <Grid divided='vertically'>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <input placeholder='Wprowadź nazwę jednostki odpowiedzialnej' autoComplete="off"
                                       type="text"
                                       value={this.state.nazwaJednostki}
                                       onChange={this.onNazwaJednostkiChange}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form.Field>

                <Form.Field>
                    <Checkbox label='Dopasuj wszystkie pola...'
                              onChange={this.onMatchAllAttribitesChange}
                              checked={this.state.matchAllAttributes}
                    />
                </Form.Field>

                <Button primary>Wyszukaj akty prawne</Button>
            </Form>
        </Segment>
    }
}

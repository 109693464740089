import React, {Component} from "react";
import {Icon, List, Popup} from "semantic-ui-react";
import {Link} from 'react-router-dom';
import {DefaultApi} from "../api";

export default class ItemList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            aktPrawny: '',
            aktyPrawneTab: this.props.aktyPrawneTab,
            rodzaje: [],
            rodzajAktuPrawnego: '',
            isLoading: true,
        }
    }

    getAktPrawny = () => {
            const api = new DefaultApi({});
            if (this.state.aktyPrawneTab)
                api.aktPrawnyDetails(this.state.aktyPrawneTab).then((response) => this.setState({aktPrawny: response.data}));

            if (this.state.aktPrawny) {
                api.listOfRodzajAktuPrawnego().then((response) => this.setState({rodzaje: response.data}));
                this.setState({rodzajAktuPrawnego: this.state.rodzaje.find(rodzaj => rodzaj.id === this.state.aktPrawny.fkIdRodzajAktu).nazwaRodzajuAktu});
            }
    }

    componentDidMount() {
        this.getAktPrawny();
    }

    render() {
        return (
            <List>
                <List.Item>
                    <List.Content>
                        {this.state.aktPrawny ? (
                            <div>
                                <Icon name='file pdf outline'/>
                                <Link className="link-button" to='#'
                                      onClick={(e) => this.props.details(this.state.aktyPrawneTab, e)}>
                                    {this.state.rodzajAktuPrawnego} nr {this.state.aktPrawny.numerRocznikAktu} {this.state.aktPrawny.przedmiotAktu}
                                </Link>
                                {this.props.remove && <Popup
                                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.state.aktyPrawneTab)}/>}
                                    content='Usunięcie wybranego aktu prawnego z tej listy'
                                    size='tiny'
                                    inverted
                                />}
                            </div>
                        ) : (
                            <div>Nie można było odnaleźć aktu prawnego o ID: {this.state.aktyPrawneTab}</div>
                        )}
                    </List.Content>
                </List.Item>
            </List>
        )
    }
}

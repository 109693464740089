import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Form, Grid, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class RodzajWalutyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RodzajWaluty: [],
            nazwaWaluty: '',
            nazwaWalutyError: '',
            symbolWaluty: '',
            symbolWalutyError: '',
            domyslny: false,
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        if (this.props.mode === "add")
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie rodzaju waluty"});
        else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja rodzaju waluty"});
            this.getItem(this.props.id);
        }
    }

    onNazwaWalutyChange = (event) => {
        this.setState({nazwaWaluty: event.target.value});
    };

    onSymbolWalutyChange = (event) => {
        this.setState({symbolWaluty: event.target.value});
    };

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfWaluta(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const waluta = response.data.find((a) => a.id === id);

            this.setState({
                IdWaluty: waluta.valueOf().id,
                nazwaWaluty: waluta.valueOf().nazwaWaluty,
                symbolWaluty: waluta.valueOf().symbolWaluty,
                domyslny: waluta.valueOf().domyslny
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let nazwaWalutyError, symbolWalutyError = "";
        let isEmptyText = "Nie wypełniono tego pola";

        if (!this.state.nazwaWaluty) {
            nazwaWalutyError = isEmptyText;
        }

        if (!this.state.symbolWaluty) {
            symbolWalutyError = isEmptyText;
        }

        if (nazwaWalutyError || symbolWalutyError) {
            this.setState({nazwaWalutyError, symbolWalutyError});
            return false;
        }
        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {
            await api.addWaluta({
                id: '',
                nazwaWaluty: this.state.nazwaWaluty,
                symbolWaluty: this.state.symbolWaluty,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {
            await api.editWaluta(this.props.id, {
                id: '',
                nazwaWaluty: this.state.nazwaWaluty,
                symbolWaluty: this.state.symbolWaluty,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if(this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }
            this.setState({nazwaWalutyError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal}=this.props;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić nazwę rodzaju waluty</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={9}>
                                    <label>Nazwa rodzaju waluty:*</label>
                                    <input placeholder='Podaj nazwę rodzaju waluty' autoComplete="off" type="text" id="rodzaj"
                                           value={this.state.nazwaWaluty}
                                           onChange={this.onNazwaWalutyChange}/>
                                    {this.state.nazwaWalutyError ? (
                                        <Label color='red' pointing>
                                            {this.state.nazwaWalutyError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <label>Symbol rodzaju waluty:*</label>
                                    <input placeholder='Podaj symbol rodzaju waluty' autoComplete="off" type="text" id="symbol"
                                           value={this.state.symbolWaluty}
                                           onChange={this.onSymbolWalutyChange}/>
                                    {this.state.symbolWalutyError ? (
                                        <Label color='red' pointing>
                                            {this.state.symbolWalutyError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    {modal ?
                        <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/umowy/waluta'}><Button basic>Anuluj</Button></Link>
                    }
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/umowy/waluta'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
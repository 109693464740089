import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import AktyPrawneList from "../aktyprawne/AktyPrawneList";
import AktyPrawneAdd from "../aktyprawne/AktyPrawneAdd";
import AktyPrawneEdit from "../aktyprawne/AktyPrawneEdit";
import AktyPrawneDetail from "../aktyprawne/AktyPrawneDetail";
import JednostkaInicjujacaList from "../aktyprawne/ustawienia/JednostkaInicjujacaList";
import JednostkaInicjujacaAdd from "../aktyprawne/ustawienia/JednostkaInicjujacaAdd";
import JednostkaInicjujacaEdit from "../aktyprawne/ustawienia/JednostkaInicjujacaEdit";
import RodzajAktuList from "../aktyprawne/ustawienia/RodzajAktuList";
import RodzajAktuAdd from "../aktyprawne/ustawienia/RodzajAktuAdd";
import RodzajAktuEdit from "../aktyprawne/ustawienia/RodzajAktuEdit";
import StatusAktuPrawnegoList from "../aktyprawne/ustawienia/StatusAktuPrawnegoList";
import StatusAktuPrawnegoAdd from "../aktyprawne/ustawienia/StatusAktuPrawnegoAdd";
import StatusAktuPrawnegoEdit from "../aktyprawne/ustawienia/StatusAktuPrawnegoEdit";
import UmowyList from "../umowy/UmowyList";
import UmowyAdd from "../umowy/UmowyAdd";
import UmowyEdit from "../umowy/UmowyEdit";
import UmowyDetail from "../umowy/UmowyDetail";
import JednostkaOdpowiedzialnaList from "../umowy/ustawienia/JednostkaOdpowiedzialnaList";
import JednostkaOdpowiedzialnaAdd from "../umowy/ustawienia/JednostkaOdpowiedzialnaAdd";
import JednostkaOdpowiedzialnaEdit from "../umowy/ustawienia/JednostkaOdpowiedzialnaEdit";
import OsobaList from "../umowy/ustawienia/OsobaList";
import OsobaAdd from "../umowy/ustawienia/OsobaAdd";
import OsobaEdit from "../umowy/ustawienia/OsobaEdit";
import RodzajUmowyList from "../umowy/ustawienia/RodzajUmowyList";
import RodzajUmowyAdd from "../umowy/ustawienia/RodzajUmowyAdd";
import RodzajUmowyEdit from "../umowy/ustawienia/RodzajUmowyEdit";
import RodzajWalutyList from "../umowy/ustawienia/RodzajWalutyList";
import RodzajWalutyAdd from "../umowy/ustawienia/RodzajWalutyAdd";
import RodzajWalutyEdit from "../umowy/ustawienia/RodzajWalutyEdit";
import StatusUmowyList from "../umowy/ustawienia/StatusUmowyList";
import StatusUmowyAdd from "../umowy/ustawienia/StatusUmowyAdd";
import StatusUmowyEdit from "../umowy/ustawienia/StatusUmowyEdit";
import WykonawcaList from "../umowy/ustawienia/WykonawcaList";
import WykonawcaAdd from "../umowy/ustawienia/WykonawcaAdd";
import WykonawcaEdit from "../umowy/ustawienia/WykonawcaEdit";
import PodmiotList from "../umowy/ustawienia/PodmiotList";
import PodmiotAdd from "../umowy/ustawienia/PodmiotAdd";
import PodmiotEdit from "../umowy/ustawienia/PodmiotEdit";
import UstawieniaMain from "../ustawienia/UstawieniaMain";
import Kontakt from "./Contact";
import MainPage from "../public/MainPage";
import NoMatch from "../error/NoMatch";


export default class Routes extends React.Component {
    render() {
        return (<div>
                <Switch>
                    <ProtectedRoute exact path="/aktyprawne" component={AktyPrawneList}/>
                    <ProtectedRoute exact path="/aktyprawne/dodaj" component={AktyPrawneAdd}/>
                    <ProtectedRoute exact path="/aktyprawne/edytuj/:id" component={AktyPrawneEdit}/>
                    <ProtectedRoute exact path="/aktyprawne/details/:id" component={AktyPrawneDetail}/>
                    <ProtectedRoute exact path="/aktyprawne/jednostkainicjujaca" component={JednostkaInicjujacaList}/>
                    <ProtectedRoute exact path="/aktyprawne/jednostkainicjujaca/dodaj" component={JednostkaInicjujacaAdd}/>
                    <ProtectedRoute exact path="/aktyprawne/jednostkainicjujaca/edytuj/:id" component={JednostkaInicjujacaEdit}/>
                    <ProtectedRoute exact path="/aktyprawne/rodzajaktu" component={RodzajAktuList}/>
                    <ProtectedRoute exact path="/aktyprawne/rodzajaktu/dodaj" component={RodzajAktuAdd}/>
                    <ProtectedRoute exact path="/aktyprawne/rodzajaktu/edytuj/:id" component={RodzajAktuEdit}/>
                    <ProtectedRoute exact path="/aktyprawne/status" component={StatusAktuPrawnegoList}/>
                    <ProtectedRoute exact path="/aktyprawne/status/dodaj" component={StatusAktuPrawnegoAdd}/>
                    <ProtectedRoute exact path="/aktyprawne/status/edytuj/:id" component={StatusAktuPrawnegoEdit}/>
                    <ProtectedRoute exact path="/umowy" component={UmowyList}/>
                    <ProtectedRoute exact path="/umowy/dodaj" component={UmowyAdd}/>
                    <ProtectedRoute exact path="/umowy/edytuj/:id" component={UmowyEdit}/>
                    <ProtectedRoute exact path="/umowy/details/:id" component={UmowyDetail}/>
                    <ProtectedRoute exact path="/umowy/jednostkaodpowiedzialna" component={JednostkaOdpowiedzialnaList}/>
                    <ProtectedRoute exact path="/umowy/jednostkaodpowiedzialna/dodaj" component={JednostkaOdpowiedzialnaAdd}/>
                    <ProtectedRoute exact path="/umowy/jednostkaodpowiedzialna/edytuj/:id" component={JednostkaOdpowiedzialnaEdit}/>
                    <ProtectedRoute exact path="/umowy/osoba" component={OsobaList}/>
                    <ProtectedRoute exact path="/umowy/osoba/dodaj" component={OsobaAdd}/>
                    <ProtectedRoute exact path="/umowy/osoba/edytuj/:id" component={OsobaEdit}/>
                    <ProtectedRoute exact path="/umowy/rodzajumowy" component={RodzajUmowyList}/>
                    <ProtectedRoute exact path="/umowy/rodzajumowy/dodaj" component={RodzajUmowyAdd}/>
                    <ProtectedRoute exact path="/umowy/rodzajumowy/edytuj/:id" component={RodzajUmowyEdit}/>
                    <ProtectedRoute exact path="/umowy/status" component={StatusUmowyList}/>
                    <ProtectedRoute exact path="/umowy/status/dodaj" component={StatusUmowyAdd}/>
                    <ProtectedRoute exact path="/umowy/status/edytuj/:id" component={StatusUmowyEdit}/>
                    <ProtectedRoute exact path="/umowy/waluta" component={RodzajWalutyList}/>
                    <ProtectedRoute exact path="/umowy/waluta/dodaj" component={RodzajWalutyAdd}/>
                    <ProtectedRoute exact path="/umowy/waluta/edytuj/:id" component={RodzajWalutyEdit}/>
                    <ProtectedRoute exact path="/umowy/wykonawca" component={WykonawcaList}/>
                    <ProtectedRoute exact path="/umowy/wykonawca/dodaj" component={WykonawcaAdd}/>
                    <ProtectedRoute exact path="/umowy/wykonawca/edytuj/:id" component={WykonawcaEdit}/>
                    <ProtectedRoute exact path="/umowy/podmiot" component={PodmiotList}/>
                    <ProtectedRoute exact path="/umowy/podmiot/dodaj" component={PodmiotAdd}/>
                    <ProtectedRoute exact path="/umowy/podmiot/edytuj/:id" component={PodmiotEdit}/>
                    <ProtectedRoute exact path="/ustawienia" component={UstawieniaMain}/>
                    <Route exact path="/kontakt" component={Kontakt}/>
                    <Route exact path="/" component={MainPage}/>

                    <Route component={NoMatch}/>
                </Switch>
            </div>
        )
    }
}
import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Form, Grid, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class JednostkaOdpowiedzialnaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nazwaJednostkiOdpowiedzialnej: '',
            nazwaJednostkiOdpowiedzialnejError: '',
            symbolJednostkiOdpowiedzialnej: '',
            symbolJednostkiOdpowiedzialnejError: '',
            domyslny: '',
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        if (this.props.mode === "add")
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie jednostki odpowiedzialnej"});
        else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja jednostki odpowiedzialnej"});
            this.getItem(this.props.id);
        }
    }

    onNazwaJednostkiOdpowiedzialnejChange = (event) => {
        this.setState({nazwaJednostkiOdpowiedzialnej: event.target.value});
    };

    onSymbolJednostkiOdpowiedzialnejChange = (event) => {
        this.setState({symbolJednostkiOdpowiedzialnej: event.target.value});
    };

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfJednostki(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const jednostkaOdpowiedzialna = response.data.find((a) => a.id === id);

            this.setState({
                IdJednostkiOdpowiedzialnej: jednostkaOdpowiedzialna.valueOf().id,
                nazwaJednostkiOdpowiedzialnej: jednostkaOdpowiedzialna.valueOf().nazwaJednostki,
                symbolJednostkiOdpowiedzialnej: jednostkaOdpowiedzialna.valueOf().symbolJednostki,
                domyslny: jednostkaOdpowiedzialna.valueOf().domyslny
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let nazwaJednostkiOdpowiedzialnejError, symbolJednostkiOdpowiedzialnejError = "";
        let isEmptyText = "Nie wypełniono tego pola";

        if (!this.state.nazwaJednostkiOdpowiedzialnej) {
            nazwaJednostkiOdpowiedzialnejError = isEmptyText;
        }

        if (!this.state.symbolJednostkiOdpowiedzialnej) {
            symbolJednostkiOdpowiedzialnejError = isEmptyText;
        }

        if (nazwaJednostkiOdpowiedzialnejError || symbolJednostkiOdpowiedzialnejError) {
            this.setState({nazwaJednostkiOdpowiedzialnejError, symbolJednostkiOdpowiedzialnejError});
            return false;
        }
        return true;
    };

    handleError = () => this.setState({confirmError: false});

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {

            await api.addJednostka({
                id: '',
                nazwaJednostki: this.state.nazwaJednostkiOdpowiedzialnej,
                symbolJednostki: this.state.symbolJednostkiOdpowiedzialnej,
                domyslny: false,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {

            await api.editJednostka(this.props.id, {
                id: '',
                nazwaJednostki: this.state.nazwaJednostkiOdpowiedzialnej,
                symbolJednostki: this.state.symbolJednostkiOdpowiedzialnej,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if(this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }
            this.setState({nazwaJednostkiInicjujacejError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }

    };

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal}=this.props;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić dane jednostki odpowiedzialnej</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={9}>
                                    <label>Nazwa jednostki odpowiedzialnej:*</label>
                                    <input placeholder='Podaj nazwę jednostki odpowiedzialnej' autoComplete="off" type="text" id="address"
                                           value={this.state.nazwaJednostkiOdpowiedzialnej}
                                           onChange={this.onNazwaJednostkiOdpowiedzialnejChange}/>
                                    {this.state.nazwaJednostkiOdpowiedzialnejError ? (
                                        <Label color='red' pointing>
                                            {this.state.nazwaJednostkiOdpowiedzialnejError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <label>Symbol jednostki odpowiedzialnej:*</label>
                                    <input placeholder='Podaj symbol jednostki odpowiedzialnej' autoComplete="off" type="text" id="symbol"
                                           value={this.state.symbolJednostkiOdpowiedzialnej}
                                           onChange={this.onSymbolJednostkiOdpowiedzialnejChange}/>
                                    {this.state.symbolJednostkiOdpowiedzialnejError ? (
                                        <Label color='red' pointing>
                                            {this.state.symbolJednostkiOdpowiedzialnejError}
                                        </Label>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    {modal ?
                        <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/umowy/jednostkaodpowiedzialna'}><Button basic>Anuluj</Button></Link>
                    }
                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/umowy/jednostkaodpowiedzialna'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
import React, {Component} from 'react';
import StatusAktuPrawnegoForm from "./StatusAktuPrawnegoForm";


export default class StatusAktuPrawnegoAdd extends Component {

    render() {
        return <div>
            <StatusAktuPrawnegoForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

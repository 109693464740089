import React, {Component} from 'react';
import JednostkaInicjujacaForm from "./JednostkaInicjujacaForm";


export default class JednostkaInicjujacaAdd extends Component {

    render() {
        return <div>
            <JednostkaInicjujacaForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

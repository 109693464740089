import React, {Component} from 'react';
import AktyPrawneForm from "./AktyPrawneForm";


export default class AktyPrawneAdd extends Component {

    render() {
        return <div>
            <AktyPrawneForm mode={'add'}/>
        </div>
    }
}

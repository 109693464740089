import React, {Component} from 'react';
import {Header, Icon, Button, Divider, Table, Segment} from 'semantic-ui-react';
import {DefaultApi} from "../api/index";
import {ZalacznikAktuPrawnegoRodzajPlikuEnum} from "../api/ts/model";
import FileList from "../common/FileList";
import AneksList from "../common/AneksList";
import Axios from "../common/Axios";
import download from "downloadjs";
import {Redirect} from "react-router-dom";
import ErrorBoundary from "../common/ErrorBoundary";
import Error from '../common/Error';

export default class UmowyDetail extends Component {

    constructor(props) {
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
        this.state = {
            umowa: [],
            zalacznikiPDF: [],
            zalacznikiWord: [],
            zalaczniki: [],
            rodzajeUmowy: [],
            wykonawcy: [],
            jednostki: [],
            osoby: [],
            statusy: [],
            aneksy: [],
            showDetailsPanel: false,
            redirectDetail: false,
            idUmowy: '',
            nazwaStatusu: [],
            rodzajUmowy: [],
            jednostkaOdpowiedzialna: [],
            osobaOdpowiedzialna: [],
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getUmowa(this.props.match.params.id);
    }

    detailsUmowa = (id, e) => {
        e.preventDefault();
        this.setState({showDetailsPanel: true, redirectDetail: true, idUmowy: id});
    };

    closeDetails = () => {
        this.setState({showDetailsPanel: false})
    }

    downloadFile = async (e, id, filename) => {
        e.preventDefault();
        await Axios({
            method: 'get',
            url: '/umowy/pliki/' + id,
            responseType: 'blob'
        }).then((response) =>
            download(response.data, filename)
        );
    };

    getUmowa = async (id) => {
        const api = new DefaultApi({});
        const response = await api.umowaDetails(id).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({umowa: response.data});

        await api.listOfZalacznikUmowy(id).then((response) => this.setState({
            zalaczniki: response.data,
            isLoading: false
        })).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        this.setState({zalacznikiPDF: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.PDF)});
        this.setState({zalacznikiWord: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.WORD)});
        this.setState({zalaczniki: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.ZALACZNIK)});

        await api.listOfAneksUmowaEL(id).then((response) => this.setState({aneksy: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    handleError = () => this.setState({confirmError: false});

    handleBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {umowa} = this.state;
        const {redirectDetail} = this.state;
        const {errorAPI} = this.state;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Header as='h2' icon textAlign='center'>
                    <Icon name='clipboard outline' circular/>
                    <Header.Content>Szczegóły umowy {umowa.numerUmowy}</Header.Content>
                </Header>

                <p style={{textAlign: 'center'}}>
                    Poniżej wyświetlone są szczegóły wskazanej umowy (aneksu do umowy)...
                </p>

                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='bar chart'/>
                        Dane umowy
                    </Header>
                </Divider>

                <Table definition>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={7}>Numer umowy:</Table.Cell>
                            <Table.Cell>{umowa.numerUmowy}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Data zawarcia umowy:</Table.Cell>
                            <Table.Cell>{umowa.dataZawarcia}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Plik umowy w formacie PDF:</Table.Cell>
                            <Table.Cell>
                                {this.state.zalacznikiPDF.map((zalaczniki, i) => <FileList key={i}
                                                                                           data={zalaczniki}
                                                                                           load={this.downloadFile}/>)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Plik umowy w formacie Word:</Table.Cell>
                            <Table.Cell>
                                {this.state.zalacznikiWord.map((zalaczniki, i) => <FileList key={i}
                                                                                            data={zalaczniki}
                                                                                            load={this.downloadFile}/>)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Załączniki do umowy:</Table.Cell>
                            <Table.Cell>
                                {this.state.zalaczniki.map((zalaczniki, i) => <FileList key={i}
                                                                                        data={zalaczniki}
                                                                                        load={this.downloadFile}/>)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Aneksy do umowy:</Table.Cell>
                            <Table.Cell>
                                {this.state.aneksy.length === 0 ? (
                                    <div>Ta umowa nie posiada aneksów</div>
                                ) : (
                                    this.state.aneksy.map((aneksy, i) => <AneksList key={i} data={aneksy}
                                                                                       remove={this.deleteAneks}
                                                                                       load={this.detailsUmowa}
                                                                                       id={this.state.umowa.id}
                                    />))}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Rodzaj umowy:</Table.Cell>
                            <Table.Cell>{this.state.umowa.rodzajUmowy}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Dane wykonawcy:</Table.Cell>
                            <Table.Cell>{umowa.wykonawca}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Wartość umowy:</Table.Cell>
                            <Table.Cell>{umowa.netto} NETTO, {umowa.brutto} BRUTTO</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Przedmiot umowy:</Table.Cell>
                            <Table.Cell>{umowa.przedmiotUmowy}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Termin realizacji umowy:</Table.Cell>
                            <Table.Cell>od dnia {umowa.dataPoczatkowa} do {umowa.dataKoncowa}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Jednostka odpowiedzialna za realizację umowy:</Table.Cell>
                            <Table.Cell>{this.state.umowa.jednostka}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Osoba odpowiedzialna za realizację umowy:</Table.Cell>
                            <Table.Cell>{this.state.umowa.osoba}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Status:</Table.Cell>
                            <Table.Cell>{this.state.umowa.statusUmowy}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Tagi:</Table.Cell>
                            <Table.Cell>{umowa.tagi}</Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>

                <Button onClick={this.handleBack} primary>Powrót</Button>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectDetail && (
                <Redirect push to={'/umowy/details/' + this.state.idUmowy}/>
            )}
        </ErrorBoundary>
    }
}

import React, {Component} from 'react';
import JednostkaOdpowiedzialnaForm from "./JednostkaOdpowiedzialnaForm";


export default class JednostkaOdpowiedzialnaEdit extends Component {

    render() {
        return <div>
            <JednostkaOdpowiedzialnaForm mode={'edit'} id={this.props.match.params.id}/>
        </div>

    }
}

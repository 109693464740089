import React, {Component} from 'react'
import {Segment, Dimmer, Loader} from 'semantic-ui-react'


export default class Spinner extends Component {

    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }

    render() {
        return <Segment>
            <Dimmer ref={this.wrapper} active />
                <Loader>{'Wczytuję dane...'}</Loader>
        </Segment>
    }
}


import React, {Component} from 'react';
import {Message, Button, Icon, Header, Segment, Popup, Radio} from 'semantic-ui-react';
import {Link, Redirect} from 'react-router-dom';
import ErrorBoundary from '../../common/ErrorBoundary';
import Confirm from '../../common/Confirm';
import Spinner from '../../common/Spinner';
import {DefaultApi} from "../../api";
import Error from '../../common/Error';

class TableRow extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onDomyslnyChange(this.props.data.id);
    }

    render() {
        return <tr>
            <td width="500px">{this.props.data.nazwaRodzajuUmowy}</td>
            <td><Radio onChange={this.handleChange} checked={this.props.radiovalue === this.props.data.id} value={this.props.data.id} name='radioGroup'/>
            </td>
            <td width="120px">
                <Popup
                    trigger={<Icon link name='edit' onClick={() => this.props.edit(this.props.data.id)}/>}
                    content='Edycja wybranego rodzaju umowy'
                    size='tiny'
                    inverted
                />
                <Popup
                    trigger={<Icon link name='trash' onClick={() => this.props.remove(this.props.data.id)}/>}
                    content='Usunięcie wybranego rodzaju umowy'
                    size='tiny'
                    inverted
                />
            </td>
        </tr>
    }
}


export default class RodzajUmowyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idRodzajuUmowy: '',
            isLoading: true,
            rodzajeUmow: [],
            domyslny: false,
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            radiovalue: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getRodzajeUmow();
    }

    getRodzajeUmow = async () => {
        const api = new DefaultApi({});
        api.listOfRodzajUmowy().then((response) => {
            this.setState({rodzajeUmow: response.data, isLoading: false});
            const found = this.state.rodzajeUmow.find(rodzajumowy => rodzajumowy.domyslny === true);
            if (typeof found !== 'undefined')
                this.setState({radiovalue: found.id});
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    listRodzajeUmow = () => {
        return (
            <Segment raised className={`panel`}>

                <Header as='h3'>
                    <Icon name='configure'/>
                    <Header.Content>
                        Lista rodzajów umów
                        <Header.Subheader>Zarządzanie rodzajami umów</Header.Subheader>
                    </Header.Content>
                </Header>

                <Link to={'/umowy/rodzajumowy/dodaj'}><Button primary>Dodaj rodzaj umowy</Button></Link>
                <table className="ui table">
                    <thead>
                    <tr>
                        <th>Nazwa rodzaju umowy</th>
                        <th>Domyślny</th>
                        <th>Opcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.rodzajeUmow.sort((a, b) => {
                        if (a.nazwaRodzajuUmowy != null)
                            return a.nazwaRodzajuUmowy.localeCompare(b.nazwaRodzajuUmowy)
                        else if (b.nazwaRodzajuUmowy != null)
                            return b.nazwaRodzajuUmowy.localeCompare(a.nazwaRodzajuUmowy)
                        return null
                    }).map((aktyprawne, i) => <TableRow key={i} data={aktyprawne} radiovalue={this.state.radiovalue}
                                                        onDomyslnyChange={this.onDomyslnyChange}
                                                        remove={this.deleteRodzajUmowy}
                                                        edit={this.editRodzajUmowy}
                        />
                    )}
                    </tbody>
                </table>
                <Segment>
                    <Header as='h4' color='grey'>Legenda</Header>
                    <Icon link name='edit'/> - edycja wybranego rodzaju umowy<br/>
                    <Icon link name='trash'/> - usunięcie wybranego rodzaju umowy
                </Segment>
            </Segment>
        )
    };

    onDomyslnyChange = async (id) => {

        this.setState({radiovalue: id});

        const api = new DefaultApi({});
        await api.listOfRodzajUmowy().then((response) => this.setState({rodzajeUmow: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        /* ustawiamy flagę FALSE dla dokumentu(ów), który dotychczas miał ustawioną TRUE */
        const foundRodzajeUmowy = this.state.rodzajeUmow.filter(rodzajumowy => rodzajumowy.domyslny === true);

        for (const rodzajumowy of foundRodzajeUmowy) {
            if (rodzajumowy.id !== id) {
                await api.editRodzajUmowy(rodzajumowy.id, {
                    nazwaRodzajuUmowy: rodzajumowy.nazwaRodzajuUmowy,
                    domyslny: false,
                }).catch((error) => {
                    Promise.resolve(error.response.data.msg).then((response) => {
                        this.setState({errorAPI: response, confirmError: true})
                    });
                });
            }
        }

        /* ustawiamy flagę TRUE dla dokumentu zaznaczonego */
        const foundRodzajUmowy = this.state.rodzajeUmow.filter(rodzajumowy => rodzajumowy.id === id);
        await api.editRodzajUmowy(id, {
            id: '',
            nazwaRodzajuUmowy: foundRodzajUmowy[0].nazwaRodzajuUmowy,
            domyslny: true
        }).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    showRodzajUmowy = () => {
        if (this.state.rodzajeUmow.length > 0) {
            return this.listRodzajeUmow()
        } else {
            if (this.state.isLoading) {
                return <Spinner/>
            } else {
                return <div>
                    <Message
                        color='red'
                        header='Nie ma zdefiniowanych rodzajów umów'
                        list={[
                            'Musisz zdefiniować rodzaj umowy. W tym celu kliknij przycisk "Dodaj rodzaj umowy"',
                        ]}
                    />
                    <Link to={'/umowy/rodzajumowy/dodaj'}><Button primary>Dodaj rodzaj umowy</Button></Link>
                </div>
            }
        }
    };


    handleConfirm = () => {
        const api = new DefaultApi({});
        api.deleteRodzajUmowy(this.state.idRodzajuUmowy).then(() => this.getRodzajeUmow()).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({confirmDelete: false});
    };

    handleCancel = () => this.setState({confirmDelete: false});

    deleteRodzajUmowy = (id) => {
        this.setState({confirmDelete: true, idRodzajuUmowy: id});
    };

    editRodzajUmowy = (id) => {
        this.setState({redirectEdit: true, idRodzajuUmowy: id})
    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectEdit} = this.state;
        const {errorAPI} = this.state;

        return <ErrorBoundary>
            {this.showRodzajUmowy()}

            <Confirm mode={this.state.confirmDelete} confirm={this.handleConfirm} cancel={this.handleCancel}
                     title={'Usunięcie rodzaju umowy'}
                     question={'Czy na pewno chcesz usunąć ten rodzaj umowy?'}/>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectEdit && (
                <Redirect push to={'/umowy/rodzajumowy/edytuj/' + this.state.idRodzajuUmowy}/>
            )}
        </ErrorBoundary>
    }
}

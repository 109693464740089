import React, {Component} from 'react';
import AktyPrawneForm from './AktyPrawneForm';


export default class AktyPrawneEdit extends Component {

    render() {
        return <div>
            <AktyPrawneForm mode={'edit'} id={this.props.match.params.id}/>
        </div>

    }
}

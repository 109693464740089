import React, {Component} from 'react';
import {Icon, Label, Segment, Grid, Form, Input, GridRow, GridColumn, Dropdown} from 'semantic-ui-react';
import {Link, Redirect} from 'react-router-dom';
import ErrorBoundary from '../common/ErrorBoundary';
import {DefaultApi} from "../api/index";
import {ZalacznikAktuPrawnegoRodzajPlikuEnum} from "../api/ts/model";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import FileList from "../common/FileList";
import Pagination from "react-js-pagination";
import download from "downloadjs";
import Axios from "../common/Axios";
import Error from '../common/Error';

class TableRow extends Component {

    downloadFile = async (e, id, filename) => {
        e.preventDefault();
        await Axios({
            method: 'get',
            url: '/aktyprawne/pliki/' + id,
            responseType: 'blob'
        }).then((response) =>
            download(response.data, filename)
        );
    };

    render() {
        const nazwaStatusu = this.props.data.nazwaStatusuAktu;
        let icon, plikPDF = [], zalaczniki = [];

        if (nazwaStatusu === "Obowiązujący") {
            icon = <div><Icon name='check circle' color='olive'/>{this.props.data.nazwaStatusuAktu}</div>
        } else if (nazwaStatusu === "Wycofany") {
            icon = <div><Icon name='minus circle' color='red'/>{this.props.data.nazwaStatusuAktu}</div>
        } else {
            icon = <div><Icon name='dot circle' color='grey'/>{this.props.data.nazwaStatusuAktu}</div>
        }

        if (this.props.data.zalaczniki != null) {
            plikPDF = this.props.data.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.PDF);
            zalaczniki = this.props.data.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.ZALACZNIK);
        }


        return <tr>
            <td><Link to="#" onClick={() => this.props.details(this.props.data.id)}>{this.props.data.nazwaRodzajuAktu} nr {this.props.data.numerRocznikAktu} w
                sprawie {this.props.data.przedmiotAktu} </Link>
                <br/>
                <br/>
                {plikPDF.length > 0 && "Plik aktu prawnego: "}
                {plikPDF.length > 0 &&
                plikPDF.map((zalaczniki, i) => <FileList key={i}
                                                         data={zalaczniki}
                                                         load={this.downloadFile}/>)
                }
                <br/>
                {zalaczniki.length > 0 && "Załączniki: "}
                {zalaczniki.length > 0 &&
                zalaczniki.map((zalaczniki, i) => <FileList key={i}
                                                            data={zalaczniki}
                                                            load={this.downloadFile}/>)
                }
            </td>
            <td>{this.props.data.dataWydania}</td>
            <td>{icon}</td>
        </tr>
    }
}


class AktyPrawneTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idAktuPrawnego: '',
            isLoading: true,
            aktyPrawne: [],
            statusAktu: '',
            rodzajAktu: '',
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            slowaKluczowe: '',
            zalacznikiPDF: [],
            zalacznikiWord: [],
            zalaczniki: [],
            dataError: '',
            activePage: 1,
            liczbaAktow: 0,
            sortBy: this.props.sortBy,
            direction: this.props.direction,
            page: this.props.page,
            errorAPI: '',
            confirmError: false
        }
    }

    componentDidMount() {
        this.getAktyPrawne();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.sortBy !== prevProps.sortBy || this.props.direction !== prevProps.direction || this.props.page !== prevProps.page
            || this.props.searchWord !== prevProps.searchWord || this.props.rodzajAktu !== prevProps.rodzajAktu || this.props.statusAktu !== prevProps.statusAktu
            || this.props.dataWydaniaOd !== prevProps.dataWydaniaOd || this.props.dataWydaniaDo !== prevProps.dataWydaniaDo) {
            if (this.props.searchWord !== '' || this.props.rodzajAktu !== '' || this.props.statusAktu !== ''
                || this.props.dataWydaniaOd !== '' || this.props.dataWydaniaDo !== '') {
                this.wyszukajAktyPrawneUser();
            } else {
                this.getAktyPrawne();
            }

            if (this.props.searchWord === '' && this.props.rodzajAktu === '' && this.props.statusAktu === ''
                && !this.props.dataWydaniaOd && !this.props.dataWydaniaDo) {
                this.getAktyPrawne();
            }
        }
    }

    detailsAktPrawny = (id) => {
        this.setState({redirectDetail: true, idAktuPrawnego: id});
    };

    handlePageChange = async (pageNumber) => {
        await this.setState({activePage: pageNumber});
        await this.getAktyPrawne();
    }

    wyszukajAktyPrawneUser = async () => {
        const api = new DefaultApi({});
        await api.wyszukiwanieAktowPrawnychUser({
            nazwaNrTrescPrzedmiot: this.props.searchWord,
            dataWydaniaOd: this.props.dataWydaniaOd,
            dataWydaniaDo: this.props.dataWydaniaDo,
            statusAktu: this.props.statusAktu,
            rodzajAktu: this.props.rodzajAktu,
            page: this.state.activePage,
            limit: process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE,
            sort: this.props.sortBy,
            asc: this.props.direction
        }).then((response) => this.setState({
            aktyPrawne: response.data,
            liczbaAktow: response.data.length,
            isLoading: false
        })).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    getAktyPrawne() {
        const api = new DefaultApi({});
        api.listOfAktPrawnyEL(
            this.state.activePage,
            process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE,
            this.props.sortBy,
            this.props.direction
        ).then((response) => {
            this.setState({aktyPrawne: response.data.list, liczbaAktow: response.data.count, isLoading: false})
        }).catch((error) => {
            if (error.response === undefined) {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            }
        });
    }

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectDetail} = this.state;
        const maxNumber = process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE;
        const pageRange = process.env.REACT_APP_PAGE_RANGE;
        const {errorAPI} = this.state;

        return (
            <tbody>

            {this.state.aktyPrawne && this.state.aktyPrawne.length > 0 ? (
                this.state.aktyPrawne.map((aktyprawne, i) => <TableRow key={i} data={aktyprawne}
                                                                       details={this.detailsAktPrawny}
                />)) : <tr>
                <td colSpan="5"><strong>Nie znaleziono aktów prawnych</strong></td>
            </tr>
            }

            {(this.state.liczbaAktow / maxNumber) > 1 &&
            <tr>
                <td colSpan="3">
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={Number(maxNumber)}
                        totalItemsCount={this.state.liczbaAktow}
                        pageRangeDisplayed={Number(pageRange)}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </td>
            </tr>
            }

            <tr>
                <td colSpan="3">
                    {errorAPI && <Error mode={this.state.confirmError}
                                        confirm={this.handleError}
                                        title={'Wystąpił błąd!'}
                                        question={errorAPI}/>}

                    {redirectDetail && (
                        <Redirect push to={'/aktyprawne/details/' + this.state.idAktuPrawnego}/>
                    )}
                </td>
            </tr>
            </tbody>
        )
    }
}


export default class MainPage extends Component {

    constructor(props) {
        super(props);
        this.wrapper = React.createRef();

        this.state = {
            isLoading: true,
            aktyPrawne: [],
            statusAktu: '',
            rodzajAktu: '',
            statusy: [],
            hasError: false,
            redirectEdit: false,
            redirectList: false,
            redirectDetail: false,
            confirmDelete: false,
            searchWord: '',
            showSearchPanel: false,
            showDetailsPanel: false,
            zalacznikiPDF: [],
            zalacznikiWord: [],
            zalaczniki: [],
            rodzajeAktow: [],
            dataError: '',
            activePage: 1,
            liczbaAktow: 0,
            sortBy: '',
            direction: false,
            sortDir_nazwaNrTrescPrzedmiot: false,
            sortDir_przedmiotAktu: false,
            sortDir_dataWydania: false,
            sortDir_statusAktu: false
        };
    }

    componentDidMount() {
        this.getRodzajeAktow();
        this.getStatusy();
    }

    getRodzajeAktow = async () => {
        const api = new DefaultApi({});
        api.listOfRodzajAktuPrawnego().then((response) => this.setState({
            rodzajeAktow: response.data,
            isLoading: false
        })).catch((error) => {
            if (error)
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
        });
    };

    getStatusy = async () => {
        const api = new DefaultApi({});
        api.listOfStatusAktuPrawnego().then((response) => this.setState({statusy: response.data, isLoading: false})).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
    };

    handleChangeSortBy = (sortValue) => {
        this.setState({sortBy: sortValue});

        if (sortValue === "numerRocznikAktu") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_nazwaNrTrescPrzedmiot: !this.state.sortDir_nazwaNrTrescPrzedmiot})
            } else {
                this.setState({sortDir_nazwaNrTrescPrzedmiot: true})
            }

            this.setState({direction: this.state.sortDir_nazwaNrTrescPrzedmiot});
        }

        if (sortValue === "dataWydania") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_dataWydania: !this.state.sortDir_dataWydania})
            } else {
                this.setState({sortDir_dataWydania: true})
            }

            this.setState({direction: this.state.sortDir_dataWydania});
        }

        if (sortValue === "fkIdStatusAktu") {
            if (sortValue === this.state.sortBy) {
                this.setState({sortDir_statusAktu: !this.state.sortDir_statusAktu})
            } else {
                this.setState({sortDir_statusAktu: true})
            }

            this.setState({direction: this.state.sortDir_statusAktu});
        }
    }

    validateDate = () => {
        if (this.state.dataWydaniaOd && this.state.dataWydaniaDo) {
            if (this.state.dataWydaniaOd >= this.state.dataWydaniaDo) {
                this.setState({dataError: "Data końcowa nie może być wcześniejsza od daty początkowej"});
            } else {
                this.setState({dataError: ''});
            }
        }
    }

    onDataWydaniaOdChange = async (e, data) => {
        await this.setState({dataWydaniaOd: data.value});
        this.validateDate();
    };

    onDataWydaniaDoChange = async (e, data) => {
        await this.setState({dataWydaniaDo: data.value});
        this.validateDate();
    };

    onNazwaStatusuChange = async (e, {value}) => {
        await this.setState({statusAktu: value});
    };

    onNazwaRodzajuChange = async (e, {value}) => {
        await this.setState({rodzajAktu: value});
    };

    onSlowaKluczoweChange = async (e, data) => {
        await this.setState({searchWord: data.value});
    }

    render() {
        const maxNumber = process.env.REACT_APP_MAX_NUMBER_AKTYPRAWNE;
        const pageRange = process.env.REACT_APP_PAGE_RANGE;

        const rodzajeAktowPrawnych = this.state.rodzajeAktow.map((rodzaj) => {
            return {
                key: rodzaj.id,
                text: rodzaj.nazwaRodzajuAktu,
                value: rodzaj.nazwaRodzajuAktu
            }
        });
        const statusy = this.state.statusy.map((status) => {
            return {
                key: status.id,
                text: status.nazwaStatusu,
                value: status.nazwaStatusu
            }
        });

        const {statusAktu, rodzajAktu} = this.state

        return <ErrorBoundary>
            <div className={`panel`}>
                <h2>Wydane akty prawne</h2>
                <Form onSubmit={this.onFormSubmit}>
                    <Segment>
                        <Grid>
                            <GridRow columns={3}>
                                <GridColumn width={15}>
                                    <h3>Szukaj aktu prawnego:&nbsp;</h3>
                                    <Form.Field>
                                        <Input autoComplete="off" type="text"
                                               placeholder={'Wprowadź nazwę aktu prawnego lub jego numer'}
                                               id="searchUmowa" className="wyszukaj"
                                               icon='search'
                                               size='big'
                                               value={this.state.searchWord}
                                               onChange={this.onSlowaKluczoweChange}/>
                                    </Form.Field>
                                </GridColumn>
                            </GridRow>
                            <GridRow>
                                <GridColumn width={4}>
                                    <Form.Field>
                                        <label>Rodzaj aktu prawnego:</label>
                                        <Dropdown
                                            placeholder={'Wybierz rodzaj'}
                                            fluid
                                            noResultsMessage={"Brak danych"}
                                            selection
                                            clearable
                                            onChange={this.onNazwaRodzajuChange}
                                            options={rodzajeAktowPrawnych}
                                            value={rodzajAktu}
                                        />
                                    </Form.Field>
                                </GridColumn>
                                <GridColumn width={3}>
                                    <Form.Field>
                                        <label>Status:</label>
                                        <Dropdown
                                            placeholder='Wybierz status'
                                            fluid
                                            selection
                                            clearable
                                            onChange={this.onNazwaStatusuChange}
                                            options={statusy}
                                            value={statusAktu}
                                            className='pokaz'
                                        />
                                    </Form.Field>
                                </GridColumn>
                                <GridColumn width={9}>
                                    <Form.Field>
                                        <label>Data wydania aktu prawnego:</label>
                                        <div>
                                            od:&nbsp;&nbsp;<SemanticDatepicker onChange={this.onDataWydaniaOdChange} format='DD-MM-YYYY'
                                                                               locale="en-US" clearable={false}
                                                                               value={this.state.dataWydaniaOd}/>&nbsp;&nbsp;
                                            do:&nbsp;&nbsp; <SemanticDatepicker onChange={this.onDataWydaniaDoChange} format='DD-MM-YYYY'
                                                                                locale="en-US" clearable={false}
                                                                                value={this.state.dataWydaniaDo}/>
                                        </div>
                                        {this.state.dataError ? (
                                            <Label color='red' pointing>
                                                {this.state.dataError}
                                            </Label>
                                        ) : null}
                                    </Form.Field>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Segment>
                    <br/>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <table className={`tabela`}>
                                    <thead>
                                    <tr>
                                        <th width="700px">
                                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("numerRocznikAktu")}>Numer i rocznik</button>
                                            {this.state.sortBy === "numerRocznikAktu" &&
                                            (this.state.sortDir_nazwaNrTrescPrzedmiot ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                                        </th>
                                        <th>
                                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("dataWydania")}>Data wydania</button>
                                            {this.state.sortBy === "dataWydania" &&
                                            (this.state.sortDir_dataWydania ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                                        </th>
                                        <th>
                                            <button className="buttonSort" onClick={() => this.handleChangeSortBy("fkIdStatusAktu")}>Status</button>
                                            {this.state.sortBy === "fkIdStatusAktu" &&
                                            (this.state.sortDir_statusAktu ? <Icon name='chevron down'/> : <Icon link name='chevron up'/>)}
                                        </th>
                                    </tr>
                                    </thead>
                                    <AktyPrawneTable sortBy={this.state.sortBy} direction={this.state.direction} page={this.state.activePage}
                                                     searchWord={this.state.searchWord} dataWydaniaOd={this.state.dataWydaniaOd} dataWydaniaDo={this.state.dataWydaniaDo}
                                                     rodzajAktu={this.state.rodzajAktu} statusAktu={this.state.statusAktu}
                                    />
                                </table>
                                {(this.state.liczbaAktow / maxNumber) > 1 &&
                                <tr>
                                    <td colSpan="5">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={Number(maxNumber)}
                                            totalItemsCount={this.state.liczbaAktow}
                                            pageRangeDisplayed={Number(pageRange)}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </td>
                                </tr>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        </ErrorBoundary>
    }
}
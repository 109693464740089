import React from 'react';
import ReactDOM from 'react-dom';
import App from 'common/App';
import * as serviceWorker from './common/serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from './common/Auth0ProviderWithHistory';


ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <App />
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

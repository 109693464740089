import React, {Component} from "react";
import {Button, Modal} from "semantic-ui-react";
import StatusAktuPrawnegoAdd from "./StatusAktuPrawnegoAdd";


export default class StatusAktuPrawnegoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    openModal = () => {
        this.setState({open: true});
    };

    closeModal = () => {
        this.setState({open: false});
        this.props.change();
    };

    render() {
        return <Modal
            onClose={this.closeModal}
            onOpen={this.openModal}
            open={this.state.open}
            trigger={<Button size='mini' color='blue' type='button'>Dodaj status aktu prawnego...</Button>}>
            <StatusAktuPrawnegoAdd close={this.closeModal} modal={true}/>
        </Modal>
    }
}

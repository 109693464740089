import React, {Component} from 'react';
import {Redirect} from 'react-router'
import {Button, Divider, Header, Icon, Segment, Table} from 'semantic-ui-react';
import {DefaultApi} from "../api/index";
import {ZalacznikAktuPrawnegoRodzajPlikuEnum} from "../api/ts/model";
import FileList from "../common/FileList";
import ItemList from "../common/ItemList";
import download from "downloadjs";
import Axios from "../common/Axios";
import ErrorBoundary from "../common/ErrorBoundary";
import Error from '../common/Error';

export default class AktyPrawneDetail extends Component {

    constructor(props) {
        super(props);
        this.downloadFile = this.downloadFile.bind(this);
        this.state = {
            aktPrawny: [],
            zalacznikiPDF: [],
            zalacznikiWord: [],
            zalaczniki: [],
            statusy: [],
            rodzaje: [],
            jednostki: [],
            nazwaStatusu: [],
            rodzajAktuPrawnego: [],
            jednostkaInicjujaca: [],
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        this.getAktyPrawne(this.props.match.params.id);
    }

    downloadFile = async (e, id, filename) => {
        e.preventDefault();
        await Axios({
            method: 'get',
            url: '/aktyprawne/pliki/' + id,
            responseType: 'blob'
        }).then((response) =>
            download(response.data, filename)
        );
    };

    detailsAktPrawny = (id, e) => {
        e.preventDefault();
        this.setState({redirectDetail: true, idAktuPrawnego: id});
    };

    getAktyPrawne = async (id) => {
        const api = new DefaultApi({});
        const response = await api.aktPrawnyDetails(id).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });
        this.setState({aktPrawny: response.data});

        await api.listOfZalacznikAktuPrawnego(id).then((response) => this.setState({
            zalaczniki: response.data,
            isLoading: false
        })).catch((error) => {
            Promise.resolve(error.response.data.msg).then((response) => {
                this.setState({errorAPI: response, confirmError: true})
            });
        });

        this.setState({zalacznikiPDF: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.PDF)});
        this.setState({zalacznikiWord: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.WORD)});
        this.setState({zalaczniki: this.state.zalaczniki.filter(plik => plik.rodzajPliku === ZalacznikAktuPrawnegoRodzajPlikuEnum.ZALACZNIK)});
    };

    handleBack = () => {
        this.props.history.goBack();
    }

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectDetail} = this.state;
        const {errorAPI} = this.state;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Header as='h2' icon textAlign='center'>
                    <Icon name='clipboard outline' circular/>
                    <Header.Content>Szczegóły aktu prawnego {this.state.aktPrawny.numerRocznikAktu}</Header.Content>
                </Header>

                <p style={{textAlign: 'center'}}>
                    Poniżej wyświetlone są szczegóły wskazanego aktu prawnego...
                </p>

                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='bar chart'/>
                        Dane aktu prawnego
                    </Header>
                </Divider>

                <Table definition>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={7}>Rodzaj aktu prawnego:</Table.Cell>
                            <Table.Cell>{this.state.aktPrawny.rodzajAktu}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Numer i rocznik:</Table.Cell>
                            <Table.Cell>{this.state.aktPrawny.numerRocznikAktu}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Data wydania aktu prawnego:</Table.Cell>
                            <Table.Cell>{this.state.aktPrawny.dataWydania}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Przedmiot aktu prawnego:</Table.Cell>
                            <Table.Cell>{this.state.aktPrawny.przedmiotAktu}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Plik aktu prawnego w formacie PDF:</Table.Cell>
                            <Table.Cell>
                                {this.state.zalacznikiPDF.map((zalaczniki, i) => <FileList key={i}
                                                                                           data={zalaczniki}
                                                                                           load={this.downloadFile}/>)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Plik aktu prawnego DOSTĘPNY CYFROWO:</Table.Cell>
                            <Table.Cell>
                                {this.state.zalacznikiWord.map((zalaczniki, i) => <FileList key={i}
                                                                                            data={zalaczniki}
                                                                                            load={this.downloadFile}/>)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Załączniki do aktu prawnego:</Table.Cell>
                            <Table.Cell>
                                {this.state.zalaczniki.map((zalaczniki, i) => <FileList key={i}
                                                                                        data={zalaczniki}
                                                                                        load={this.downloadFile}/>)}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Zmienia akty prawne:</Table.Cell>
                            <Table.Cell>
                                {this.state.aktPrawny.zmieniaAktyPrawne && this.state.aktPrawny.zmieniaAktyPrawne.length > 0 &&
                                this.state.aktPrawny.zmieniaAktyPrawne.map((zmienia, i) =>
                                    <ItemList key={i}
                                              aktyPrawneTab={zmienia}
                                              details={this.detailsAktPrawny}
                                              id={this.state.aktPrawny.id}
                                    />
                                )}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Uchyla akty prawne:</Table.Cell>
                            <Table.Cell>
                                {this.state.aktPrawny.uchylaAktyPrawne && this.state.aktPrawny.uchylaAktyPrawne.length > 0 &&
                                this.state.aktPrawny.uchylaAktyPrawne.map((uchyla, i) =>
                                    <ItemList key={i}
                                              aktyPrawneTab={uchyla}
                                              details={this.detailsAktPrawny}
                                              id={this.state.aktPrawny.id}
                                    />
                                )}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Status:</Table.Cell>
                            <Table.Cell>{this.state.aktPrawny.statusAktu}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Jednostka inicjująca:</Table.Cell>
                            <Table.Cell>{this.state.aktPrawny.jednostkaInicjujaca}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Tagi:</Table.Cell>
                            <Table.Cell>{this.state.aktPrawny.tagi}</Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>
                <Button onClick={this.handleBack} primary>Powrót</Button>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectDetail && (
                <Redirect push to={'/aktyprawne/details/' + this.state.idAktuPrawnego}/>
            )}
        </ErrorBoundary>
    }
}

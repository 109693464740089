import React, {Component} from 'react';
import {DefaultApi} from "../../api";
import ErrorBoundary from "../../common/ErrorBoundary";
import {Button, Form, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link, Redirect} from "react-router-dom";
import Error from '../../common/Error';

export default class JednostkaInicjujacaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nazwaJednostkiInicjujacej: '',
            nazwaJednostkiInicjujacejError: '',
            domyslny: '',
            redirectSwitch: false,
            redirectModal: false,
            modeName: '',
            modeTitle: '',
            errorAPI: '',
            confirmError: false
        };
    }

    componentDidMount() {
        if (this.props.mode === "add")
            this.setState({modeName: 'Dodaj', modeTitle: "Dodawanie jednostki inicjującej"});
        else if (this.props.mode === "edit") {
            this.setState({modeName: 'Zapisz zmiany', modeTitle: "Edycja jednostki inicjującej"});
            this.getItem(this.props.id);
        }
    }

    onNazwaJednostkiInicjujacejChange = (event) => {
        this.setState({nazwaJednostkiInicjujacej: event.target.value});
    };

    getItem = async (id) => {
        try {
            const api = new DefaultApi({});
            const response = await api.listOfJednostkaInicjujaca(id).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
            const jednostkaInicjujaca = response.data.find((a) => a.id === id);

            this.setState({
                IdJednostkiInicjujacej: jednostkaInicjujaca.valueOf().id,
                nazwaJednostkiInicjujacej: jednostkaInicjujaca.valueOf().nazwaJednostkiInicjujacej,
                domyslny: jednostkaInicjujaca.valueOf().domyslny
            });
        } catch (error) {
            console.error(error);
        }
    };

    validateForm = () => {
        let nazwaJednostkiInicjujacejError = "";
        let isEmptyText = "Nie wypełniono tego pola";

        if (!this.state.nazwaJednostkiInicjujacej) {
            nazwaJednostkiInicjujacejError = isEmptyText;
        }

        if (nazwaJednostkiInicjujacejError) {
            this.setState({nazwaJednostkiInicjujacejError});
            return false;
        }
        return true;
    };

    saveForm = async () => {
        const api = new DefaultApi({});
        if (this.props.mode === "add") {
            await api.addJednostkaInicjujaca({
                id: '',
                nazwaJednostkiInicjujacej: this.state.nazwaJednostkiInicjujacej,
                domyslny: false,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else if (this.props.mode === "edit") {
            await api.editJednostkaInicjujaca(this.props.id, {
                id: '',
                nazwaJednostkiInicjujacej: this.state.nazwaJednostkiInicjujacej,
                domyslny: this.state.domyslny,
            }).catch((error) => {
                Promise.resolve(error.response.data.msg).then((response) => {
                    this.setState({errorAPI: response, confirmError: true})
                });
            });
        } else {
            console.log("Nie wybrano trybu pracy")
        }
    };

    onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = this.validateForm();

        if (isValid) {
            if (this.props.modal) {
                this.saveForm().then(() => this.setState({redirectModal: true}));
            } else {
                this.saveForm().then(() => this.setState({redirectSwitch: true}));
            }
            this.setState({nazwaJednostkiInicjujacejError: ''});
        } else {
            console.log("Niewypełniony formularz");
        }
    };

    handleError = () => this.setState({confirmError: false});

    render() {
        const {redirectSwitch, redirectModal} = this.state;
        const {errorAPI} = this.state;
        const {modal} = this.props;

        return <ErrorBoundary>
            <Segment raised className={`panel`}>
                <Form onSubmit={this.onFormSubmit}>

                    <Header as='h3'>
                        <Icon name='clipboard outline'/>
                        <Header.Content>
                            {this.state.modeTitle}
                            <Header.Subheader>Proszę wprowadzić dane jednostki inicjującej</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form.Field>
                        <label>Nazwa jednostki inicjującej:*</label>
                        <input placeholder='Podaj nazwę jednostki inicjującej' autoComplete="off" type="text" id="address"
                               value={this.state.nazwaJednostkiInicjujacej}
                               onChange={this.onNazwaJednostkiInicjujacejChange}/>
                        {this.state.nazwaJednostkiInicjujacejError ? (
                            <Label color='red' pointing>
                                {this.state.nazwaJednostkiInicjujacejError}
                            </Label>
                        ) : null}
                    </Form.Field>

                    <Header as='h4' color='grey'>* - pole wymagane</Header>
                    <Button primary>{this.state.modeName}</Button>
                    {modal ?
                        <Button basic onClick={this.props.close}>Anuluj</Button>
                        :
                        <Link to={'/aktyprawne/jednostkainicjujaca'}><Button basic>Anuluj</Button></Link>
                    }

                </Form>
            </Segment>

            {errorAPI && <Error mode={this.state.confirmError}
                                confirm={this.handleError}
                                title={'Wystąpił błąd!'}
                                question={errorAPI}/>}

            {redirectSwitch && (
                <Redirect push to={'/aktyprawne/jednostkainicjujaca'}/>
            )}
            {redirectModal && (
                this.props.close()
            )}
        </ErrorBoundary>
    }
}
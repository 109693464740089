import React from 'react';
import 'semantic-ui-css/semantic.min.css';


const Contact = () => {

    return <div>
        <h2>Rejestr Aktów Prawnych i Umów</h2>
        <p>Uniwersytet Przyrodniczno-Humanistyczny w Siedlcach</p>
        <address>ul. Konarskiego 2<br/>08-110 Siedlce<br/><strong>tel.: </strong>25 643-19-48<br/><strong>e-mail:</strong> <a
            href="mailto:doz@uph.edu.pl">doz@uph.edu.pl</a><br/>www.rejestr.uph.edu.pl
        </address>
    </div>
}

export default Contact;
// tslint:disable
/**
 * RAPU - Repozytorium Aktów Prawnych i Umów
 * Specyfikacja API dla RAPU
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Załącznik umowy
 * @export
 * @interface ZalacznikUmowy
 */
export interface ZalacznikUmowy {
    /**
     * 
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    id?: string;
    /**
     * ścieżka do miejsca przechowywania pliku, nazwa pliku to jego id
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    sciezka?: string;
    /**
     * 
     * @type {number}
     * @memberof ZalacznikUmowy
     */
    nrZalacznika?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    dataDodania?: string;
    /**
     * 
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    fkIdUmowy?: string;
    /**
     * nazwa pliku dla użytkownika - na podstawie numeru i rodzaju dokumentu - możliwe do nadpisania przez użytkownika
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    nazwaPliku?: string;
    /**
     * 
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    uwagi?: string | null;
    /**
     * Określa rolę jaką plik odgrywa w systemie, czy zawiera treść głównego dokumentu, czy załącznika: * \'PDF\' główny skan dokumentu * \'WORD\' zaimportowany do systemu plik w formacie word () * \'ZALACZNIK\' plik załącznika do umowy 
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    rodzajPliku?: ZalacznikUmowyRodzajPlikuEnum;
    /**
     * Mime-type pliku - co to jest za format
     * @type {string}
     * @memberof ZalacznikUmowy
     */
    mime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ZalacznikUmowyRodzajPlikuEnum {
    PDF = 'PDF',
    WORD = 'WORD',
    ZALACZNIK = 'ZALACZNIK'
}




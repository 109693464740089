import React, {Component} from 'react';
import WykonawcaForm from "./WykonawcaForm";


export default class WykonawcaAdd extends Component {

    render() {
        return <div>
            <WykonawcaForm mode={'add'} modal={this.props.modal} close={this.props.close}/>
        </div>
    }
}

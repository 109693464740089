// tslint:disable
/**
 * RAPU - Repozytorium Aktów Prawnych i Umów
 * Specyfikacja API dla RAPU
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Rola użytkownika w systemie
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    roleName?: RoleRoleNameEnum;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    roleDescription?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RoleRoleNameEnum {
    ADMIN = 'ADMIN',
    SYSTEM = 'SYSTEM',
    USERG2 = 'USER-G2',
    USERMG2 = 'USERM-G2',
    USERG3 = 'USER-G3',
    USERMG3 = 'USERM-G3'
}




import React, {Component} from 'react';
import WykonawcaForm from "./WykonawcaForm";


export default class WykonawcaEdit extends Component {

    render() {
        return <div>
            <WykonawcaForm mode={'edit'} id={this.props.match.params.id}/>
        </div>

    }
}

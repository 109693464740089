// tslint:disable
/**
 * RAPU - Repozytorium Aktów Prawnych i Umów
 * Specyfikacja API dla RAPU
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../base';

import { AktPrawny } from '../../ts/model';
import { AktPrawnyDetails } from '../../ts/model';
import { AktPrawnyEL } from '../../ts/model';
import { AktPrawnySearchResponse } from '../../ts/model';
import { AktPrawnyUser } from '../../ts/model';
import { AktPrawnyZaawansowane } from '../../ts/model';
import { InlineObject } from '../../ts/model';
import { Jednostka } from '../../ts/model';
import { JednostkaInicjujaca } from '../../ts/model';
import { Log } from '../../ts/model';
import { Osoba } from '../../ts/model';
import { ResponseErrWithMsg } from '../../ts/model';
import { ResponseOkWithId } from '../../ts/model';
import { RodzajAktuPrawnego } from '../../ts/model';
import { RodzajPodmiotu } from '../../ts/model';
import { RodzajUmowy } from '../../ts/model';
import { Role } from '../../ts/model';
import { StatusAktuPrawnego } from '../../ts/model';
import { StatusUmowy } from '../../ts/model';
import { Umowa } from '../../ts/model';
import { UmowaDetails } from '../../ts/model';
import { UmowaEL } from '../../ts/model';
import { UmowaSearchResponse } from '../../ts/model';
import { UmowaZaawansowane } from '../../ts/model';
import { User } from '../../ts/model';
import { Waluta } from '../../ts/model';
import { Wykonawca } from '../../ts/model';
import { ZalacznikAktuPrawnego } from '../../ts/model';
import { ZalacznikUmowy } from '../../ts/model';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AktPrawny} [aktPrawny] Nowy obiekt AktPrawny
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAktPrawny(aktPrawny?: AktPrawny, options: any = {}): RequestArgs {
            const localVarPath = `/aktyprawne`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof aktPrawny !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(aktPrawny !== undefined ? aktPrawny : {}) : (aktPrawny || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tworzy kopię umowy jako bazę dla aneksu. Zwraca id aneksu
         * @param {string} idUmowy Id umowy do której dodajemy aneks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAneksUmowa(idUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling addAneksUmowa.');
            }
            const localVarPath = `/umowy/dodajAneks/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tworzy pusty akt prawny, zapisuje go w bazie i zwraca jego id. Dalsze etapy wypełniania pól aktu prawnego powinny być realizowane za pomocą edycji.
         * @summary Tworzy nowy, pusty akt prawny i zwraca jedo id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmptyAktPrawny(options: any = {}): RequestArgs {
            const localVarPath = `/aktyprawne/nowy`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tworzy nową, pustą umowę, zapisuje ją w bazie i zwraca jej id. Dalsze etapy wypełniania pól umowy powinny być realizowane za pomocą edycji.
         * @summary Tworzy nową, pustą umowę i zwraca jej id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmptyUmowa(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/nowa`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowej jednostki organizacyjnej uczelni do słownika
         * @param {Jednostka} [jednostka] Nowy obiekt Jednostka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addJednostka(jednostka?: Jednostka, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/jednostki`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof jednostka !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(jednostka !== undefined ? jednostka : {}) : (jednostka || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowej jednostki inicjującej do słownika
         * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Nowy obiekt JednostkaInicjujaca
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addJednostkaInicjujaca(jednostkaInicjujaca?: JednostkaInicjujaca, options: any = {}): RequestArgs {
            const localVarPath = `/admin/jednostkiInicjujace`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof jednostkaInicjujaca !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(jednostkaInicjujaca !== undefined ? jednostkaInicjujaca : {}) : (jednostkaInicjujaca || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowej osoby odpowiedzialnej za realizację umowy
         * @param {Osoba} [osoba] Nowy obiekt Osoba
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsoba(osoba?: Osoba, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/osoby`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof osoba !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(osoba !== undefined ? osoba : {}) : (osoba || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary dodanie pliku do aktu prawnego do już istniejącego w bazie obiektu załącznika
         * @param {string} [idZalacznika] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlikDoAktuPrawnego(idZalacznika?: string, file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/aktyprawne/pliki`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


            if (idZalacznika !== undefined) { 
                localVarFormParams.append('idZalacznika', idZalacznika as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary dodanie pliku do umowy do już istniejącego w bazie obiektu załącznika
         * @param {string} [idZalacznika] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlikDoUmowy(idZalacznika?: string, file?: any, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/pliki`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


            if (idZalacznika !== undefined) { 
                localVarFormParams.append('idZalacznika', idZalacznika as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju aktu prawnego do słownika
         * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Nowy obiekt RodzajAktuPrawnego
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajAktuPrawnego(rodzajAktuPrawnego?: RodzajAktuPrawnego, options: any = {}): RequestArgs {
            const localVarPath = `/admin/rodzajeAktow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof rodzajAktuPrawnego !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rodzajAktuPrawnego !== undefined ? rodzajAktuPrawnego : {}) : (rodzajAktuPrawnego || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju podmiotów do słownika
         * @param {RodzajPodmiotu} [rodzajPodmiotu] Nowy obiekt RodzajPodmiotu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajPomiotu(rodzajPodmiotu?: RodzajPodmiotu, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/rodzajePodmiotow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof rodzajPodmiotu !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rodzajPodmiotu !== undefined ? rodzajPodmiotu : {}) : (rodzajPodmiotu || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju umowy do słownika
         * @param {RodzajUmowy} [rodzajUmowy] Nowy obiekt RodzajUmowy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajUmowy(rodzajUmowy?: RodzajUmowy, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/rodzajeUmow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof rodzajUmowy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rodzajUmowy !== undefined ? rodzajUmowy : {}) : (rodzajUmowy || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego statusu aktu prawnego do słownika
         * @param {StatusAktuPrawnego} [statusAktuPrawnego] Nowy obiekt StatusAktuPrawnego
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusAktuPrawnego(statusAktuPrawnego?: StatusAktuPrawnego, options: any = {}): RequestArgs {
            const localVarPath = `/admin/statusyAktow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof statusAktuPrawnego !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(statusAktuPrawnego !== undefined ? statusAktuPrawnego : {}) : (statusAktuPrawnego || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego statusu umowy do słownika
         * @param {StatusUmowy} [statusUmowy] Nowy obiekt StatusUmowy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusUmowy(statusUmowy?: StatusUmowy, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/statusyumow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof statusUmowy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(statusUmowy !== undefined ? statusUmowy : {}) : (statusUmowy || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dodaj nowego użytkownika
         * @param {User} [user] Nowy użytkownik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user?: User, options: any = {}): RequestArgs {
            const localVarPath = `/admin/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowej waluty do słownika
         * @param {Waluta} [waluta] Nowy obiekt Waluta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWaluta(waluta?: Waluta, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/waluty`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof waluta !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(waluta !== undefined ? waluta : {}) : (waluta || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego wykonawcy do słownika
         * @param {Wykonawca} [wykonawca] Nowy obiekt Wykonawca
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWykonawca(wykonawca?: Wykonawca, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/wykonawcy`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof wykonawca !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(wykonawca !== undefined ? wykonawca : {}) : (wykonawca || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego załącznika aktu prawnego
         * @param {ZalacznikAktuPrawnego} [zalacznikAktuPrawnego] Nowy obiekt - załącznik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addZalacznikAktuPrawnego(zalacznikAktuPrawnego?: ZalacznikAktuPrawnego, options: any = {}): RequestArgs {
            const localVarPath = `/aktyprawne/zalacznikiAkty`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof zalacznikAktuPrawnego !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(zalacznikAktuPrawnego !== undefined ? zalacznikAktuPrawnego : {}) : (zalacznikAktuPrawnego || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodanie nowego załącznika umowy
         * @param {ZalacznikUmowy} [zalacznikUmowy] Nowy obiekt - załącznik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addZalacznikUmowy(zalacznikUmowy?: ZalacznikUmowy, options: any = {}): RequestArgs {
            const localVarPath = `/umowy/zalaczniki`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof zalacznikUmowy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(zalacznikUmowy !== undefined ? zalacznikUmowy : {}) : (zalacznikUmowy || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły dokumentu o podanym id
         * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktPrawnyDetails(idAktuPrawnego: string, options: any = {}): RequestArgs {
            // verify required parameter 'idAktuPrawnego' is not null or undefined
            if (idAktuPrawnego === null || idAktuPrawnego === undefined) {
                throw new RequiredError('idAktuPrawnego','Required parameter idAktuPrawnego was null or undefined when calling aktPrawnyDetails.');
            }
            const localVarPath = `/aktyprawne/details/{idAktuPrawnego}`
                .replace(`{${"idAktuPrawnego"}}`, encodeURIComponent(String(idAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Usuń akt prawny
         * @param {string} idAktuPrawnego 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAktPrawny(idAktuPrawnego: string, options: any = {}): RequestArgs {
            // verify required parameter 'idAktuPrawnego' is not null or undefined
            if (idAktuPrawnego === null || idAktuPrawnego === undefined) {
                throw new RequiredError('idAktuPrawnego','Required parameter idAktuPrawnego was null or undefined when calling deleteAktPrawny.');
            }
            const localVarPath = `/aktyprawne/details/{idAktuPrawnego}`
                .replace(`{${"idAktuPrawnego"}}`, encodeURIComponent(String(idAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie jednostki
         * @param {string} idJednostki Identyfikator jednostki do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJednostka(idJednostki: string, options: any = {}): RequestArgs {
            // verify required parameter 'idJednostki' is not null or undefined
            if (idJednostki === null || idJednostki === undefined) {
                throw new RequiredError('idJednostki','Required parameter idJednostki was null or undefined when calling deleteJednostka.');
            }
            const localVarPath = `/umowy/jednostki/{idJednostki}`
                .replace(`{${"idJednostki"}}`, encodeURIComponent(String(idJednostki)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie jednostki inicjującej
         * @param {string} idJednostkiInicjujacej Identyfikator jednostki inicjującej do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJednostkaInicjujaca(idJednostkiInicjujacej: string, options: any = {}): RequestArgs {
            // verify required parameter 'idJednostkiInicjujacej' is not null or undefined
            if (idJednostkiInicjujacej === null || idJednostkiInicjujacej === undefined) {
                throw new RequiredError('idJednostkiInicjujacej','Required parameter idJednostkiInicjujacej was null or undefined when calling deleteJednostkaInicjujaca.');
            }
            const localVarPath = `/admin/jednostkiInicjujace/{idJednostkiInicjujacej}`
                .replace(`{${"idJednostkiInicjujacej"}}`, encodeURIComponent(String(idJednostkiInicjujacej)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Usuń osobę
         * @param {string} idOsoby 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOsoba(idOsoby: string, options: any = {}): RequestArgs {
            // verify required parameter 'idOsoby' is not null or undefined
            if (idOsoby === null || idOsoby === undefined) {
                throw new RequiredError('idOsoby','Required parameter idOsoby was null or undefined when calling deleteOsoba.');
            }
            const localVarPath = `/umowy/osoby/{idOsoby}`
                .replace(`{${"idOsoby"}}`, encodeURIComponent(String(idOsoby)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie rodzaju aktu prawnego
         * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuAktuPrawnego' is not null or undefined
            if (idRodzajuAktuPrawnego === null || idRodzajuAktuPrawnego === undefined) {
                throw new RequiredError('idRodzajuAktuPrawnego','Required parameter idRodzajuAktuPrawnego was null or undefined when calling deleteRodzajAktuPrawnego.');
            }
            const localVarPath = `/admin/rodzajeAktow/{idRodzajuAktuPrawnego}`
                .replace(`{${"idRodzajuAktuPrawnego"}}`, encodeURIComponent(String(idRodzajuAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie rodzaju podmiotu
         * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajPodmiotu(idRodzajuPodmiotu: string, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuPodmiotu' is not null or undefined
            if (idRodzajuPodmiotu === null || idRodzajuPodmiotu === undefined) {
                throw new RequiredError('idRodzajuPodmiotu','Required parameter idRodzajuPodmiotu was null or undefined when calling deleteRodzajPodmiotu.');
            }
            const localVarPath = `/umowy/rodzajePodmiotow/{idRodzajuPodmiotu}`
                .replace(`{${"idRodzajuPodmiotu"}}`, encodeURIComponent(String(idRodzajuPodmiotu)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie rodzaju umowy
         * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajUmowy(idRodzajuUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuUmowy' is not null or undefined
            if (idRodzajuUmowy === null || idRodzajuUmowy === undefined) {
                throw new RequiredError('idRodzajuUmowy','Required parameter idRodzajuUmowy was null or undefined when calling deleteRodzajUmowy.');
            }
            const localVarPath = `/umowy/rodzajeUmow/{idRodzajuUmowy}`
                .replace(`{${"idRodzajuUmowy"}}`, encodeURIComponent(String(idRodzajuUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie statusu aktu prawnego
         * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusAktuPrawnego(idStatusuAktuPrawnego: string, options: any = {}): RequestArgs {
            // verify required parameter 'idStatusuAktuPrawnego' is not null or undefined
            if (idStatusuAktuPrawnego === null || idStatusuAktuPrawnego === undefined) {
                throw new RequiredError('idStatusuAktuPrawnego','Required parameter idStatusuAktuPrawnego was null or undefined when calling deleteStatusAktuPrawnego.');
            }
            const localVarPath = `/admin/statusyAktow/{idStatusuAktuPrawnego}`
                .replace(`{${"idStatusuAktuPrawnego"}}`, encodeURIComponent(String(idStatusuAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie statusu umowy
         * @param {string} idStatusu Identyfikator statusu umowy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusUmowy(idStatusu: string, options: any = {}): RequestArgs {
            // verify required parameter 'idStatusu' is not null or undefined
            if (idStatusu === null || idStatusu === undefined) {
                throw new RequiredError('idStatusu','Required parameter idStatusu was null or undefined when calling deleteStatusUmowy.');
            }
            const localVarPath = `/umowy/statusyumow/{idStatusu}`
                .replace(`{${"idStatusu"}}`, encodeURIComponent(String(idStatusu)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Usuń umowę
         * @param {string} idUmowy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUmowa(idUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling deleteUmowa.');
            }
            const localVarPath = `/umowy/details/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie użytkownika
         * @param {string} idUzytkownika Identyfikator użytkownika usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(idUzytkownika: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUzytkownika' is not null or undefined
            if (idUzytkownika === null || idUzytkownika === undefined) {
                throw new RequiredError('idUzytkownika','Required parameter idUzytkownika was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/admin/users/{idUzytkownika}`
                .replace(`{${"idUzytkownika"}}`, encodeURIComponent(String(idUzytkownika)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie waluty
         * @param {string} idWaluty Identyfikator waluty do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaluta(idWaluty: string, options: any = {}): RequestArgs {
            // verify required parameter 'idWaluty' is not null or undefined
            if (idWaluty === null || idWaluty === undefined) {
                throw new RequiredError('idWaluty','Required parameter idWaluty was null or undefined when calling deleteWaluta.');
            }
            const localVarPath = `/umowy/waluty/{idWaluty}`
                .replace(`{${"idWaluty"}}`, encodeURIComponent(String(idWaluty)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie wykonawcy
         * @param {string} idWykonawcy Identyfikator wykonawcy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWykonawca(idWykonawcy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idWykonawcy' is not null or undefined
            if (idWykonawcy === null || idWykonawcy === undefined) {
                throw new RequiredError('idWykonawcy','Required parameter idWykonawcy was null or undefined when calling deleteWykonawca.');
            }
            const localVarPath = `/umowy/wykonawcy/{idWykonawcy}`
                .replace(`{${"idWykonawcy"}}`, encodeURIComponent(String(idWykonawcy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie załącznika
         * @param {string} idZalacznikiAkty Identyfikator załącznika do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZalacznikAktuPrawnego(idZalacznikiAkty: string, options: any = {}): RequestArgs {
            // verify required parameter 'idZalacznikiAkty' is not null or undefined
            if (idZalacznikiAkty === null || idZalacznikiAkty === undefined) {
                throw new RequiredError('idZalacznikiAkty','Required parameter idZalacznikiAkty was null or undefined when calling deleteZalacznikAktuPrawnego.');
            }
            const localVarPath = `/aktyprawne/zalacznikiAkty/{idZalacznikiAkty}`
                .replace(`{${"idZalacznikiAkty"}}`, encodeURIComponent(String(idZalacznikiAkty)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usunięcie załącznika do umowy
         * @param {string} idZalacznika Identyfikator załącznika do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZalacznikUmowy(idZalacznika: string, options: any = {}): RequestArgs {
            // verify required parameter 'idZalacznika' is not null or undefined
            if (idZalacznika === null || idZalacznika === undefined) {
                throw new RequiredError('idZalacznika','Required parameter idZalacznika was null or undefined when calling deleteZalacznikUmowy.');
            }
            const localVarPath = `/umowy/zalaczniki/{idZalacznika}`
                .replace(`{${"idZalacznika"}}`, encodeURIComponent(String(idZalacznika)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły jednostki organizacyjnej o podanym id
         * @param {string} idJednostki Id jednostki do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfJednostka(idJednostki: string, options: any = {}): RequestArgs {
            // verify required parameter 'idJednostki' is not null or undefined
            if (idJednostki === null || idJednostki === undefined) {
                throw new RequiredError('idJednostki','Required parameter idJednostki was null or undefined when calling detailsOfJednostka.');
            }
            const localVarPath = `/umowy/jednostki/{idJednostki}`
                .replace(`{${"idJednostki"}}`, encodeURIComponent(String(idJednostki)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły osoby o podanym id
         * @param {string} idOsoby Id osoby do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfOsoba(idOsoby: string, options: any = {}): RequestArgs {
            // verify required parameter 'idOsoby' is not null or undefined
            if (idOsoby === null || idOsoby === undefined) {
                throw new RequiredError('idOsoby','Required parameter idOsoby was null or undefined when calling detailsOfOsoba.');
            }
            const localVarPath = `/umowy/osoby/{idOsoby}`
                .replace(`{${"idOsoby"}}`, encodeURIComponent(String(idOsoby)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły rodzaju podmiotu o podanym id
         * @param {string} idRodzajuPodmiotu Id rodzaju podmiotu do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfRodzajPodmiotu(idRodzajuPodmiotu: string, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuPodmiotu' is not null or undefined
            if (idRodzajuPodmiotu === null || idRodzajuPodmiotu === undefined) {
                throw new RequiredError('idRodzajuPodmiotu','Required parameter idRodzajuPodmiotu was null or undefined when calling detailsOfRodzajPodmiotu.');
            }
            const localVarPath = `/umowy/rodzajePodmiotow/{idRodzajuPodmiotu}`
                .replace(`{${"idRodzajuPodmiotu"}}`, encodeURIComponent(String(idRodzajuPodmiotu)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły rodzaju umowy o podanym id
         * @param {string} idRodzajuUmowy Id rodzaju umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfRodzajUmowy(idRodzajuUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuUmowy' is not null or undefined
            if (idRodzajuUmowy === null || idRodzajuUmowy === undefined) {
                throw new RequiredError('idRodzajuUmowy','Required parameter idRodzajuUmowy was null or undefined when calling detailsOfRodzajUmowy.');
            }
            const localVarPath = `/umowy/rodzajeUmow/{idRodzajuUmowy}`
                .replace(`{${"idRodzajuUmowy"}}`, encodeURIComponent(String(idRodzajuUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły statusu o podanym id
         * @param {string} idStatusu Id statusu do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfStatusUmowy(idStatusu: string, options: any = {}): RequestArgs {
            // verify required parameter 'idStatusu' is not null or undefined
            if (idStatusu === null || idStatusu === undefined) {
                throw new RequiredError('idStatusu','Required parameter idStatusu was null or undefined when calling detailsOfStatusUmowy.');
            }
            const localVarPath = `/umowy/statusyumow/{idStatusu}`
                .replace(`{${"idStatusu"}}`, encodeURIComponent(String(idStatusu)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły waluty o podanym id
         * @param {string} idWaluty Id waluty do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfWaluta(idWaluty: string, options: any = {}): RequestArgs {
            // verify required parameter 'idWaluty' is not null or undefined
            if (idWaluty === null || idWaluty === undefined) {
                throw new RequiredError('idWaluty','Required parameter idWaluty was null or undefined when calling detailsOfWaluta.');
            }
            const localVarPath = `/umowy/waluty/{idWaluty}`
                .replace(`{${"idWaluty"}}`, encodeURIComponent(String(idWaluty)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły wykonawcy o podanym id
         * @param {string} idWykonawcy Id wykonawcy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfWykonawca(idWykonawcy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idWykonawcy' is not null or undefined
            if (idWykonawcy === null || idWykonawcy === undefined) {
                throw new RequiredError('idWykonawcy','Required parameter idWykonawcy was null or undefined when calling detailsOfWykonawca.');
            }
            const localVarPath = `/umowy/wykonawcy/{idWykonawcy}`
                .replace(`{${"idWykonawcy"}}`, encodeURIComponent(String(idWykonawcy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca obiekt załącznika
         * @param {string} idZalacznikiAkty Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfZalacznikAktuPrawnego(idZalacznikiAkty: string, options: any = {}): RequestArgs {
            // verify required parameter 'idZalacznikiAkty' is not null or undefined
            if (idZalacznikiAkty === null || idZalacznikiAkty === undefined) {
                throw new RequiredError('idZalacznikiAkty','Required parameter idZalacznikiAkty was null or undefined when calling detailsOfZalacznikAktuPrawnego.');
            }
            const localVarPath = `/aktyprawne/zalacznikiAkty/{idZalacznikiAkty}`
                .replace(`{${"idZalacznikiAkty"}}`, encodeURIComponent(String(idZalacznikiAkty)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca informacje o załączniku do umowy
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfZalacznikUmowy(idZalacznika: string, options: any = {}): RequestArgs {
            // verify required parameter 'idZalacznika' is not null or undefined
            if (idZalacznika === null || idZalacznika === undefined) {
                throw new RequiredError('idZalacznika','Required parameter idZalacznika was null or undefined when calling detailsOfZalacznikUmowy.');
            }
            const localVarPath = `/umowy/zalaczniki/{idZalacznika}`
                .replace(`{${"idZalacznika"}}`, encodeURIComponent(String(idZalacznika)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modyfikacja aktu prawnego
         * @param {string} idAktuPrawnego Identyfikator aktu prawnego do modyfikacji
         * @param {AktPrawny} [aktPrawny] Obiekt AktPrawny ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAktPrawny(idAktuPrawnego: string, aktPrawny?: AktPrawny, options: any = {}): RequestArgs {
            // verify required parameter 'idAktuPrawnego' is not null or undefined
            if (idAktuPrawnego === null || idAktuPrawnego === undefined) {
                throw new RequiredError('idAktuPrawnego','Required parameter idAktuPrawnego was null or undefined when calling editAktPrawny.');
            }
            const localVarPath = `/aktyprawne/details/{idAktuPrawnego}`
                .replace(`{${"idAktuPrawnego"}}`, encodeURIComponent(String(idAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof aktPrawny !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(aktPrawny !== undefined ? aktPrawny : {}) : (aktPrawny || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja jednostki
         * @param {string} idJednostki Identyfikator jednostki  do modyfikacji
         * @param {Jednostka} [jednostka] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editJednostka(idJednostki: string, jednostka?: Jednostka, options: any = {}): RequestArgs {
            // verify required parameter 'idJednostki' is not null or undefined
            if (idJednostki === null || idJednostki === undefined) {
                throw new RequiredError('idJednostki','Required parameter idJednostki was null or undefined when calling editJednostka.');
            }
            const localVarPath = `/umowy/jednostki/{idJednostki}`
                .replace(`{${"idJednostki"}}`, encodeURIComponent(String(idJednostki)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof jednostka !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(jednostka !== undefined ? jednostka : {}) : (jednostka || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja jednostki inicjującej
         * @param {string} idJednostkiInicjujacej Identyfikator organu  do modyfikacji
         * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editJednostkaInicjujaca(idJednostkiInicjujacej: string, jednostkaInicjujaca?: JednostkaInicjujaca, options: any = {}): RequestArgs {
            // verify required parameter 'idJednostkiInicjujacej' is not null or undefined
            if (idJednostkiInicjujacej === null || idJednostkiInicjujacej === undefined) {
                throw new RequiredError('idJednostkiInicjujacej','Required parameter idJednostkiInicjujacej was null or undefined when calling editJednostkaInicjujaca.');
            }
            const localVarPath = `/admin/jednostkiInicjujace/{idJednostkiInicjujacej}`
                .replace(`{${"idJednostkiInicjujacej"}}`, encodeURIComponent(String(idJednostkiInicjujacej)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof jednostkaInicjujaca !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(jednostkaInicjujaca !== undefined ? jednostkaInicjujaca : {}) : (jednostkaInicjujaca || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modyfikacja osoby
         * @param {string} idOsoby Identyfikator osoby do modyfikacji
         * @param {Osoba} [osoba] Obiekt Osoba ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOsoba(idOsoby: string, osoba?: Osoba, options: any = {}): RequestArgs {
            // verify required parameter 'idOsoby' is not null or undefined
            if (idOsoby === null || idOsoby === undefined) {
                throw new RequiredError('idOsoby','Required parameter idOsoby was null or undefined when calling editOsoba.');
            }
            const localVarPath = `/umowy/osoby/{idOsoby}`
                .replace(`{${"idOsoby"}}`, encodeURIComponent(String(idOsoby)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof osoba !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(osoba !== undefined ? osoba : {}) : (osoba || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja rodzaju aktu prawnego
         * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do modyfikacji
         * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Obiekt - rodzaj aktu prawnego do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, rodzajAktuPrawnego?: RodzajAktuPrawnego, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuAktuPrawnego' is not null or undefined
            if (idRodzajuAktuPrawnego === null || idRodzajuAktuPrawnego === undefined) {
                throw new RequiredError('idRodzajuAktuPrawnego','Required parameter idRodzajuAktuPrawnego was null or undefined when calling editRodzajAktuPrawnego.');
            }
            const localVarPath = `/admin/rodzajeAktow/{idRodzajuAktuPrawnego}`
                .replace(`{${"idRodzajuAktuPrawnego"}}`, encodeURIComponent(String(idRodzajuAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof rodzajAktuPrawnego !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rodzajAktuPrawnego !== undefined ? rodzajAktuPrawnego : {}) : (rodzajAktuPrawnego || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja rodzaju podmiotu
         * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu  do modyfikacji
         * @param {RodzajPodmiotu} [rodzajPodmiotu] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajPodmiotu(idRodzajuPodmiotu: string, rodzajPodmiotu?: RodzajPodmiotu, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuPodmiotu' is not null or undefined
            if (idRodzajuPodmiotu === null || idRodzajuPodmiotu === undefined) {
                throw new RequiredError('idRodzajuPodmiotu','Required parameter idRodzajuPodmiotu was null or undefined when calling editRodzajPodmiotu.');
            }
            const localVarPath = `/umowy/rodzajePodmiotow/{idRodzajuPodmiotu}`
                .replace(`{${"idRodzajuPodmiotu"}}`, encodeURIComponent(String(idRodzajuPodmiotu)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof rodzajPodmiotu !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rodzajPodmiotu !== undefined ? rodzajPodmiotu : {}) : (rodzajPodmiotu || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja rodzaju umowy
         * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy  do modyfikacji
         * @param {RodzajUmowy} [rodzajUmowy] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajUmowy(idRodzajuUmowy: string, rodzajUmowy?: RodzajUmowy, options: any = {}): RequestArgs {
            // verify required parameter 'idRodzajuUmowy' is not null or undefined
            if (idRodzajuUmowy === null || idRodzajuUmowy === undefined) {
                throw new RequiredError('idRodzajuUmowy','Required parameter idRodzajuUmowy was null or undefined when calling editRodzajUmowy.');
            }
            const localVarPath = `/umowy/rodzajeUmow/{idRodzajuUmowy}`
                .replace(`{${"idRodzajuUmowy"}}`, encodeURIComponent(String(idRodzajuUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof rodzajUmowy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rodzajUmowy !== undefined ? rodzajUmowy : {}) : (rodzajUmowy || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja statusu aktu prawnego
         * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do modyfikacji
         * @param {StatusAktuPrawnego} [statusAktuPrawnego] Obiekt - status aktu prawnego do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStatusAktuPrawnego(idStatusuAktuPrawnego: string, statusAktuPrawnego?: StatusAktuPrawnego, options: any = {}): RequestArgs {
            // verify required parameter 'idStatusuAktuPrawnego' is not null or undefined
            if (idStatusuAktuPrawnego === null || idStatusuAktuPrawnego === undefined) {
                throw new RequiredError('idStatusuAktuPrawnego','Required parameter idStatusuAktuPrawnego was null or undefined when calling editStatusAktuPrawnego.');
            }
            const localVarPath = `/admin/statusyAktow/{idStatusuAktuPrawnego}`
                .replace(`{${"idStatusuAktuPrawnego"}}`, encodeURIComponent(String(idStatusuAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof statusAktuPrawnego !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(statusAktuPrawnego !== undefined ? statusAktuPrawnego : {}) : (statusAktuPrawnego || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja statusu umowy
         * @param {string} idStatusu Identyfikator statusu umowy  do modyfikacji
         * @param {StatusUmowy} [statusUmowy] Obiekt do nadpisania w bazie, na podstawie id przekazanego w ściezce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStatusUmowy(idStatusu: string, statusUmowy?: StatusUmowy, options: any = {}): RequestArgs {
            // verify required parameter 'idStatusu' is not null or undefined
            if (idStatusu === null || idStatusu === undefined) {
                throw new RequiredError('idStatusu','Required parameter idStatusu was null or undefined when calling editStatusUmowy.');
            }
            const localVarPath = `/umowy/statusyumow/{idStatusu}`
                .replace(`{${"idStatusu"}}`, encodeURIComponent(String(idStatusu)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof statusUmowy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(statusUmowy !== undefined ? statusUmowy : {}) : (statusUmowy || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modyfikacja umowy
         * @param {string} idUmowy Identyfikator umowy do modyfikacji
         * @param {Umowa} [umowa] Obiekt Umowa ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUmowa(idUmowy: string, umowa?: Umowa, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling editUmowa.');
            }
            const localVarPath = `/umowy/details/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof umowa !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(umowa !== undefined ? umowa : {}) : (umowa || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja użytkownika
         * @param {string} idUzytkownika Identyfikator użytkownika do modyfikacji
         * @param {User} [user] Obiekt - użytkownik do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(idUzytkownika: string, user?: User, options: any = {}): RequestArgs {
            // verify required parameter 'idUzytkownika' is not null or undefined
            if (idUzytkownika === null || idUzytkownika === undefined) {
                throw new RequiredError('idUzytkownika','Required parameter idUzytkownika was null or undefined when calling editUser.');
            }
            const localVarPath = `/admin/users/{idUzytkownika}`
                .replace(`{${"idUzytkownika"}}`, encodeURIComponent(String(idUzytkownika)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja waluty
         * @param {string} idWaluty Identyfikator waluty  do modyfikacji
         * @param {Waluta} [waluta] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWaluta(idWaluty: string, waluta?: Waluta, options: any = {}): RequestArgs {
            // verify required parameter 'idWaluty' is not null or undefined
            if (idWaluty === null || idWaluty === undefined) {
                throw new RequiredError('idWaluty','Required parameter idWaluty was null or undefined when calling editWaluta.');
            }
            const localVarPath = `/umowy/waluty/{idWaluty}`
                .replace(`{${"idWaluty"}}`, encodeURIComponent(String(idWaluty)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof waluta !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(waluta !== undefined ? waluta : {}) : (waluta || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modyfikacja wykonawcy
         * @param {string} idWykonawcy Identyfikator wykonawcy  do modyfikacji
         * @param {Wykonawca} [wykonawca] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWykonawca(idWykonawcy: string, wykonawca?: Wykonawca, options: any = {}): RequestArgs {
            // verify required parameter 'idWykonawcy' is not null or undefined
            if (idWykonawcy === null || idWykonawcy === undefined) {
                throw new RequiredError('idWykonawcy','Required parameter idWykonawcy was null or undefined when calling editWykonawca.');
            }
            const localVarPath = `/umowy/wykonawcy/{idWykonawcy}`
                .replace(`{${"idWykonawcy"}}`, encodeURIComponent(String(idWykonawcy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof wykonawca !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(wykonawca !== undefined ? wykonawca : {}) : (wykonawca || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modyfikacja informacji o załaczniku do umowy
         * @param {string} idZalacznika Identyfikator załącznika do modyfikacji
         * @param {ZalacznikUmowy} [zalacznikUmowy] Obiekt ZalacznikUmowy ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editZalacznikUmowy(idZalacznika: string, zalacznikUmowy?: ZalacznikUmowy, options: any = {}): RequestArgs {
            // verify required parameter 'idZalacznika' is not null or undefined
            if (idZalacznika === null || idZalacznika === undefined) {
                throw new RequiredError('idZalacznika','Required parameter idZalacznika was null or undefined when calling editZalacznikUmowy.');
            }
            const localVarPath = `/umowy/zalaczniki/{idZalacznika}`
                .replace(`{${"idZalacznika"}}`, encodeURIComponent(String(idZalacznika)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof zalacznikUmowy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(zalacznikUmowy !== undefined ? zalacznikUmowy : {}) : (zalacznikUmowy || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pobranie pliku załącznika do aktu prawnego
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlikAktPrawny(idZalacznika: string, options: any = {}): RequestArgs {
            // verify required parameter 'idZalacznika' is not null or undefined
            if (idZalacznika === null || idZalacznika === undefined) {
                throw new RequiredError('idZalacznika','Required parameter idZalacznika was null or undefined when calling getPlikAktPrawny.');
            }
            const localVarPath = `/aktyprawne/pliki/{idZalacznika}`
                .replace(`{${"idZalacznika"}}`, encodeURIComponent(String(idZalacznika)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pobranie pliku załącznika
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlikUmowy(idZalacznika: string, options: any = {}): RequestArgs {
            // verify required parameter 'idZalacznika' is not null or undefined
            if (idZalacznika === null || idZalacznika === undefined) {
                throw new RequiredError('idZalacznika','Required parameter idZalacznika was null or undefined when calling getPlikUmowy.');
            }
            const localVarPath = `/umowy/pliki/{idZalacznika}`
                .replace(`{${"idZalacznika"}}`, encodeURIComponent(String(idZalacznika)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę aktów prawnych jako obiekt AktPrawnySearchResponse zawierający pole list: tablicę JSON obiektów typu AktPrawnyEL oraz pole count  - ile jest wszystkich obiektów spełniających kryteria, zaczynając od ostatnio uchwalonych, chyba że parametry sortowania wskażą co innego 
         * @summary Zwraca listę aktów prawnych, zaczynając od najnowszych - ostatnio dodanych (po id)
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAktPrawnyEL(page?: number, limit?: number, sort?: string, asc?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/aktyprawne`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (asc !== undefined) {
                localVarQueryParameter['asc'] = asc;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę aneksów (pełnych dokumentów) do umowy o podanym id
         * @param {string} idUmowy Id umowy, której aneksy są zwracane
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAneksUmowa(idUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling listOfAneksUmowa.');
            }
            const localVarPath = `/umowy/listaAneksow/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę aneksów (EL) do umowy o podanym id
         * @param {string} idUmowy Id umowy, której aneksy są zwracane
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAneksUmowaEL(idUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling listOfAneksUmowaEL.');
            }
            const localVarPath = `/umowy/listaAneksowEL/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę jednostek inicjujących
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfJednostkaInicjujaca(options: any = {}): RequestArgs {
            const localVarPath = `/admin/jednostkiInicjujace`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę jednostek organizacyjnych uczelni jako tablicę JSON obiektów typu Jednostka 
         * @summary Zwraca listę jednostek organizacyjnych uczelni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfJednostki(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/jednostki`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca logi systemowe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfLog(options: any = {}): RequestArgs {
            const localVarPath = `/admin/logs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę osób odpowiedzialnych za realizację umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfOsoba(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/osoby`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę rodzajów aktów prawnych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajAktuPrawnego(options: any = {}): RequestArgs {
            const localVarPath = `/admin/rodzajeAktow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę rodzajów podmiotów jako tablicę JSON obiektów typu Wykonawca 
         * @summary Zwraca listę rodzajów podmiotów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajPodmiotu(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/rodzajePodmiotow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę rodzajów umów jako tablicę JSON obiektów typu RodzajUmowy 
         * @summary Zwraca listę rodzajów umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajUmowy(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/rodzajeUmow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę ról systemowych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRole(options: any = {}): RequestArgs {
            const localVarPath = `/admin/roles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę statusów aktów prawnych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfStatusAktuPrawnego(options: any = {}): RequestArgs {
            const localVarPath = `/admin/statusyAktow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę statusów umów jako tablicę JSON obiektów typu StatusUmowy 
         * @summary Zwraca listę statusów umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfStatusUmowy(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/statusyumow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę umów jako tablicę JSON obiektów typu UmowaEL, zaczynając od ostatnio podpisanych 
         * @summary Zwraca listę umów, zaczynając od najnowszych
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfUmowa(page?: number, limit?: number, sort?: string, asc?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/umowy`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (asc !== undefined) {
                localVarQueryParameter['asc'] = asc;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę użytkowników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfUser(options: any = {}): RequestArgs {
            const localVarPath = `/admin/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę walut jako tablicę JSON obiektów typu Waluta 
         * @summary Zwraca listę walut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfWaluta(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/waluty`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca listę wykonawców jako tablicę JSON obiektów typu Wykonawca 
         * @summary Zwraca listę wykonawców
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfWykonawca(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/wykonawcy`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę obiektów ZalacznikAktuPrawnego na podstawie id aktu prawnego
         * @param {string} idAktuPrawnego id aktu prawnego do którego chcemy listę załączników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfZalacznikAktuPrawnego(idAktuPrawnego: string, options: any = {}): RequestArgs {
            // verify required parameter 'idAktuPrawnego' is not null or undefined
            if (idAktuPrawnego === null || idAktuPrawnego === undefined) {
                throw new RequiredError('idAktuPrawnego','Required parameter idAktuPrawnego was null or undefined when calling listOfZalacznikAktuPrawnego.');
            }
            const localVarPath = `/aktyprawne/zalaczniki/lista/{idAktuPrawnego}`
                .replace(`{${"idAktuPrawnego"}}`, encodeURIComponent(String(idAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca listę obiektów ZalacznikUmowy na podstawie id umowy
         * @param {string} idUmowy id umowy do której chcemy listę załączników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfZalacznikUmowy(idUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling listOfZalacznikUmowy.');
            }
            const localVarPath = `/umowy/zalaczniki/lista/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca obiekt dokumentu o podanym id
         * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAktPrawny(idAktuPrawnego: string, options: any = {}): RequestArgs {
            // verify required parameter 'idAktuPrawnego' is not null or undefined
            if (idAktuPrawnego === null || idAktuPrawnego === undefined) {
                throw new RequiredError('idAktuPrawnego','Required parameter idAktuPrawnego was null or undefined when calling loadAktPrawny.');
            }
            const localVarPath = `/aktyprawne/{idAktuPrawnego}`
                .replace(`{${"idAktuPrawnego"}}`, encodeURIComponent(String(idAktuPrawnego)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca obiekt umowy o podanym id
         * @param {string} idUmowy Id umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUmowa(idUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling loadUmowa.');
            }
            const localVarPath = `/umowy/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca ilość aktów prawnych w bazie
         * @summary Zwraca ilość aktów prawnych w bazie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policzAktPrawny(options: any = {}): RequestArgs {
            const localVarPath = `/aktyprawne/licz`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Zwraca ilość umów w bazie nie licząc aneksów
         * @summary Zwraca ilość umów w bazie nie licząc aneksów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policzUmowa(options: any = {}): RequestArgs {
            const localVarPath = `/umowy/licz`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary test konwertera BIgDecimal
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testKonwertera(inlineObject: InlineObject, options: any = {}): RequestArgs {
            // verify required parameter 'inlineObject' is not null or undefined
            if (inlineObject === null || inlineObject === undefined) {
                throw new RequiredError('inlineObject','Required parameter inlineObject was null or undefined when calling testKonwertera.');
            }
            const localVarPath = `/proba`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof inlineObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject !== undefined ? inlineObject : {}) : (inlineObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły umowy o podanym id
         * @param {string} idUmowy Id umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        umowaDetails(idUmowy: string, options: any = {}): RequestArgs {
            // verify required parameter 'idUmowy' is not null or undefined
            if (idUmowy === null || idUmowy === undefined) {
                throw new RequiredError('idUmowy','Required parameter idUmowy was null or undefined when calling umowaDetails.');
            }
            const localVarPath = `/umowy/details/{idUmowy}`
                .replace(`{${"idUmowy"}}`, encodeURIComponent(String(idUmowy)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Wyszukiwanie (proste) aktów prawnych
         * @param {string} query Hasło do wyszukania aktu prawnego
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukajAktPrawny(query: string, page?: number, limit?: number, sort?: string, asc?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling wyszukajAktPrawny.');
            }
            const localVarPath = `/aktyprawne/user/wyszukaj`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (asc !== undefined) {
                localVarQueryParameter['asc'] = asc;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Wyszukiwanie umów
         * @param {string} query Hasło do wyszukania umowy
         * @param {boolean} uwzglednijAneksy Czy wyszukiwanie ma obejmować także aneksy do umów
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukajUmowe(query: string, uwzglednijAneksy: boolean, page?: number, limit?: number, sort?: string, asc?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling wyszukajUmowe.');
            }
            // verify required parameter 'uwzglednijAneksy' is not null or undefined
            if (uwzglednijAneksy === null || uwzglednijAneksy === undefined) {
                throw new RequiredError('uwzglednijAneksy','Required parameter uwzglednijAneksy was null or undefined when calling wyszukajUmowe.');
            }
            const localVarPath = `/umowy/wyszukaj`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (uwzglednijAneksy !== undefined) {
                localVarQueryParameter['uwzglednijAneksy'] = uwzglednijAneksy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (asc !== undefined) {
                localVarQueryParameter['asc'] = asc;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Wyszukiwanie aktów prawnych dla użytkownika
         * @param {AktPrawnyUser} aktPrawnyUser parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukiwanieAktowPrawnychUser(aktPrawnyUser: AktPrawnyUser, options: any = {}): RequestArgs {
            // verify required parameter 'aktPrawnyUser' is not null or undefined
            if (aktPrawnyUser === null || aktPrawnyUser === undefined) {
                throw new RequiredError('aktPrawnyUser','Required parameter aktPrawnyUser was null or undefined when calling wyszukiwanieAktowPrawnychUser.');
            }
            const localVarPath = `/aktyprawne/user/wyszukaj`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof aktPrawnyUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(aktPrawnyUser !== undefined ? aktPrawnyUser : {}) : (aktPrawnyUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zaawansowane wyszukiwanie aktów prawnych
         * @param {AktPrawnyZaawansowane} aktPrawnyZaawansowane parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zaawansowaneWyszukiwanieAktowPrawnych(aktPrawnyZaawansowane: AktPrawnyZaawansowane, options: any = {}): RequestArgs {
            // verify required parameter 'aktPrawnyZaawansowane' is not null or undefined
            if (aktPrawnyZaawansowane === null || aktPrawnyZaawansowane === undefined) {
                throw new RequiredError('aktPrawnyZaawansowane','Required parameter aktPrawnyZaawansowane was null or undefined when calling zaawansowaneWyszukiwanieAktowPrawnych.');
            }
            const localVarPath = `/aktyprawne/wyszukaj`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof aktPrawnyZaawansowane !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(aktPrawnyZaawansowane !== undefined ? aktPrawnyZaawansowane : {}) : (aktPrawnyZaawansowane || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zaawansowane wyszukiwanie umów
         * @param {UmowaZaawansowane} umowaZaawansowane parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zaawansowaneWyszukiwanieUmow(umowaZaawansowane: UmowaZaawansowane, options: any = {}): RequestArgs {
            // verify required parameter 'umowaZaawansowane' is not null or undefined
            if (umowaZaawansowane === null || umowaZaawansowane === undefined) {
                throw new RequiredError('umowaZaawansowane','Required parameter umowaZaawansowane was null or undefined when calling zaawansowaneWyszukiwanieUmow.');
            }
            const localVarPath = `/umowy/wyszukaj`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof umowaZaawansowane !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(umowaZaawansowane !== undefined ? umowaZaawansowane : {}) : (umowaZaawansowane || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AktPrawny} [aktPrawny] Nowy obiekt AktPrawny
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAktPrawny(aktPrawny?: AktPrawny, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addAktPrawny(aktPrawny, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Tworzy kopię umowy jako bazę dla aneksu. Zwraca id aneksu
         * @param {string} idUmowy Id umowy do której dodajemy aneks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAneksUmowa(idUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addAneksUmowa(idUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Tworzy pusty akt prawny, zapisuje go w bazie i zwraca jego id. Dalsze etapy wypełniania pól aktu prawnego powinny być realizowane za pomocą edycji.
         * @summary Tworzy nowy, pusty akt prawny i zwraca jedo id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmptyAktPrawny(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addEmptyAktPrawny(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Tworzy nową, pustą umowę, zapisuje ją w bazie i zwraca jej id. Dalsze etapy wypełniania pól umowy powinny być realizowane za pomocą edycji.
         * @summary Tworzy nową, pustą umowę i zwraca jej id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmptyUmowa(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addEmptyUmowa(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowej jednostki organizacyjnej uczelni do słownika
         * @param {Jednostka} [jednostka] Nowy obiekt Jednostka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addJednostka(jednostka?: Jednostka, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addJednostka(jednostka, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowej jednostki inicjującej do słownika
         * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Nowy obiekt JednostkaInicjujaca
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addJednostkaInicjujaca(jednostkaInicjujaca?: JednostkaInicjujaca, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addJednostkaInicjujaca(jednostkaInicjujaca, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowej osoby odpowiedzialnej za realizację umowy
         * @param {Osoba} [osoba] Nowy obiekt Osoba
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsoba(osoba?: Osoba, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addOsoba(osoba, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary dodanie pliku do aktu prawnego do już istniejącego w bazie obiektu załącznika
         * @param {string} [idZalacznika] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlikDoAktuPrawnego(idZalacznika?: string, file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addPlikDoAktuPrawnego(idZalacznika, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary dodanie pliku do umowy do już istniejącego w bazie obiektu załącznika
         * @param {string} [idZalacznika] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlikDoUmowy(idZalacznika?: string, file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addPlikDoUmowy(idZalacznika, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju aktu prawnego do słownika
         * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Nowy obiekt RodzajAktuPrawnego
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajAktuPrawnego(rodzajAktuPrawnego?: RodzajAktuPrawnego, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addRodzajAktuPrawnego(rodzajAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju podmiotów do słownika
         * @param {RodzajPodmiotu} [rodzajPodmiotu] Nowy obiekt RodzajPodmiotu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajPomiotu(rodzajPodmiotu?: RodzajPodmiotu, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addRodzajPomiotu(rodzajPodmiotu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju umowy do słownika
         * @param {RodzajUmowy} [rodzajUmowy] Nowy obiekt RodzajUmowy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajUmowy(rodzajUmowy?: RodzajUmowy, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addRodzajUmowy(rodzajUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego statusu aktu prawnego do słownika
         * @param {StatusAktuPrawnego} [statusAktuPrawnego] Nowy obiekt StatusAktuPrawnego
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusAktuPrawnego(statusAktuPrawnego?: StatusAktuPrawnego, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addStatusAktuPrawnego(statusAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego statusu umowy do słownika
         * @param {StatusUmowy} [statusUmowy] Nowy obiekt StatusUmowy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusUmowy(statusUmowy?: StatusUmowy, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addStatusUmowy(statusUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Dodaj nowego użytkownika
         * @param {User} [user] Nowy użytkownik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user?: User, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addUser(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowej waluty do słownika
         * @param {Waluta} [waluta] Nowy obiekt Waluta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWaluta(waluta?: Waluta, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addWaluta(waluta, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego wykonawcy do słownika
         * @param {Wykonawca} [wykonawca] Nowy obiekt Wykonawca
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWykonawca(wykonawca?: Wykonawca, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addWykonawca(wykonawca, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego załącznika aktu prawnego
         * @param {ZalacznikAktuPrawnego} [zalacznikAktuPrawnego] Nowy obiekt - załącznik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addZalacznikAktuPrawnego(zalacznikAktuPrawnego?: ZalacznikAktuPrawnego, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addZalacznikAktuPrawnego(zalacznikAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dodanie nowego załącznika umowy
         * @param {ZalacznikUmowy} [zalacznikUmowy] Nowy obiekt - załącznik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addZalacznikUmowy(zalacznikUmowy?: ZalacznikUmowy, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addZalacznikUmowy(zalacznikUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły dokumentu o podanym id
         * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktPrawnyDetails(idAktuPrawnego: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AktPrawnyDetails> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).aktPrawnyDetails(idAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Usuń akt prawny
         * @param {string} idAktuPrawnego 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAktPrawny(idAktuPrawnego: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteAktPrawny(idAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie jednostki
         * @param {string} idJednostki Identyfikator jednostki do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJednostka(idJednostki: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteJednostka(idJednostki, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie jednostki inicjującej
         * @param {string} idJednostkiInicjujacej Identyfikator jednostki inicjującej do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJednostkaInicjujaca(idJednostkiInicjujacej: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteJednostkaInicjujaca(idJednostkiInicjujacej, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Usuń osobę
         * @param {string} idOsoby 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOsoba(idOsoby: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteOsoba(idOsoby, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie rodzaju aktu prawnego
         * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteRodzajAktuPrawnego(idRodzajuAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie rodzaju podmiotu
         * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajPodmiotu(idRodzajuPodmiotu: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteRodzajPodmiotu(idRodzajuPodmiotu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie rodzaju umowy
         * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajUmowy(idRodzajuUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteRodzajUmowy(idRodzajuUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie statusu aktu prawnego
         * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusAktuPrawnego(idStatusuAktuPrawnego: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteStatusAktuPrawnego(idStatusuAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie statusu umowy
         * @param {string} idStatusu Identyfikator statusu umowy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusUmowy(idStatusu: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteStatusUmowy(idStatusu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Usuń umowę
         * @param {string} idUmowy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUmowa(idUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteUmowa(idUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie użytkownika
         * @param {string} idUzytkownika Identyfikator użytkownika usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(idUzytkownika: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteUser(idUzytkownika, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie waluty
         * @param {string} idWaluty Identyfikator waluty do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaluta(idWaluty: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteWaluta(idWaluty, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie wykonawcy
         * @param {string} idWykonawcy Identyfikator wykonawcy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWykonawca(idWykonawcy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteWykonawca(idWykonawcy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie załącznika
         * @param {string} idZalacznikiAkty Identyfikator załącznika do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZalacznikAktuPrawnego(idZalacznikiAkty: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteZalacznikAktuPrawnego(idZalacznikiAkty, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Usunięcie załącznika do umowy
         * @param {string} idZalacznika Identyfikator załącznika do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZalacznikUmowy(idZalacznika: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteZalacznikUmowy(idZalacznika, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły jednostki organizacyjnej o podanym id
         * @param {string} idJednostki Id jednostki do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfJednostka(idJednostki: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jednostka> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfJednostka(idJednostki, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły osoby o podanym id
         * @param {string} idOsoby Id osoby do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfOsoba(idOsoby: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Osoba> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfOsoba(idOsoby, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły rodzaju podmiotu o podanym id
         * @param {string} idRodzajuPodmiotu Id rodzaju podmiotu do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfRodzajPodmiotu(idRodzajuPodmiotu: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RodzajPodmiotu> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfRodzajPodmiotu(idRodzajuPodmiotu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły rodzaju umowy o podanym id
         * @param {string} idRodzajuUmowy Id rodzaju umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfRodzajUmowy(idRodzajuUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RodzajUmowy> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfRodzajUmowy(idRodzajuUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły statusu o podanym id
         * @param {string} idStatusu Id statusu do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfStatusUmowy(idStatusu: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusUmowy> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfStatusUmowy(idStatusu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły waluty o podanym id
         * @param {string} idWaluty Id waluty do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfWaluta(idWaluty: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Waluta> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfWaluta(idWaluty, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły wykonawcy o podanym id
         * @param {string} idWykonawcy Id wykonawcy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfWykonawca(idWykonawcy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wykonawca> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfWykonawca(idWykonawcy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca obiekt załącznika
         * @param {string} idZalacznikiAkty Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfZalacznikAktuPrawnego(idZalacznikiAkty: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZalacznikAktuPrawnego> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfZalacznikAktuPrawnego(idZalacznikiAkty, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca informacje o załączniku do umowy
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfZalacznikUmowy(idZalacznika: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZalacznikUmowy> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).detailsOfZalacznikUmowy(idZalacznika, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Modyfikacja aktu prawnego
         * @param {string} idAktuPrawnego Identyfikator aktu prawnego do modyfikacji
         * @param {AktPrawny} [aktPrawny] Obiekt AktPrawny ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAktPrawny(idAktuPrawnego: string, aktPrawny?: AktPrawny, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editAktPrawny(idAktuPrawnego, aktPrawny, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja jednostki
         * @param {string} idJednostki Identyfikator jednostki  do modyfikacji
         * @param {Jednostka} [jednostka] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editJednostka(idJednostki: string, jednostka?: Jednostka, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editJednostka(idJednostki, jednostka, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja jednostki inicjującej
         * @param {string} idJednostkiInicjujacej Identyfikator organu  do modyfikacji
         * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editJednostkaInicjujaca(idJednostkiInicjujacej: string, jednostkaInicjujaca?: JednostkaInicjujaca, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editJednostkaInicjujaca(idJednostkiInicjujacej, jednostkaInicjujaca, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Modyfikacja osoby
         * @param {string} idOsoby Identyfikator osoby do modyfikacji
         * @param {Osoba} [osoba] Obiekt Osoba ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOsoba(idOsoby: string, osoba?: Osoba, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editOsoba(idOsoby, osoba, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja rodzaju aktu prawnego
         * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do modyfikacji
         * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Obiekt - rodzaj aktu prawnego do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, rodzajAktuPrawnego?: RodzajAktuPrawnego, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editRodzajAktuPrawnego(idRodzajuAktuPrawnego, rodzajAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja rodzaju podmiotu
         * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu  do modyfikacji
         * @param {RodzajPodmiotu} [rodzajPodmiotu] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajPodmiotu(idRodzajuPodmiotu: string, rodzajPodmiotu?: RodzajPodmiotu, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editRodzajPodmiotu(idRodzajuPodmiotu, rodzajPodmiotu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja rodzaju umowy
         * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy  do modyfikacji
         * @param {RodzajUmowy} [rodzajUmowy] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajUmowy(idRodzajuUmowy: string, rodzajUmowy?: RodzajUmowy, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editRodzajUmowy(idRodzajuUmowy, rodzajUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja statusu aktu prawnego
         * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do modyfikacji
         * @param {StatusAktuPrawnego} [statusAktuPrawnego] Obiekt - status aktu prawnego do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStatusAktuPrawnego(idStatusuAktuPrawnego: string, statusAktuPrawnego?: StatusAktuPrawnego, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editStatusAktuPrawnego(idStatusuAktuPrawnego, statusAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja statusu umowy
         * @param {string} idStatusu Identyfikator statusu umowy  do modyfikacji
         * @param {StatusUmowy} [statusUmowy] Obiekt do nadpisania w bazie, na podstawie id przekazanego w ściezce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStatusUmowy(idStatusu: string, statusUmowy?: StatusUmowy, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editStatusUmowy(idStatusu, statusUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Modyfikacja umowy
         * @param {string} idUmowy Identyfikator umowy do modyfikacji
         * @param {Umowa} [umowa] Obiekt Umowa ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUmowa(idUmowy: string, umowa?: Umowa, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editUmowa(idUmowy, umowa, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja użytkownika
         * @param {string} idUzytkownika Identyfikator użytkownika do modyfikacji
         * @param {User} [user] Obiekt - użytkownik do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(idUzytkownika: string, user?: User, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editUser(idUzytkownika, user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja waluty
         * @param {string} idWaluty Identyfikator waluty  do modyfikacji
         * @param {Waluta} [waluta] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWaluta(idWaluty: string, waluta?: Waluta, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editWaluta(idWaluty, waluta, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modyfikacja wykonawcy
         * @param {string} idWykonawcy Identyfikator wykonawcy  do modyfikacji
         * @param {Wykonawca} [wykonawca] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWykonawca(idWykonawcy: string, wykonawca?: Wykonawca, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editWykonawca(idWykonawcy, wykonawca, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Modyfikacja informacji o załaczniku do umowy
         * @param {string} idZalacznika Identyfikator załącznika do modyfikacji
         * @param {ZalacznikUmowy} [zalacznikUmowy] Obiekt ZalacznikUmowy ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editZalacznikUmowy(idZalacznika: string, zalacznikUmowy?: ZalacznikUmowy, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseOkWithId> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).editZalacznikUmowy(idZalacznika, zalacznikUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary pobranie pliku załącznika do aktu prawnego
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlikAktPrawny(idZalacznika: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPlikAktPrawny(idZalacznika, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary pobranie pliku załącznika
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlikUmowy(idZalacznika: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPlikUmowy(idZalacznika, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę aktów prawnych jako obiekt AktPrawnySearchResponse zawierający pole list: tablicę JSON obiektów typu AktPrawnyEL oraz pole count  - ile jest wszystkich obiektów spełniających kryteria, zaczynając od ostatnio uchwalonych, chyba że parametry sortowania wskażą co innego 
         * @summary Zwraca listę aktów prawnych, zaczynając od najnowszych - ostatnio dodanych (po id)
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAktPrawnyEL(page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AktPrawnySearchResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfAktPrawnyEL(page, limit, sort, asc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę aneksów (pełnych dokumentów) do umowy o podanym id
         * @param {string} idUmowy Id umowy, której aneksy są zwracane
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAneksUmowa(idUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Umowa>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfAneksUmowa(idUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę aneksów (EL) do umowy o podanym id
         * @param {string} idUmowy Id umowy, której aneksy są zwracane
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAneksUmowaEL(idUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UmowaEL>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfAneksUmowaEL(idUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę jednostek inicjujących
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfJednostkaInicjujaca(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JednostkaInicjujaca>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfJednostkaInicjujaca(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę jednostek organizacyjnych uczelni jako tablicę JSON obiektów typu Jednostka 
         * @summary Zwraca listę jednostek organizacyjnych uczelni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfJednostki(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Jednostka>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfJednostki(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca logi systemowe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfLog(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Log>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfLog(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę osób odpowiedzialnych za realizację umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfOsoba(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Osoba>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfOsoba(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę rodzajów aktów prawnych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajAktuPrawnego(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RodzajAktuPrawnego>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfRodzajAktuPrawnego(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę rodzajów podmiotów jako tablicę JSON obiektów typu Wykonawca 
         * @summary Zwraca listę rodzajów podmiotów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajPodmiotu(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RodzajPodmiotu>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfRodzajPodmiotu(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę rodzajów umów jako tablicę JSON obiektów typu RodzajUmowy 
         * @summary Zwraca listę rodzajów umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajUmowy(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RodzajUmowy>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfRodzajUmowy(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę ról systemowych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRole(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfRole(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę statusów aktów prawnych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfStatusAktuPrawnego(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusAktuPrawnego>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfStatusAktuPrawnego(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę statusów umów jako tablicę JSON obiektów typu StatusUmowy 
         * @summary Zwraca listę statusów umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfStatusUmowy(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusUmowy>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfStatusUmowy(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę umów jako tablicę JSON obiektów typu UmowaEL, zaczynając od ostatnio podpisanych 
         * @summary Zwraca listę umów, zaczynając od najnowszych
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfUmowa(page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UmowaSearchResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfUmowa(page, limit, sort, asc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę użytkowników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfUser(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę walut jako tablicę JSON obiektów typu Waluta 
         * @summary Zwraca listę walut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfWaluta(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Waluta>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfWaluta(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca listę wykonawców jako tablicę JSON obiektów typu Wykonawca 
         * @summary Zwraca listę wykonawców
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfWykonawca(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Wykonawca>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfWykonawca(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę obiektów ZalacznikAktuPrawnego na podstawie id aktu prawnego
         * @param {string} idAktuPrawnego id aktu prawnego do którego chcemy listę załączników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfZalacznikAktuPrawnego(idAktuPrawnego: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZalacznikAktuPrawnego>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfZalacznikAktuPrawnego(idAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca listę obiektów ZalacznikUmowy na podstawie id umowy
         * @param {string} idUmowy id umowy do której chcemy listę załączników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfZalacznikUmowy(idUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZalacznikUmowy>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listOfZalacznikUmowy(idUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca obiekt dokumentu o podanym id
         * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAktPrawny(idAktuPrawnego: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AktPrawny> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).loadAktPrawny(idAktuPrawnego, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca obiekt umowy o podanym id
         * @param {string} idUmowy Id umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUmowa(idUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Umowa> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).loadUmowa(idUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca ilość aktów prawnych w bazie
         * @summary Zwraca ilość aktów prawnych w bazie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policzAktPrawny(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).policzAktPrawny(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Zwraca ilość umów w bazie nie licząc aneksów
         * @summary Zwraca ilość umów w bazie nie licząc aneksów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policzUmowa(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).policzUmowa(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary test konwertera BIgDecimal
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testKonwertera(inlineObject: InlineObject, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).testKonwertera(inlineObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zwraca szczegóły umowy o podanym id
         * @param {string} idUmowy Id umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        umowaDetails(idUmowy: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UmowaDetails> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).umowaDetails(idUmowy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Wyszukiwanie (proste) aktów prawnych
         * @param {string} query Hasło do wyszukania aktu prawnego
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukajAktPrawny(query: string, page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AktPrawnySearchResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).wyszukajAktPrawny(query, page, limit, sort, asc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Wyszukiwanie umów
         * @param {string} query Hasło do wyszukania umowy
         * @param {boolean} uwzglednijAneksy Czy wyszukiwanie ma obejmować także aneksy do umów
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukajUmowe(query: string, uwzglednijAneksy: boolean, page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UmowaSearchResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).wyszukajUmowe(query, uwzglednijAneksy, page, limit, sort, asc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Wyszukiwanie aktów prawnych dla użytkownika
         * @param {AktPrawnyUser} aktPrawnyUser parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukiwanieAktowPrawnychUser(aktPrawnyUser: AktPrawnyUser, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AktPrawnyEL>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).wyszukiwanieAktowPrawnychUser(aktPrawnyUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zaawansowane wyszukiwanie aktów prawnych
         * @param {AktPrawnyZaawansowane} aktPrawnyZaawansowane parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zaawansowaneWyszukiwanieAktowPrawnych(aktPrawnyZaawansowane: AktPrawnyZaawansowane, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AktPrawnySearchResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).zaawansowaneWyszukiwanieAktowPrawnych(aktPrawnyZaawansowane, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Zaawansowane wyszukiwanie umów
         * @param {UmowaZaawansowane} umowaZaawansowane parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zaawansowaneWyszukiwanieUmow(umowaZaawansowane: UmowaZaawansowane, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UmowaSearchResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).zaawansowaneWyszukiwanieUmow(umowaZaawansowane, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AktPrawny} [aktPrawny] Nowy obiekt AktPrawny
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAktPrawny(aktPrawny?: AktPrawny, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addAktPrawny(aktPrawny, options)(axios, basePath);
        },
        /**
         * 
         * @summary Tworzy kopię umowy jako bazę dla aneksu. Zwraca id aneksu
         * @param {string} idUmowy Id umowy do której dodajemy aneks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAneksUmowa(idUmowy: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addAneksUmowa(idUmowy, options)(axios, basePath);
        },
        /**
         * Tworzy pusty akt prawny, zapisuje go w bazie i zwraca jego id. Dalsze etapy wypełniania pól aktu prawnego powinny być realizowane za pomocą edycji.
         * @summary Tworzy nowy, pusty akt prawny i zwraca jedo id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmptyAktPrawny(options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addEmptyAktPrawny(options)(axios, basePath);
        },
        /**
         * Tworzy nową, pustą umowę, zapisuje ją w bazie i zwraca jej id. Dalsze etapy wypełniania pól umowy powinny być realizowane za pomocą edycji.
         * @summary Tworzy nową, pustą umowę i zwraca jej id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmptyUmowa(options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addEmptyUmowa(options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowej jednostki organizacyjnej uczelni do słownika
         * @param {Jednostka} [jednostka] Nowy obiekt Jednostka
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addJednostka(jednostka?: Jednostka, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addJednostka(jednostka, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowej jednostki inicjującej do słownika
         * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Nowy obiekt JednostkaInicjujaca
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addJednostkaInicjujaca(jednostkaInicjujaca?: JednostkaInicjujaca, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addJednostkaInicjujaca(jednostkaInicjujaca, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowej osoby odpowiedzialnej za realizację umowy
         * @param {Osoba} [osoba] Nowy obiekt Osoba
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsoba(osoba?: Osoba, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addOsoba(osoba, options)(axios, basePath);
        },
        /**
         * 
         * @summary dodanie pliku do aktu prawnego do już istniejącego w bazie obiektu załącznika
         * @param {string} [idZalacznika] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlikDoAktuPrawnego(idZalacznika?: string, file?: any, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addPlikDoAktuPrawnego(idZalacznika, file, options)(axios, basePath);
        },
        /**
         * 
         * @summary dodanie pliku do umowy do już istniejącego w bazie obiektu załącznika
         * @param {string} [idZalacznika] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlikDoUmowy(idZalacznika?: string, file?: any, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addPlikDoUmowy(idZalacznika, file, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju aktu prawnego do słownika
         * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Nowy obiekt RodzajAktuPrawnego
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajAktuPrawnego(rodzajAktuPrawnego?: RodzajAktuPrawnego, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addRodzajAktuPrawnego(rodzajAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju podmiotów do słownika
         * @param {RodzajPodmiotu} [rodzajPodmiotu] Nowy obiekt RodzajPodmiotu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajPomiotu(rodzajPodmiotu?: RodzajPodmiotu, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addRodzajPomiotu(rodzajPodmiotu, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego rodzaju umowy do słownika
         * @param {RodzajUmowy} [rodzajUmowy] Nowy obiekt RodzajUmowy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRodzajUmowy(rodzajUmowy?: RodzajUmowy, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addRodzajUmowy(rodzajUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego statusu aktu prawnego do słownika
         * @param {StatusAktuPrawnego} [statusAktuPrawnego] Nowy obiekt StatusAktuPrawnego
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusAktuPrawnego(statusAktuPrawnego?: StatusAktuPrawnego, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addStatusAktuPrawnego(statusAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego statusu umowy do słownika
         * @param {StatusUmowy} [statusUmowy] Nowy obiekt StatusUmowy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusUmowy(statusUmowy?: StatusUmowy, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addStatusUmowy(statusUmowy, options)(axios, basePath);
        },
        /**
         * Dodaj nowego użytkownika
         * @param {User} [user] Nowy użytkownik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user?: User, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addUser(user, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowej waluty do słownika
         * @param {Waluta} [waluta] Nowy obiekt Waluta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWaluta(waluta?: Waluta, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addWaluta(waluta, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego wykonawcy do słownika
         * @param {Wykonawca} [wykonawca] Nowy obiekt Wykonawca
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWykonawca(wykonawca?: Wykonawca, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addWykonawca(wykonawca, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego załącznika aktu prawnego
         * @param {ZalacznikAktuPrawnego} [zalacznikAktuPrawnego] Nowy obiekt - załącznik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addZalacznikAktuPrawnego(zalacznikAktuPrawnego?: ZalacznikAktuPrawnego, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addZalacznikAktuPrawnego(zalacznikAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dodanie nowego załącznika umowy
         * @param {ZalacznikUmowy} [zalacznikUmowy] Nowy obiekt - załącznik
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addZalacznikUmowy(zalacznikUmowy?: ZalacznikUmowy, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).addZalacznikUmowy(zalacznikUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły dokumentu o podanym id
         * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktPrawnyDetails(idAktuPrawnego: string, options?: any): AxiosPromise<AktPrawnyDetails> {
            return DefaultApiFp(configuration).aktPrawnyDetails(idAktuPrawnego, options)(axios, basePath);
        },
        /**
         * Usuń akt prawny
         * @param {string} idAktuPrawnego 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAktPrawny(idAktuPrawnego: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteAktPrawny(idAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie jednostki
         * @param {string} idJednostki Identyfikator jednostki do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJednostka(idJednostki: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteJednostka(idJednostki, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie jednostki inicjującej
         * @param {string} idJednostkiInicjujacej Identyfikator jednostki inicjującej do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJednostkaInicjujaca(idJednostkiInicjujacej: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteJednostkaInicjujaca(idJednostkiInicjujacej, options)(axios, basePath);
        },
        /**
         * Usuń osobę
         * @param {string} idOsoby 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOsoba(idOsoby: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteOsoba(idOsoby, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie rodzaju aktu prawnego
         * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteRodzajAktuPrawnego(idRodzajuAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie rodzaju podmiotu
         * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajPodmiotu(idRodzajuPodmiotu: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteRodzajPodmiotu(idRodzajuPodmiotu, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie rodzaju umowy
         * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRodzajUmowy(idRodzajuUmowy: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteRodzajUmowy(idRodzajuUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie statusu aktu prawnego
         * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusAktuPrawnego(idStatusuAktuPrawnego: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteStatusAktuPrawnego(idStatusuAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie statusu umowy
         * @param {string} idStatusu Identyfikator statusu umowy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusUmowy(idStatusu: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteStatusUmowy(idStatusu, options)(axios, basePath);
        },
        /**
         * Usuń umowę
         * @param {string} idUmowy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUmowa(idUmowy: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteUmowa(idUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie użytkownika
         * @param {string} idUzytkownika Identyfikator użytkownika usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(idUzytkownika: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteUser(idUzytkownika, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie waluty
         * @param {string} idWaluty Identyfikator waluty do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWaluta(idWaluty: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteWaluta(idWaluty, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie wykonawcy
         * @param {string} idWykonawcy Identyfikator wykonawcy do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWykonawca(idWykonawcy: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteWykonawca(idWykonawcy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie załącznika
         * @param {string} idZalacznikiAkty Identyfikator załącznika do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZalacznikAktuPrawnego(idZalacznikiAkty: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteZalacznikAktuPrawnego(idZalacznikiAkty, options)(axios, basePath);
        },
        /**
         * 
         * @summary Usunięcie załącznika do umowy
         * @param {string} idZalacznika Identyfikator załącznika do usunięcia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteZalacznikUmowy(idZalacznika: string, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).deleteZalacznikUmowy(idZalacznika, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły jednostki organizacyjnej o podanym id
         * @param {string} idJednostki Id jednostki do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfJednostka(idJednostki: string, options?: any): AxiosPromise<Jednostka> {
            return DefaultApiFp(configuration).detailsOfJednostka(idJednostki, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły osoby o podanym id
         * @param {string} idOsoby Id osoby do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfOsoba(idOsoby: string, options?: any): AxiosPromise<Osoba> {
            return DefaultApiFp(configuration).detailsOfOsoba(idOsoby, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły rodzaju podmiotu o podanym id
         * @param {string} idRodzajuPodmiotu Id rodzaju podmiotu do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfRodzajPodmiotu(idRodzajuPodmiotu: string, options?: any): AxiosPromise<RodzajPodmiotu> {
            return DefaultApiFp(configuration).detailsOfRodzajPodmiotu(idRodzajuPodmiotu, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły rodzaju umowy o podanym id
         * @param {string} idRodzajuUmowy Id rodzaju umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfRodzajUmowy(idRodzajuUmowy: string, options?: any): AxiosPromise<RodzajUmowy> {
            return DefaultApiFp(configuration).detailsOfRodzajUmowy(idRodzajuUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły statusu o podanym id
         * @param {string} idStatusu Id statusu do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfStatusUmowy(idStatusu: string, options?: any): AxiosPromise<StatusUmowy> {
            return DefaultApiFp(configuration).detailsOfStatusUmowy(idStatusu, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły waluty o podanym id
         * @param {string} idWaluty Id waluty do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfWaluta(idWaluty: string, options?: any): AxiosPromise<Waluta> {
            return DefaultApiFp(configuration).detailsOfWaluta(idWaluty, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły wykonawcy o podanym id
         * @param {string} idWykonawcy Id wykonawcy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfWykonawca(idWykonawcy: string, options?: any): AxiosPromise<Wykonawca> {
            return DefaultApiFp(configuration).detailsOfWykonawca(idWykonawcy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca obiekt załącznika
         * @param {string} idZalacznikiAkty Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfZalacznikAktuPrawnego(idZalacznikiAkty: string, options?: any): AxiosPromise<ZalacznikAktuPrawnego> {
            return DefaultApiFp(configuration).detailsOfZalacznikAktuPrawnego(idZalacznikiAkty, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca informacje o załączniku do umowy
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsOfZalacznikUmowy(idZalacznika: string, options?: any): AxiosPromise<ZalacznikUmowy> {
            return DefaultApiFp(configuration).detailsOfZalacznikUmowy(idZalacznika, options)(axios, basePath);
        },
        /**
         * Modyfikacja aktu prawnego
         * @param {string} idAktuPrawnego Identyfikator aktu prawnego do modyfikacji
         * @param {AktPrawny} [aktPrawny] Obiekt AktPrawny ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAktPrawny(idAktuPrawnego: string, aktPrawny?: AktPrawny, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editAktPrawny(idAktuPrawnego, aktPrawny, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja jednostki
         * @param {string} idJednostki Identyfikator jednostki  do modyfikacji
         * @param {Jednostka} [jednostka] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editJednostka(idJednostki: string, jednostka?: Jednostka, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editJednostka(idJednostki, jednostka, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja jednostki inicjującej
         * @param {string} idJednostkiInicjujacej Identyfikator organu  do modyfikacji
         * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editJednostkaInicjujaca(idJednostkiInicjujacej: string, jednostkaInicjujaca?: JednostkaInicjujaca, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editJednostkaInicjujaca(idJednostkiInicjujacej, jednostkaInicjujaca, options)(axios, basePath);
        },
        /**
         * Modyfikacja osoby
         * @param {string} idOsoby Identyfikator osoby do modyfikacji
         * @param {Osoba} [osoba] Obiekt Osoba ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOsoba(idOsoby: string, osoba?: Osoba, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editOsoba(idOsoby, osoba, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja rodzaju aktu prawnego
         * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do modyfikacji
         * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Obiekt - rodzaj aktu prawnego do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, rodzajAktuPrawnego?: RodzajAktuPrawnego, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editRodzajAktuPrawnego(idRodzajuAktuPrawnego, rodzajAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja rodzaju podmiotu
         * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu  do modyfikacji
         * @param {RodzajPodmiotu} [rodzajPodmiotu] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajPodmiotu(idRodzajuPodmiotu: string, rodzajPodmiotu?: RodzajPodmiotu, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editRodzajPodmiotu(idRodzajuPodmiotu, rodzajPodmiotu, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja rodzaju umowy
         * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy  do modyfikacji
         * @param {RodzajUmowy} [rodzajUmowy] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRodzajUmowy(idRodzajuUmowy: string, rodzajUmowy?: RodzajUmowy, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editRodzajUmowy(idRodzajuUmowy, rodzajUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja statusu aktu prawnego
         * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do modyfikacji
         * @param {StatusAktuPrawnego} [statusAktuPrawnego] Obiekt - status aktu prawnego do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStatusAktuPrawnego(idStatusuAktuPrawnego: string, statusAktuPrawnego?: StatusAktuPrawnego, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editStatusAktuPrawnego(idStatusuAktuPrawnego, statusAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja statusu umowy
         * @param {string} idStatusu Identyfikator statusu umowy  do modyfikacji
         * @param {StatusUmowy} [statusUmowy] Obiekt do nadpisania w bazie, na podstawie id przekazanego w ściezce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStatusUmowy(idStatusu: string, statusUmowy?: StatusUmowy, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editStatusUmowy(idStatusu, statusUmowy, options)(axios, basePath);
        },
        /**
         * Modyfikacja umowy
         * @param {string} idUmowy Identyfikator umowy do modyfikacji
         * @param {Umowa} [umowa] Obiekt Umowa ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUmowa(idUmowy: string, umowa?: Umowa, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editUmowa(idUmowy, umowa, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja użytkownika
         * @param {string} idUzytkownika Identyfikator użytkownika do modyfikacji
         * @param {User} [user] Obiekt - użytkownik do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(idUzytkownika: string, user?: User, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editUser(idUzytkownika, user, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja waluty
         * @param {string} idWaluty Identyfikator waluty  do modyfikacji
         * @param {Waluta} [waluta] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWaluta(idWaluty: string, waluta?: Waluta, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editWaluta(idWaluty, waluta, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modyfikacja wykonawcy
         * @param {string} idWykonawcy Identyfikator wykonawcy  do modyfikacji
         * @param {Wykonawca} [wykonawca] Obiekt do nadpisania w bazie, na podstawie id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWykonawca(idWykonawcy: string, wykonawca?: Wykonawca, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editWykonawca(idWykonawcy, wykonawca, options)(axios, basePath);
        },
        /**
         * Modyfikacja informacji o załaczniku do umowy
         * @param {string} idZalacznika Identyfikator załącznika do modyfikacji
         * @param {ZalacznikUmowy} [zalacznikUmowy] Obiekt ZalacznikUmowy ze zmodyfikowanymi wartościami
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editZalacznikUmowy(idZalacznika: string, zalacznikUmowy?: ZalacznikUmowy, options?: any): AxiosPromise<ResponseOkWithId> {
            return DefaultApiFp(configuration).editZalacznikUmowy(idZalacznika, zalacznikUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary pobranie pliku załącznika do aktu prawnego
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlikAktPrawny(idZalacznika: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).getPlikAktPrawny(idZalacznika, options)(axios, basePath);
        },
        /**
         * 
         * @summary pobranie pliku załącznika
         * @param {string} idZalacznika Identyfikator załącznika do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlikUmowy(idZalacznika: string, options?: any): AxiosPromise<any> {
            return DefaultApiFp(configuration).getPlikUmowy(idZalacznika, options)(axios, basePath);
        },
        /**
         * Zwraca listę aktów prawnych jako obiekt AktPrawnySearchResponse zawierający pole list: tablicę JSON obiektów typu AktPrawnyEL oraz pole count  - ile jest wszystkich obiektów spełniających kryteria, zaczynając od ostatnio uchwalonych, chyba że parametry sortowania wskażą co innego 
         * @summary Zwraca listę aktów prawnych, zaczynając od najnowszych - ostatnio dodanych (po id)
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAktPrawnyEL(page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): AxiosPromise<AktPrawnySearchResponse> {
            return DefaultApiFp(configuration).listOfAktPrawnyEL(page, limit, sort, asc, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę aneksów (pełnych dokumentów) do umowy o podanym id
         * @param {string} idUmowy Id umowy, której aneksy są zwracane
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAneksUmowa(idUmowy: string, options?: any): AxiosPromise<Array<Umowa>> {
            return DefaultApiFp(configuration).listOfAneksUmowa(idUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę aneksów (EL) do umowy o podanym id
         * @param {string} idUmowy Id umowy, której aneksy są zwracane
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfAneksUmowaEL(idUmowy: string, options?: any): AxiosPromise<Array<UmowaEL>> {
            return DefaultApiFp(configuration).listOfAneksUmowaEL(idUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę jednostek inicjujących
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfJednostkaInicjujaca(options?: any): AxiosPromise<Array<JednostkaInicjujaca>> {
            return DefaultApiFp(configuration).listOfJednostkaInicjujaca(options)(axios, basePath);
        },
        /**
         * Zwraca listę jednostek organizacyjnych uczelni jako tablicę JSON obiektów typu Jednostka 
         * @summary Zwraca listę jednostek organizacyjnych uczelni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfJednostki(options?: any): AxiosPromise<Array<Jednostka>> {
            return DefaultApiFp(configuration).listOfJednostki(options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca logi systemowe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfLog(options?: any): AxiosPromise<Array<Log>> {
            return DefaultApiFp(configuration).listOfLog(options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę osób odpowiedzialnych za realizację umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfOsoba(options?: any): AxiosPromise<Array<Osoba>> {
            return DefaultApiFp(configuration).listOfOsoba(options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę rodzajów aktów prawnych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajAktuPrawnego(options?: any): AxiosPromise<Array<RodzajAktuPrawnego>> {
            return DefaultApiFp(configuration).listOfRodzajAktuPrawnego(options)(axios, basePath);
        },
        /**
         * Zwraca listę rodzajów podmiotów jako tablicę JSON obiektów typu Wykonawca 
         * @summary Zwraca listę rodzajów podmiotów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajPodmiotu(options?: any): AxiosPromise<Array<RodzajPodmiotu>> {
            return DefaultApiFp(configuration).listOfRodzajPodmiotu(options)(axios, basePath);
        },
        /**
         * Zwraca listę rodzajów umów jako tablicę JSON obiektów typu RodzajUmowy 
         * @summary Zwraca listę rodzajów umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRodzajUmowy(options?: any): AxiosPromise<Array<RodzajUmowy>> {
            return DefaultApiFp(configuration).listOfRodzajUmowy(options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę ról systemowych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfRole(options?: any): AxiosPromise<Array<Role>> {
            return DefaultApiFp(configuration).listOfRole(options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę statusów aktów prawnych
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfStatusAktuPrawnego(options?: any): AxiosPromise<Array<StatusAktuPrawnego>> {
            return DefaultApiFp(configuration).listOfStatusAktuPrawnego(options)(axios, basePath);
        },
        /**
         * Zwraca listę statusów umów jako tablicę JSON obiektów typu StatusUmowy 
         * @summary Zwraca listę statusów umów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfStatusUmowy(options?: any): AxiosPromise<Array<StatusUmowy>> {
            return DefaultApiFp(configuration).listOfStatusUmowy(options)(axios, basePath);
        },
        /**
         * Zwraca listę umów jako tablicę JSON obiektów typu UmowaEL, zaczynając od ostatnio podpisanych 
         * @summary Zwraca listę umów, zaczynając od najnowszych
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfUmowa(page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): AxiosPromise<UmowaSearchResponse> {
            return DefaultApiFp(configuration).listOfUmowa(page, limit, sort, asc, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę użytkowników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfUser(options?: any): AxiosPromise<Array<User>> {
            return DefaultApiFp(configuration).listOfUser(options)(axios, basePath);
        },
        /**
         * Zwraca listę walut jako tablicę JSON obiektów typu Waluta 
         * @summary Zwraca listę walut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfWaluta(options?: any): AxiosPromise<Array<Waluta>> {
            return DefaultApiFp(configuration).listOfWaluta(options)(axios, basePath);
        },
        /**
         * Zwraca listę wykonawców jako tablicę JSON obiektów typu Wykonawca 
         * @summary Zwraca listę wykonawców
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfWykonawca(options?: any): AxiosPromise<Array<Wykonawca>> {
            return DefaultApiFp(configuration).listOfWykonawca(options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę obiektów ZalacznikAktuPrawnego na podstawie id aktu prawnego
         * @param {string} idAktuPrawnego id aktu prawnego do którego chcemy listę załączników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfZalacznikAktuPrawnego(idAktuPrawnego: string, options?: any): AxiosPromise<Array<ZalacznikAktuPrawnego>> {
            return DefaultApiFp(configuration).listOfZalacznikAktuPrawnego(idAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca listę obiektów ZalacznikUmowy na podstawie id umowy
         * @param {string} idUmowy id umowy do której chcemy listę załączników
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOfZalacznikUmowy(idUmowy: string, options?: any): AxiosPromise<Array<ZalacznikUmowy>> {
            return DefaultApiFp(configuration).listOfZalacznikUmowy(idUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca obiekt dokumentu o podanym id
         * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAktPrawny(idAktuPrawnego: string, options?: any): AxiosPromise<AktPrawny> {
            return DefaultApiFp(configuration).loadAktPrawny(idAktuPrawnego, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca obiekt umowy o podanym id
         * @param {string} idUmowy Id umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUmowa(idUmowy: string, options?: any): AxiosPromise<Umowa> {
            return DefaultApiFp(configuration).loadUmowa(idUmowy, options)(axios, basePath);
        },
        /**
         * Zwraca ilość aktów prawnych w bazie
         * @summary Zwraca ilość aktów prawnych w bazie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policzAktPrawny(options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).policzAktPrawny(options)(axios, basePath);
        },
        /**
         * Zwraca ilość umów w bazie nie licząc aneksów
         * @summary Zwraca ilość umów w bazie nie licząc aneksów
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policzUmowa(options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).policzUmowa(options)(axios, basePath);
        },
        /**
         * 
         * @summary test konwertera BIgDecimal
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testKonwertera(inlineObject: InlineObject, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).testKonwertera(inlineObject, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zwraca szczegóły umowy o podanym id
         * @param {string} idUmowy Id umowy do wyświetlenia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        umowaDetails(idUmowy: string, options?: any): AxiosPromise<UmowaDetails> {
            return DefaultApiFp(configuration).umowaDetails(idUmowy, options)(axios, basePath);
        },
        /**
         * 
         * @summary Wyszukiwanie (proste) aktów prawnych
         * @param {string} query Hasło do wyszukania aktu prawnego
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukajAktPrawny(query: string, page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): AxiosPromise<AktPrawnySearchResponse> {
            return DefaultApiFp(configuration).wyszukajAktPrawny(query, page, limit, sort, asc, options)(axios, basePath);
        },
        /**
         * 
         * @summary Wyszukiwanie umów
         * @param {string} query Hasło do wyszukania umowy
         * @param {boolean} uwzglednijAneksy Czy wyszukiwanie ma obejmować także aneksy do umów
         * @param {number} [page] Paginacja - która strona
         * @param {number} [limit] Paginacja - ile na stronie
         * @param {string} [sort] Atrybut po którym następuje sortowanie
         * @param {boolean} [asc] Czy sortowanie rosnąco
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukajUmowe(query: string, uwzglednijAneksy: boolean, page?: number, limit?: number, sort?: string, asc?: boolean, options?: any): AxiosPromise<UmowaSearchResponse> {
            return DefaultApiFp(configuration).wyszukajUmowe(query, uwzglednijAneksy, page, limit, sort, asc, options)(axios, basePath);
        },
        /**
         * 
         * @summary Wyszukiwanie aktów prawnych dla użytkownika
         * @param {AktPrawnyUser} aktPrawnyUser parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wyszukiwanieAktowPrawnychUser(aktPrawnyUser: AktPrawnyUser, options?: any): AxiosPromise<Array<AktPrawnyEL>> {
            return DefaultApiFp(configuration).wyszukiwanieAktowPrawnychUser(aktPrawnyUser, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zaawansowane wyszukiwanie aktów prawnych
         * @param {AktPrawnyZaawansowane} aktPrawnyZaawansowane parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zaawansowaneWyszukiwanieAktowPrawnych(aktPrawnyZaawansowane: AktPrawnyZaawansowane, options?: any): AxiosPromise<AktPrawnySearchResponse> {
            return DefaultApiFp(configuration).zaawansowaneWyszukiwanieAktowPrawnych(aktPrawnyZaawansowane, options)(axios, basePath);
        },
        /**
         * 
         * @summary Zaawansowane wyszukiwanie umów
         * @param {UmowaZaawansowane} umowaZaawansowane parametry wyszukiwania
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zaawansowaneWyszukiwanieUmow(umowaZaawansowane: UmowaZaawansowane, options?: any): AxiosPromise<UmowaSearchResponse> {
            return DefaultApiFp(configuration).zaawansowaneWyszukiwanieUmow(umowaZaawansowane, options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {AktPrawny} [aktPrawny] Nowy obiekt AktPrawny
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addAktPrawny(aktPrawny?: AktPrawny, options?: any) {
        return DefaultApiFp(this.configuration).addAktPrawny(aktPrawny, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Tworzy kopię umowy jako bazę dla aneksu. Zwraca id aneksu
     * @param {string} idUmowy Id umowy do której dodajemy aneks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addAneksUmowa(idUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).addAneksUmowa(idUmowy, options)(this.axios, this.basePath);
    }

    /**
     * Tworzy pusty akt prawny, zapisuje go w bazie i zwraca jego id. Dalsze etapy wypełniania pól aktu prawnego powinny być realizowane za pomocą edycji.
     * @summary Tworzy nowy, pusty akt prawny i zwraca jedo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addEmptyAktPrawny(options?: any) {
        return DefaultApiFp(this.configuration).addEmptyAktPrawny(options)(this.axios, this.basePath);
    }

    /**
     * Tworzy nową, pustą umowę, zapisuje ją w bazie i zwraca jej id. Dalsze etapy wypełniania pól umowy powinny być realizowane za pomocą edycji.
     * @summary Tworzy nową, pustą umowę i zwraca jej id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addEmptyUmowa(options?: any) {
        return DefaultApiFp(this.configuration).addEmptyUmowa(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowej jednostki organizacyjnej uczelni do słownika
     * @param {Jednostka} [jednostka] Nowy obiekt Jednostka
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addJednostka(jednostka?: Jednostka, options?: any) {
        return DefaultApiFp(this.configuration).addJednostka(jednostka, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowej jednostki inicjującej do słownika
     * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Nowy obiekt JednostkaInicjujaca
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addJednostkaInicjujaca(jednostkaInicjujaca?: JednostkaInicjujaca, options?: any) {
        return DefaultApiFp(this.configuration).addJednostkaInicjujaca(jednostkaInicjujaca, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowej osoby odpowiedzialnej za realizację umowy
     * @param {Osoba} [osoba] Nowy obiekt Osoba
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addOsoba(osoba?: Osoba, options?: any) {
        return DefaultApiFp(this.configuration).addOsoba(osoba, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary dodanie pliku do aktu prawnego do już istniejącego w bazie obiektu załącznika
     * @param {string} [idZalacznika] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addPlikDoAktuPrawnego(idZalacznika?: string, file?: any, options?: any) {
        return DefaultApiFp(this.configuration).addPlikDoAktuPrawnego(idZalacznika, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary dodanie pliku do umowy do już istniejącego w bazie obiektu załącznika
     * @param {string} [idZalacznika] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addPlikDoUmowy(idZalacznika?: string, file?: any, options?: any) {
        return DefaultApiFp(this.configuration).addPlikDoUmowy(idZalacznika, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego rodzaju aktu prawnego do słownika
     * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Nowy obiekt RodzajAktuPrawnego
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addRodzajAktuPrawnego(rodzajAktuPrawnego?: RodzajAktuPrawnego, options?: any) {
        return DefaultApiFp(this.configuration).addRodzajAktuPrawnego(rodzajAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego rodzaju podmiotów do słownika
     * @param {RodzajPodmiotu} [rodzajPodmiotu] Nowy obiekt RodzajPodmiotu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addRodzajPomiotu(rodzajPodmiotu?: RodzajPodmiotu, options?: any) {
        return DefaultApiFp(this.configuration).addRodzajPomiotu(rodzajPodmiotu, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego rodzaju umowy do słownika
     * @param {RodzajUmowy} [rodzajUmowy] Nowy obiekt RodzajUmowy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addRodzajUmowy(rodzajUmowy?: RodzajUmowy, options?: any) {
        return DefaultApiFp(this.configuration).addRodzajUmowy(rodzajUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego statusu aktu prawnego do słownika
     * @param {StatusAktuPrawnego} [statusAktuPrawnego] Nowy obiekt StatusAktuPrawnego
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addStatusAktuPrawnego(statusAktuPrawnego?: StatusAktuPrawnego, options?: any) {
        return DefaultApiFp(this.configuration).addStatusAktuPrawnego(statusAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego statusu umowy do słownika
     * @param {StatusUmowy} [statusUmowy] Nowy obiekt StatusUmowy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addStatusUmowy(statusUmowy?: StatusUmowy, options?: any) {
        return DefaultApiFp(this.configuration).addStatusUmowy(statusUmowy, options)(this.axios, this.basePath);
    }

    /**
     * Dodaj nowego użytkownika
     * @param {User} [user] Nowy użytkownik
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addUser(user?: User, options?: any) {
        return DefaultApiFp(this.configuration).addUser(user, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowej waluty do słownika
     * @param {Waluta} [waluta] Nowy obiekt Waluta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addWaluta(waluta?: Waluta, options?: any) {
        return DefaultApiFp(this.configuration).addWaluta(waluta, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego wykonawcy do słownika
     * @param {Wykonawca} [wykonawca] Nowy obiekt Wykonawca
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addWykonawca(wykonawca?: Wykonawca, options?: any) {
        return DefaultApiFp(this.configuration).addWykonawca(wykonawca, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego załącznika aktu prawnego
     * @param {ZalacznikAktuPrawnego} [zalacznikAktuPrawnego] Nowy obiekt - załącznik
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addZalacznikAktuPrawnego(zalacznikAktuPrawnego?: ZalacznikAktuPrawnego, options?: any) {
        return DefaultApiFp(this.configuration).addZalacznikAktuPrawnego(zalacznikAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dodanie nowego załącznika umowy
     * @param {ZalacznikUmowy} [zalacznikUmowy] Nowy obiekt - załącznik
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addZalacznikUmowy(zalacznikUmowy?: ZalacznikUmowy, options?: any) {
        return DefaultApiFp(this.configuration).addZalacznikUmowy(zalacznikUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły dokumentu o podanym id
     * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aktPrawnyDetails(idAktuPrawnego: string, options?: any) {
        return DefaultApiFp(this.configuration).aktPrawnyDetails(idAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * Usuń akt prawny
     * @param {string} idAktuPrawnego 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAktPrawny(idAktuPrawnego: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteAktPrawny(idAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie jednostki
     * @param {string} idJednostki Identyfikator jednostki do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJednostka(idJednostki: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteJednostka(idJednostki, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie jednostki inicjującej
     * @param {string} idJednostkiInicjujacej Identyfikator jednostki inicjującej do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJednostkaInicjujaca(idJednostkiInicjujacej: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteJednostkaInicjujaca(idJednostkiInicjujacej, options)(this.axios, this.basePath);
    }

    /**
     * Usuń osobę
     * @param {string} idOsoby 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteOsoba(idOsoby: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteOsoba(idOsoby, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie rodzaju aktu prawnego
     * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteRodzajAktuPrawnego(idRodzajuAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie rodzaju podmiotu
     * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRodzajPodmiotu(idRodzajuPodmiotu: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteRodzajPodmiotu(idRodzajuPodmiotu, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie rodzaju umowy
     * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRodzajUmowy(idRodzajuUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteRodzajUmowy(idRodzajuUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie statusu aktu prawnego
     * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStatusAktuPrawnego(idStatusuAktuPrawnego: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteStatusAktuPrawnego(idStatusuAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie statusu umowy
     * @param {string} idStatusu Identyfikator statusu umowy do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStatusUmowy(idStatusu: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteStatusUmowy(idStatusu, options)(this.axios, this.basePath);
    }

    /**
     * Usuń umowę
     * @param {string} idUmowy 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUmowa(idUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteUmowa(idUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie użytkownika
     * @param {string} idUzytkownika Identyfikator użytkownika usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUser(idUzytkownika: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteUser(idUzytkownika, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie waluty
     * @param {string} idWaluty Identyfikator waluty do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteWaluta(idWaluty: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteWaluta(idWaluty, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie wykonawcy
     * @param {string} idWykonawcy Identyfikator wykonawcy do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteWykonawca(idWykonawcy: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteWykonawca(idWykonawcy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie załącznika
     * @param {string} idZalacznikiAkty Identyfikator załącznika do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteZalacznikAktuPrawnego(idZalacznikiAkty: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteZalacznikAktuPrawnego(idZalacznikiAkty, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Usunięcie załącznika do umowy
     * @param {string} idZalacznika Identyfikator załącznika do usunięcia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteZalacznikUmowy(idZalacznika: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteZalacznikUmowy(idZalacznika, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły jednostki organizacyjnej o podanym id
     * @param {string} idJednostki Id jednostki do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfJednostka(idJednostki: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfJednostka(idJednostki, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły osoby o podanym id
     * @param {string} idOsoby Id osoby do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfOsoba(idOsoby: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfOsoba(idOsoby, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły rodzaju podmiotu o podanym id
     * @param {string} idRodzajuPodmiotu Id rodzaju podmiotu do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfRodzajPodmiotu(idRodzajuPodmiotu: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfRodzajPodmiotu(idRodzajuPodmiotu, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły rodzaju umowy o podanym id
     * @param {string} idRodzajuUmowy Id rodzaju umowy do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfRodzajUmowy(idRodzajuUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfRodzajUmowy(idRodzajuUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły statusu o podanym id
     * @param {string} idStatusu Id statusu do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfStatusUmowy(idStatusu: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfStatusUmowy(idStatusu, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły waluty o podanym id
     * @param {string} idWaluty Id waluty do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfWaluta(idWaluty: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfWaluta(idWaluty, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły wykonawcy o podanym id
     * @param {string} idWykonawcy Id wykonawcy do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfWykonawca(idWykonawcy: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfWykonawca(idWykonawcy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca obiekt załącznika
     * @param {string} idZalacznikiAkty Identyfikator załącznika do pobrania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfZalacznikAktuPrawnego(idZalacznikiAkty: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfZalacznikAktuPrawnego(idZalacznikiAkty, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca informacje o załączniku do umowy
     * @param {string} idZalacznika Identyfikator załącznika do pobrania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detailsOfZalacznikUmowy(idZalacznika: string, options?: any) {
        return DefaultApiFp(this.configuration).detailsOfZalacznikUmowy(idZalacznika, options)(this.axios, this.basePath);
    }

    /**
     * Modyfikacja aktu prawnego
     * @param {string} idAktuPrawnego Identyfikator aktu prawnego do modyfikacji
     * @param {AktPrawny} [aktPrawny] Obiekt AktPrawny ze zmodyfikowanymi wartościami
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editAktPrawny(idAktuPrawnego: string, aktPrawny?: AktPrawny, options?: any) {
        return DefaultApiFp(this.configuration).editAktPrawny(idAktuPrawnego, aktPrawny, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja jednostki
     * @param {string} idJednostki Identyfikator jednostki  do modyfikacji
     * @param {Jednostka} [jednostka] Obiekt do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editJednostka(idJednostki: string, jednostka?: Jednostka, options?: any) {
        return DefaultApiFp(this.configuration).editJednostka(idJednostki, jednostka, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja jednostki inicjującej
     * @param {string} idJednostkiInicjujacej Identyfikator organu  do modyfikacji
     * @param {JednostkaInicjujaca} [jednostkaInicjujaca] Obiekt do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editJednostkaInicjujaca(idJednostkiInicjujacej: string, jednostkaInicjujaca?: JednostkaInicjujaca, options?: any) {
        return DefaultApiFp(this.configuration).editJednostkaInicjujaca(idJednostkiInicjujacej, jednostkaInicjujaca, options)(this.axios, this.basePath);
    }

    /**
     * Modyfikacja osoby
     * @param {string} idOsoby Identyfikator osoby do modyfikacji
     * @param {Osoba} [osoba] Obiekt Osoba ze zmodyfikowanymi wartościami
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editOsoba(idOsoby: string, osoba?: Osoba, options?: any) {
        return DefaultApiFp(this.configuration).editOsoba(idOsoby, osoba, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja rodzaju aktu prawnego
     * @param {string} idRodzajuAktuPrawnego Identyfikator rodzaju aktu prawnego do modyfikacji
     * @param {RodzajAktuPrawnego} [rodzajAktuPrawnego] Obiekt - rodzaj aktu prawnego do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editRodzajAktuPrawnego(idRodzajuAktuPrawnego: string, rodzajAktuPrawnego?: RodzajAktuPrawnego, options?: any) {
        return DefaultApiFp(this.configuration).editRodzajAktuPrawnego(idRodzajuAktuPrawnego, rodzajAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja rodzaju podmiotu
     * @param {string} idRodzajuPodmiotu Identyfikator rodzaju podmiotu  do modyfikacji
     * @param {RodzajPodmiotu} [rodzajPodmiotu] Obiekt do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editRodzajPodmiotu(idRodzajuPodmiotu: string, rodzajPodmiotu?: RodzajPodmiotu, options?: any) {
        return DefaultApiFp(this.configuration).editRodzajPodmiotu(idRodzajuPodmiotu, rodzajPodmiotu, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja rodzaju umowy
     * @param {string} idRodzajuUmowy Identyfikator rodzaju umowy  do modyfikacji
     * @param {RodzajUmowy} [rodzajUmowy] Obiekt do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editRodzajUmowy(idRodzajuUmowy: string, rodzajUmowy?: RodzajUmowy, options?: any) {
        return DefaultApiFp(this.configuration).editRodzajUmowy(idRodzajuUmowy, rodzajUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja statusu aktu prawnego
     * @param {string} idStatusuAktuPrawnego Identyfikator statusu aktu prawnego do modyfikacji
     * @param {StatusAktuPrawnego} [statusAktuPrawnego] Obiekt - status aktu prawnego do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editStatusAktuPrawnego(idStatusuAktuPrawnego: string, statusAktuPrawnego?: StatusAktuPrawnego, options?: any) {
        return DefaultApiFp(this.configuration).editStatusAktuPrawnego(idStatusuAktuPrawnego, statusAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja statusu umowy
     * @param {string} idStatusu Identyfikator statusu umowy  do modyfikacji
     * @param {StatusUmowy} [statusUmowy] Obiekt do nadpisania w bazie, na podstawie id przekazanego w ściezce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editStatusUmowy(idStatusu: string, statusUmowy?: StatusUmowy, options?: any) {
        return DefaultApiFp(this.configuration).editStatusUmowy(idStatusu, statusUmowy, options)(this.axios, this.basePath);
    }

    /**
     * Modyfikacja umowy
     * @param {string} idUmowy Identyfikator umowy do modyfikacji
     * @param {Umowa} [umowa] Obiekt Umowa ze zmodyfikowanymi wartościami
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editUmowa(idUmowy: string, umowa?: Umowa, options?: any) {
        return DefaultApiFp(this.configuration).editUmowa(idUmowy, umowa, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja użytkownika
     * @param {string} idUzytkownika Identyfikator użytkownika do modyfikacji
     * @param {User} [user] Obiekt - użytkownik do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editUser(idUzytkownika: string, user?: User, options?: any) {
        return DefaultApiFp(this.configuration).editUser(idUzytkownika, user, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja waluty
     * @param {string} idWaluty Identyfikator waluty  do modyfikacji
     * @param {Waluta} [waluta] Obiekt do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editWaluta(idWaluty: string, waluta?: Waluta, options?: any) {
        return DefaultApiFp(this.configuration).editWaluta(idWaluty, waluta, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modyfikacja wykonawcy
     * @param {string} idWykonawcy Identyfikator wykonawcy  do modyfikacji
     * @param {Wykonawca} [wykonawca] Obiekt do nadpisania w bazie, na podstawie id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editWykonawca(idWykonawcy: string, wykonawca?: Wykonawca, options?: any) {
        return DefaultApiFp(this.configuration).editWykonawca(idWykonawcy, wykonawca, options)(this.axios, this.basePath);
    }

    /**
     * Modyfikacja informacji o załaczniku do umowy
     * @param {string} idZalacznika Identyfikator załącznika do modyfikacji
     * @param {ZalacznikUmowy} [zalacznikUmowy] Obiekt ZalacznikUmowy ze zmodyfikowanymi wartościami
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editZalacznikUmowy(idZalacznika: string, zalacznikUmowy?: ZalacznikUmowy, options?: any) {
        return DefaultApiFp(this.configuration).editZalacznikUmowy(idZalacznika, zalacznikUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary pobranie pliku załącznika do aktu prawnego
     * @param {string} idZalacznika Identyfikator załącznika do pobrania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPlikAktPrawny(idZalacznika: string, options?: any) {
        return DefaultApiFp(this.configuration).getPlikAktPrawny(idZalacznika, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary pobranie pliku załącznika
     * @param {string} idZalacznika Identyfikator załącznika do pobrania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPlikUmowy(idZalacznika: string, options?: any) {
        return DefaultApiFp(this.configuration).getPlikUmowy(idZalacznika, options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę aktów prawnych jako obiekt AktPrawnySearchResponse zawierający pole list: tablicę JSON obiektów typu AktPrawnyEL oraz pole count  - ile jest wszystkich obiektów spełniających kryteria, zaczynając od ostatnio uchwalonych, chyba że parametry sortowania wskażą co innego 
     * @summary Zwraca listę aktów prawnych, zaczynając od najnowszych - ostatnio dodanych (po id)
     * @param {number} [page] Paginacja - która strona
     * @param {number} [limit] Paginacja - ile na stronie
     * @param {string} [sort] Atrybut po którym następuje sortowanie
     * @param {boolean} [asc] Czy sortowanie rosnąco
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfAktPrawnyEL(page?: number, limit?: number, sort?: string, asc?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listOfAktPrawnyEL(page, limit, sort, asc, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę aneksów (pełnych dokumentów) do umowy o podanym id
     * @param {string} idUmowy Id umowy, której aneksy są zwracane
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfAneksUmowa(idUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).listOfAneksUmowa(idUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę aneksów (EL) do umowy o podanym id
     * @param {string} idUmowy Id umowy, której aneksy są zwracane
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfAneksUmowaEL(idUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).listOfAneksUmowaEL(idUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę jednostek inicjujących
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfJednostkaInicjujaca(options?: any) {
        return DefaultApiFp(this.configuration).listOfJednostkaInicjujaca(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę jednostek organizacyjnych uczelni jako tablicę JSON obiektów typu Jednostka 
     * @summary Zwraca listę jednostek organizacyjnych uczelni
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfJednostki(options?: any) {
        return DefaultApiFp(this.configuration).listOfJednostki(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca logi systemowe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfLog(options?: any) {
        return DefaultApiFp(this.configuration).listOfLog(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę osób odpowiedzialnych za realizację umów
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfOsoba(options?: any) {
        return DefaultApiFp(this.configuration).listOfOsoba(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę rodzajów aktów prawnych
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfRodzajAktuPrawnego(options?: any) {
        return DefaultApiFp(this.configuration).listOfRodzajAktuPrawnego(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę rodzajów podmiotów jako tablicę JSON obiektów typu Wykonawca 
     * @summary Zwraca listę rodzajów podmiotów
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfRodzajPodmiotu(options?: any) {
        return DefaultApiFp(this.configuration).listOfRodzajPodmiotu(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę rodzajów umów jako tablicę JSON obiektów typu RodzajUmowy 
     * @summary Zwraca listę rodzajów umów
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfRodzajUmowy(options?: any) {
        return DefaultApiFp(this.configuration).listOfRodzajUmowy(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę ról systemowych
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfRole(options?: any) {
        return DefaultApiFp(this.configuration).listOfRole(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę statusów aktów prawnych
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfStatusAktuPrawnego(options?: any) {
        return DefaultApiFp(this.configuration).listOfStatusAktuPrawnego(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę statusów umów jako tablicę JSON obiektów typu StatusUmowy 
     * @summary Zwraca listę statusów umów
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfStatusUmowy(options?: any) {
        return DefaultApiFp(this.configuration).listOfStatusUmowy(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę umów jako tablicę JSON obiektów typu UmowaEL, zaczynając od ostatnio podpisanych 
     * @summary Zwraca listę umów, zaczynając od najnowszych
     * @param {number} [page] Paginacja - która strona
     * @param {number} [limit] Paginacja - ile na stronie
     * @param {string} [sort] Atrybut po którym następuje sortowanie
     * @param {boolean} [asc] Czy sortowanie rosnąco
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfUmowa(page?: number, limit?: number, sort?: string, asc?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listOfUmowa(page, limit, sort, asc, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę użytkowników
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfUser(options?: any) {
        return DefaultApiFp(this.configuration).listOfUser(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę walut jako tablicę JSON obiektów typu Waluta 
     * @summary Zwraca listę walut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfWaluta(options?: any) {
        return DefaultApiFp(this.configuration).listOfWaluta(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca listę wykonawców jako tablicę JSON obiektów typu Wykonawca 
     * @summary Zwraca listę wykonawców
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfWykonawca(options?: any) {
        return DefaultApiFp(this.configuration).listOfWykonawca(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę obiektów ZalacznikAktuPrawnego na podstawie id aktu prawnego
     * @param {string} idAktuPrawnego id aktu prawnego do którego chcemy listę załączników
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfZalacznikAktuPrawnego(idAktuPrawnego: string, options?: any) {
        return DefaultApiFp(this.configuration).listOfZalacznikAktuPrawnego(idAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca listę obiektów ZalacznikUmowy na podstawie id umowy
     * @param {string} idUmowy id umowy do której chcemy listę załączników
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listOfZalacznikUmowy(idUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).listOfZalacznikUmowy(idUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca obiekt dokumentu o podanym id
     * @param {string} idAktuPrawnego Id dokumentu (aktu prawnego) do pobrania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loadAktPrawny(idAktuPrawnego: string, options?: any) {
        return DefaultApiFp(this.configuration).loadAktPrawny(idAktuPrawnego, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca obiekt umowy o podanym id
     * @param {string} idUmowy Id umowy do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loadUmowa(idUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).loadUmowa(idUmowy, options)(this.axios, this.basePath);
    }

    /**
     * Zwraca ilość aktów prawnych w bazie
     * @summary Zwraca ilość aktów prawnych w bazie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policzAktPrawny(options?: any) {
        return DefaultApiFp(this.configuration).policzAktPrawny(options)(this.axios, this.basePath);
    }

    /**
     * Zwraca ilość umów w bazie nie licząc aneksów
     * @summary Zwraca ilość umów w bazie nie licząc aneksów
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public policzUmowa(options?: any) {
        return DefaultApiFp(this.configuration).policzUmowa(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary test konwertera BIgDecimal
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testKonwertera(inlineObject: InlineObject, options?: any) {
        return DefaultApiFp(this.configuration).testKonwertera(inlineObject, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zwraca szczegóły umowy o podanym id
     * @param {string} idUmowy Id umowy do wyświetlenia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public umowaDetails(idUmowy: string, options?: any) {
        return DefaultApiFp(this.configuration).umowaDetails(idUmowy, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Wyszukiwanie (proste) aktów prawnych
     * @param {string} query Hasło do wyszukania aktu prawnego
     * @param {number} [page] Paginacja - która strona
     * @param {number} [limit] Paginacja - ile na stronie
     * @param {string} [sort] Atrybut po którym następuje sortowanie
     * @param {boolean} [asc] Czy sortowanie rosnąco
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public wyszukajAktPrawny(query: string, page?: number, limit?: number, sort?: string, asc?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).wyszukajAktPrawny(query, page, limit, sort, asc, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Wyszukiwanie umów
     * @param {string} query Hasło do wyszukania umowy
     * @param {boolean} uwzglednijAneksy Czy wyszukiwanie ma obejmować także aneksy do umów
     * @param {number} [page] Paginacja - która strona
     * @param {number} [limit] Paginacja - ile na stronie
     * @param {string} [sort] Atrybut po którym następuje sortowanie
     * @param {boolean} [asc] Czy sortowanie rosnąco
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public wyszukajUmowe(query: string, uwzglednijAneksy: boolean, page?: number, limit?: number, sort?: string, asc?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).wyszukajUmowe(query, uwzglednijAneksy, page, limit, sort, asc, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Wyszukiwanie aktów prawnych dla użytkownika
     * @param {AktPrawnyUser} aktPrawnyUser parametry wyszukiwania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public wyszukiwanieAktowPrawnychUser(aktPrawnyUser: AktPrawnyUser, options?: any) {
        return DefaultApiFp(this.configuration).wyszukiwanieAktowPrawnychUser(aktPrawnyUser, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zaawansowane wyszukiwanie aktów prawnych
     * @param {AktPrawnyZaawansowane} aktPrawnyZaawansowane parametry wyszukiwania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zaawansowaneWyszukiwanieAktowPrawnych(aktPrawnyZaawansowane: AktPrawnyZaawansowane, options?: any) {
        return DefaultApiFp(this.configuration).zaawansowaneWyszukiwanieAktowPrawnych(aktPrawnyZaawansowane, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Zaawansowane wyszukiwanie umów
     * @param {UmowaZaawansowane} umowaZaawansowane parametry wyszukiwania
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zaawansowaneWyszukiwanieUmow(umowaZaawansowane: UmowaZaawansowane, options?: any) {
        return DefaultApiFp(this.configuration).zaawansowaneWyszukiwanieUmow(umowaZaawansowane, options)(this.axios, this.basePath);
    }

}
